import { MANAGEMENT_PAGE_SIZE, REQUEST_STALE_TIME_IN_MS } from '@/constants';
import useInfiniteService from '@/data/hook/useInfiniteService';
import useListService from '@/data/hook/useListService';
import {
  enrollmentsQueryKeys,
  scheduledLessonReportsQueryKeys,
} from '@/data/services/querykeys';
import Enrollment, { EnrollmentStatusEnum } from '@/models/Enrollment';
import ScheduledLessonReport, {
  ScheduledLessonReportEnum,
} from '@/models/ScheduledLessonReport';

type useReportsActiveProps = {
  klassId: number;
  scheduledLessonId?: number;
  active?: boolean;
};

export type EnrollmentReports = {
  enrollment: Enrollment;
  report?: ScheduledLessonReport;
};

export const useActiveStudents = ({
  klassId,
  scheduledLessonId,
  active = true,
}: useReportsActiveProps) => {
  const { results: enrollmentsResults, ...enrollmentsProps } =
    useInfiniteService({
      ...enrollmentsQueryKeys.list({
        current: true,
        klassId: klassId,
        pageSize: MANAGEMENT_PAGE_SIZE,
      })._ctx.infinity,
      enabled: !!klassId,
      staleTime: REQUEST_STALE_TIME_IN_MS,
    });

  const { results: reports } = useListService({
    ...scheduledLessonReportsQueryKeys.list({
      scheduledLesson: scheduledLessonId,
      status: [ScheduledLessonReportEnum.ACTIVE],
      pageSize: 50,
    }),
    staleTime: REQUEST_STALE_TIME_IN_MS,
    enabled: !!scheduledLessonId,
  });

  const enrollmentsWithReports = enrollmentsResults.map(enrollment => ({
    enrollment,
    reports: reports.filter(report => report.student === enrollment.student.id),
  }));

  const inactiveStudents: Enrollment[] = [];

  const activeStudents: Enrollment[] = [];

  for (const { enrollment, reports } of enrollmentsWithReports) {
    const hasReportActive = !!reports.length;
    const activeEnrollment = enrollment.status === EnrollmentStatusEnum.ACTIVE;

    if (hasReportActive || activeEnrollment) activeStudents.push(enrollment);
    else inactiveStudents.push(enrollment);
  }

  return {
    ...enrollmentsProps,
    enrollments: active ? activeStudents : inactiveStudents,
    reports,
  };
};
