import React, { useState } from 'react';
import Layout from '@/components/template/Layout';
import { UserStatusEnum, UserTypeEnum } from '@/models/User';
import { useTranslation } from 'react-i18next';
import AdminButton from '@/components/common/AdminButton';
import AddStudent from '@/components/admin/students/AddStudent';
import ListStudents from '@/components/admin/students/ListStudents';
import NoAdminScreenForMobile from './NoAdminScreenForMobile';
import { useParams } from 'react-router-dom';
import TableSearchIcon from '@/components/common/TableSearchIcon';
import { StudentHeader } from '@/utils/HeaderTypes';
import { useQuery } from '@tanstack/react-query';
import PaginationComponent from './PaginationComponent';
import TableStatistics from '@/components/common/table/TableStatistics';
import useAuth from '@/data/hook/useAuth';
import HeadTitle from '@/components/common/HeadTitle';
import { MANAGEMENT_PAGE_SIZE } from '@/constants';
import {
  ListUsersFilters,
  orderingUserAccess,
} from '@/data/services/userServices';
import TableSortIcons from '@/components/common/TableSortIcons';
import Dropdown from '@/components/common/filter/Dropdown';
import useFilterParams from '@/utils/UseFilterParams';
import { isTeacher, isUnitAdmin } from '@/functions/auth';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import { EnrollmentStatusEnum } from '@/models/Enrollment';
import {
  accessCountQueryKeys,
  studentsQuerykeys,
  teacherStudentsCountQueryKeys,
} from '@/data/services/querykeys';
import useStatisticService from '@/data/hook/useStatistics';

export default function ManageStudent() {
  const { t } = useTranslation('translation', { keyPrefix: 'manageStudent' });
  const { user } = useAuth();
  const [isSearch, setIsSearch] = useState(false);
  const { actionId } = useParams();
  const [isAddStudent, setIsAddStudent] = useState(actionId === 'add');

  const { filterParams, setFilterParams } = useFilterParams<ListUsersFilters>();

  const unit = filterParams.getAll('unit').map(Number) ?? undefined;
  const status = filterParams.getAll('status') ?? undefined;
  const enrollmentStatus =
    (filterParams.getAll('enrollmentStatus') as EnrollmentStatusEnum[]) ?? [];
  const pageSize = MANAGEMENT_PAGE_SIZE;
  const pageNumber = Number(filterParams.get('pageNumber') ?? '1');
  const search = filterParams.get('search') ?? undefined;
  const ordering =
    (filterParams.get('ordering') as orderingUserAccess) ?? undefined;

  const params = {
    unit,
    pageSize,
    pageNumber,
    status,
    search,
    ordering,
    enrollmentStatus,
  };

  const {
    data: studentsPage,
    isInitialLoading: isLoadingStudents,
    isPreviousData,
  } = useQuery({
    keepPreviousData: true,
    ...studentsQuerykeys.list(params),
  });

  const isMultipleUnits = user && user.unitsIds?.length > 1;

  const setOrderingParams = (orderBy: orderingUserAccess) => {
    setFilterParams({ ordering: orderBy, pageNumber: 1 });
  };

  const titles: StudentHeader = {
    name: {
      title: t('listUsers.name'),
      size: 24,
      extraFunction: [
        <TableSearchIcon onClick={() => setIsSearch(!isSearch)} />,
      ],
    },
    klass: {
      title: t('listUsers.klass'),
      size: 18,
      roles: [UserTypeEnum.TEACHER],
    },
    email: {
      title: t('listUsers.email'),
      size: 24,
    },
    unit: {
      title: t('listUsers.unit'),
      size: 14,
      extraFunction: [
        <Dropdown type="unit" filter={unit?.map(String) ?? []} />,
      ],
      roles: [
        UserTypeEnum.SUPER_ADMIN,
        UserTypeEnum.UNIT_ADMIN,
        UserTypeEnum.TEACHER,
      ],
      blockRender: true,
      blockCondition:
        (isUnitAdmin(user?.userType) || isTeacher(user?.userType)) &&
        !isMultipleUnits,
    },
    lastAccess: {
      title: t('listUsers.lastAccess'),
      size: 16,
      extraFunction: [
        <TableSortIcons
          onClickSort={value => {
            const orderBy = value === 'ASC' ? 'last_login' : '-last_login';
            setOrderingParams(orderBy);
          }}
        />,
      ],
    },
    status: {
      title: t('listUsers.status'),
      size: 10,
      extraFunction: [
        <Dropdown type="enrollmentStatus" filter={enrollmentStatus} />,
      ],
    },
    actions: {
      title: t('listUsers.actions'),
      size: 20,
    },
  };

  const changePages = (numberOfPage: string) => {
    setFilterParams({ pageNumber: Number(numberOfPage) });
  };

  const statisticsOptions = {
    userType: UserTypeEnum.STUDENT,
    ...(user?.userType === UserTypeEnum.TEACHER && {
      teacher: user?.id,
    }),
    status: UserStatusEnum.ACTIVE_ENROLLMENT,
    unitId: getAuthorizedUnits(user),
  };

  const {
    statistic: studentsStatistics,
    isInitialLoading: isLoadingStudentsStatistics,
  } = useStatisticService({
    ...accessCountQueryKeys.list(statisticsOptions),
    keepPreviousData: true,
  });
  const { statistic: totalStudents, isInitialLoading: isLoadingTotalStudents } =
    useStatisticService({
      keepPreviousData: true,
      ...teacherStudentsCountQueryKeys.list(statisticsOptions),
    });

  const statisticsCards = [
    {
      title: t('quantity'),
      info: totalStudents?.studentsCount || 0,
      isLoading: isLoadingTotalStudents,
    },
    {
      title: t('accesses'),
      info: studentsStatistics?.thirtyDaysCount || 0,
      isLoading: isLoadingStudentsStatistics,
    },
    {
      title: t('weeklyAccesses'),
      info: studentsStatistics?.sevenDaysCount || 0,
      isLoading: isLoadingStudentsStatistics,
    },
  ];

  return (
    <React.Fragment>
      <HeadTitle routeInfo={t('students')} />
      <div className="md:hidden">
        <NoAdminScreenForMobile />
      </div>

      <div className="hidden md:block w-full">
        <Layout className="gap-2 flex flex-col">
          <TableStatistics
            headingText={t('title')}
            statisticsCards={statisticsCards}
            adminButton={
              <AdminButton
                title={t('add')}
                onClick={() => setIsAddStudent(old => !old)}
                open={isAddStudent}
              />
            }
          />
          <AddStudent show={isAddStudent} setShow={setIsAddStudent} />

          <ListStudents
            isLoading={isLoadingStudents || isPreviousData}
            students={studentsPage?.results || []}
            titles={titles}
            isSearch={isSearch}
          />
          <PaginationComponent
            amountPages={studentsPage?.meta.pagination.pages || 1}
            changePages={changePages}
            paramsPage={pageNumber}
            amountOf={studentsPage?.meta.pagination.count || 0}
            managerType="students"
          />
        </Layout>
      </div>
    </React.Fragment>
  );
}
