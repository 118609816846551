import JsonApiResponse from '@/models/JsonApiResponse';
import {
  InfiniteData,
  QueryKey,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

type T<
  Model,
  TError = unknown,
  TQueryKey extends QueryKey = QueryKey,
> = UseQueryOptions<
  JsonApiResponse<Model>,
  TError,
  JsonApiResponse<Model>,
  TQueryKey
>;
const useManualQuery = <
  Model extends { id: number },
  TError = unknown,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: T<Model, TError, TQueryKey>,
) => {
  const queryClient = useQueryClient();
  // TODO: testar depois
  const add = (area: Model) => {
    const listParents = queryClient.getQueryData<
      InfiniteData<JsonApiResponse<Model>>
    >(options.queryKey as QueryKey);
    listParents?.pages.at(0)?.results.unshift(area);
    queryClient.setQueryData(options.queryKey as QueryKey, listParents);
  };

  const remove = (areaId: number) => {
    const listParents = queryClient.getQueryData<
      InfiniteData<JsonApiResponse<Model>>
    >(options.queryKey as QueryKey);
    if (!listParents) return;
    for (let i = 0; i < listParents.pages.length; i++) {
      if (listParents.pages[i].results.find(a => a.id === areaId)) {
        listParents.pages[i].results = listParents.pages[i].results.filter(
          a => a.id !== areaId,
        );
        break;
      }
    }
    queryClient.setQueryData(options.queryKey as QueryKey, listParents);
  };

  const update = (area: Model) => {
    const listParents = queryClient.getQueryData<
      InfiniteData<JsonApiResponse<Model>>
    >(options.queryKey as QueryKey);
    if (!listParents) return;
    for (let i = 0; i < listParents.pages.length; i++) {
      if (listParents.pages[i].results.find(a => a.id === area.id)) {
        listParents.pages[i].results = listParents.pages[i].results.map(a =>
          a.id === area.id ? area : a,
        );
        break;
      }
    }
    queryClient.setQueryData(options.queryKey as QueryKey, listParents);
  };

  return { add, remove, update };
};

export default useManualQuery;
