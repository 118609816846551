import { BaseFilters } from '@/models/Service';
import requester from '@/axios';
import JsonApiResponse from '@/models/JsonApiResponse';
import { Teacher } from '@/models/Klass';
import User, { UserTypeEnum } from '@/models/User';
import UserProfile from '@/models/UserProfile';
import { EnrollmentStatusEnum } from '@/models/Enrollment';

export type orderingUserAccess = 'last_login' | '-last_login';

export interface ListUsersFilters extends BaseFilters {
  unit?: number[];
  status?: string[];
  ordering?: orderingUserAccess;
  userType?: UserTypeEnum[];
  exUserType?: UserTypeEnum[];
  teacher?: number;
  enrollmentStatus?: EnrollmentStatusEnum[];
  hasAbsenceNotification?: boolean;
  username?: string;
  klassId?: number;
}

export async function listStudents(params: ListUsersFilters = {}) {
  const { data } = await requester().get<JsonApiResponse<User>>('students/', {
    params: { ...params, unit: params.unit?.toString() },
  });
  return data;
}

export async function listTeachers(params: ListUsersFilters = {}) {
  const { data } = await requester().get<JsonApiResponse<Teacher>>(
    'teachers/',
    {
      params: { ...params, unit: params.unit?.toString() },
    },
  );
  return data;
}

export async function listUser(params: ListUsersFilters = {}) {
  const userType = params.userType?.length
    ? String(params.userType)
    : undefined;

  const exUserType = params.exUserType?.length
    ? String(params.exUserType)
    : undefined;

  const { data } = await requester().get<JsonApiResponse<User>>('users/', {
    params: { ...params, userType, exUserType },
  });
  return data;
}

export async function getUser(userId: number) {
  const { data } = await requester().get<User>(`users/${userId}/`);

  return data;
}

export const getUserByUsername = async (username?: string) => {
  const { data } = await requester().get<JsonApiResponse<User>>(`users/`, {
    params: { username },
  });
  const [user] = data.results.filter(user => user.username === username);
  return user;
};

type UserForm = Partial<Omit<User, 'id' | 'profile'>>;

type CreateUser = UserForm & {
  profile?: Partial<UserProfile>;
};

export async function createUser(body: CreateUser) {
  const { data: user } = await requester().post<User>(`users/`, body);
  return user;
}

export async function updateUser(userId: number, user: UserForm) {
  const { data: changedUser } = await requester().patch<User>(
    `users/${userId}/`,
    user,
  );
  return changedUser;
}

export async function postUsername(newUsername: string) {
  const { data } = await requester().post(`users/set_username/`, {
    newUsername,
  });
  return data;
}
export async function postEmail(newEmail: string) {
  const { data } = await requester().post(`users/set_email/`, { newEmail });
  return data;
}
export async function postPassword(
  newPassword: string,
  reNewPassword: string,
  currentPassword: string,
) {
  const { data } = await requester().post(`users/set_password/`, {
    newPassword,
    reNewPassword,
    currentPassword,
  });
  return data;
}

type UpdatePasswordProps = {
  userId: number;
  password: string;
};

export const updateStudentPassword = async ({
  userId,
  password,
}: UpdatePasswordProps) => {
  const { data } = await requester().patch(
    `students/${userId}/change_password/`,
    {
      password,
    },
  );

  return data;
};
