import {
  QueryKey,
  UseInfiniteQueryOptions,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query';
import JsonApiResponse from '@/models/JsonApiResponse';
import {
  getNextPageParam,
  getPreviousPageParam,
} from '@/utils/infinityQueries';

type T<
  Model,
  TError = unknown,
  TQueryKey extends QueryKey = QueryKey,
> = UseInfiniteQueryOptions<
  JsonApiResponse<Model>,
  TError,
  JsonApiResponse<Model>,
  JsonApiResponse<Model>,
  TQueryKey
>;

export default function useInfiniteService<
  Model,
  TError = unknown,
  TQueryKey extends QueryKey = QueryKey,
>(options: T<Model, TError, TQueryKey>) {
  const { data, ...rest } = useInfiniteQuery({
    getNextPageParam,
    getPreviousPageParam,
    ...options,
  });

  const results = data?.pages?.flatMap(page => page?.results) ?? [];

  const meta = data?.pages?.[data.pages.length - 1].meta;

  const links = data?.pages?.flatMap(page => page?.links) ?? [];

  const queryClient = useQueryClient();

  const invalidate = () => queryClient.invalidateQueries(options.queryKey);

  return { ...rest, results, meta, links, invalidate };
}
