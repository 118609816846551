import { PencilIcon } from '@heroicons/react/outline';
import { useQueries } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import { IconWithText } from '@/components/common/dataDisplay/IconWithText';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import { shortActivitiesProgressesQueryKeys } from '@/data/services/querykeys';
import { scrollBarClassName } from '@/utils/scrollBarClassName';
import Text from '@/components/common/dataDisplay/Text';
import Skeleton from '@/components/common/Skeleton';
import { ActivityProgressItem } from './ActivityProgressItem';

type ActivityProgressViewProps = {
  coursePathSlug: string;
  klassId?: number;
  loading?: boolean;
  activitiesIds: number[];
};

export function ActivityProgressView({
  coursePathSlug,
  klassId,
  loading,
  activitiesIds,
}: ActivityProgressViewProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard',
  });

  const activitiesRequest = useQueries({
    queries: activitiesIds.map(activityId => ({
      ...shortActivitiesProgressesQueryKeys.list({
        activityId,
        isActive: true,
      }),
      staleTime: REQUEST_STALE_TIME_IN_MS,
      enabled: !!activitiesIds.length,
      placeholderData: {
        results: [
          {
            hasDone: false,
            activityName: '',
            lessonId: 0,
            id: 0,
            activityId,
          },
        ],
      },
    })),
  });

  const activitiesCount = activitiesRequest?.length;

  const hasDoneActivities = activitiesRequest.reduce(
    (prev, { data }) => (data?.results?.at(0)?.hasDone ? prev + 1 : prev),
    0,
  );

  return (
    <div className="flex flex-col gap-3 justify-start items-start w-[50%] h-full">
      <IconWithText
        icon={
          <PencilIcon
            className="shrink-0 w-5 md:w-6 h-auto text-base-100 bg-accent rounded-full p-0.5"
            strokeWidth={1.3}
          />
        }
        text={t('totalActivities')}
        className={{ text: 'sm:text-18 md:text-20', base: 'text-accent' }}
      />

      <ConditionalRenderer condition={!!klassId}>
        <div
          className="flex self-end items-end"
          data-testid="activityProgressCount"
        >
          <Text
            text={hasDoneActivities}
            format="rubik-500"
            size="text-16 md:text-24"
            className="flex text-secondary items-center"
          />

          <Text
            testId="activitiesCount"
            text={'/' + activitiesCount}
            format="rubik-500"
            size="text-12 md:text-16"
            className="flex text-accent items-center"
          />
        </div>
      </ConditionalRenderer>

      <ConditionalRenderer
        condition={!loading}
        fallback={
          <Skeleton className="w-full h-full bg-primary-content rounded-lg" />
        }
      >
        <ul
          className={`flex flex-wrap self-start gap-1 items-start ${scrollBarClassName}`}
        >
          {activitiesRequest?.map(({ data, isFetching }) => {
            return (
              <ActivityProgressItem
                key={data?.results.at(0)?.activityId}
                activityProgress={data?.results.at(0)}
                loading={isFetching}
                coursePathSlug={coursePathSlug}
                klassId={klassId}
              />
            );
          })}
        </ul>
      </ConditionalRenderer>
    </div>
  );
}
