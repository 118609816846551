import { useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';

import { StudentReportEditProps } from '../teacher/StudentReportEdit';
import ScheduledLessonReport, {
  GradeEnum,
} from '@/models/ScheduledLessonReport';
import { StudentRatingReport } from '../teacher/StudentRatingReport';
import { AccordionTextarea } from '@/components/common/dataInput/AccordionTextarea';
import {
  AssignmentType,
  HomeworkActivityProgress,
} from '@/models/HomeworkActivity';
import SaveCancelGroup from '@/components/common/buttons/SaveCancelGroup';
import useStudentReport from '@/data/hook/useStudentReport';
import { updateMgActivityProgress } from '@/data/services/mgActivityProgressServices';
import { Reward } from '@/models/Rewards';
import { buildChangedObject } from '@/utils/buildChangedObject';

type PickProps = Pick<StudentReportEditProps, 'enrollment' | 'canEditCash'>;

type StudentReportFormProps = Required<PickProps> & {
  homeworkProgress?: HomeworkActivityProgress;
  report: ScheduledLessonReport;
  invalidateReports?: () => Promise<void>;
  invalidateHomeworks?: () => Promise<void>;
  reward: Reward;
};

export type ReportFields = ScheduledLessonReport & {
  notDone: boolean;
  homeworkGrade: GradeEnum | null;
  notes: string;
  allValues?: GradeEnum | null;
};

export function StudentReportForm({
  reward,
  enrollment,
  canEditCash,
  homeworkProgress,
  report,
  invalidateReports,
  invalidateHomeworks,
}: StudentReportFormProps) {
  const [changed, setChanged] = useState(false);
  const [loadingUpdateHomework, setLoadingUpdateHomweork] = useState(false);

  const { update, loadingUpdate: loadingUpdateReport } = useStudentReport();

  const checkHomework = () => {
    const hasAssignment =
      homeworkProgress?.homework.assignmentType === AssignmentType.TEXT;

    if (hasAssignment)
      return (
        !!homeworkProgress.answer || !!homeworkProgress.activityProgress.grade
      );

    return homeworkProgress?.activityProgress.hasDone ?? false;
  };

  const isHomeworkDone = checkHomework();

  const defaultValues = useMemo<ReportFields>(
    () => ({
      ...report,
      presenceReward: report.presenceReward ?? reward.amount,
      homeworkGrade: homeworkProgress?.activityProgress.grade || null,
      notDone: !isHomeworkDone,
      notes: report.notes || '',
      allValues: null,
    }),
    [
      homeworkProgress?.activityProgress.grade,
      isHomeworkDone,
      report,
      reward.amount,
    ],
  );

  const { control, handleSubmit, register, reset, watch } =
    useForm<ReportFields>({
      defaultValues,
    });

  useEffect(() => {
    reset(defaultValues);
    setChanged(false);
  }, [defaultValues, reset]);

  useEffect(() => {
    const subscription = watch(() => setChanged(true));
    return () => subscription.unsubscribe();
  }, [watch, setChanged]);

  const resetFields = () => {
    reset(defaultValues);
    setChanged(false);
  };

  const onSubmitHomework = async (
    changes: Partial<Pick<ReportFields, 'homeworkGrade' | 'notDone'>>,
  ) => {
    const homeworkChanged = 'homeworkGrade' in changes || 'notDone' in changes;

    if (homeworkChanged && homeworkProgress) {
      const { homeworkGrade, notDone } = changes;
      const mgActivityProgressId = homeworkProgress.activityProgress.id;

      const mgActivityProgressData = await updateMgActivityProgress(
        mgActivityProgressId,
        {
          grade: notDone ? 0 : homeworkGrade,
          hasDone: !notDone,
        },
      );

      if (mgActivityProgressData.id) {
        try {
          setLoadingUpdateHomweork(true);
          await invalidateHomeworks?.();
          setChanged(false);
        } finally {
          setLoadingUpdateHomweork(false);
        }
      }
    }
  };

  const onSubmitReport = async (
    changes: Partial<Omit<ReportFields, 'homeworkGrade' | 'notDone'>>,
  ) => {
    const reportChanged = Object.keys(changes).length;

    if (reportChanged) {
      const reportData = await update({
        id: report.id,
        changes,
      });
      if (reportData.id) {
        await invalidateReports?.();
        setChanged(false);
      }
    }
  };

  const onSubmit = async (changes: ReportFields) => {
    const changedFields = buildChangedObject<ReportFields>(
      defaultValues,
      changes,
    );

    const { homeworkGrade, notDone, ...rest } = changedFields;

    await onSubmitHomework({ homeworkGrade, notDone });

    await onSubmitReport(rest);
  };

  return (
    <form
      data-testid="studentReportEdit"
      className="flex flex-col gap-y-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <StudentRatingReport
        key={homeworkProgress?.id}
        control={control}
        homeworkProgress={homeworkProgress}
        rewardAmount={reward.amount}
        locked={!canEditCash}
      />

      <AccordionTextarea
        register={register('notes')}
        testId="accordionTextarea"
        studentName={enrollment.student.firstName}
      />

      <SaveCancelGroup
        loading={loadingUpdateReport || loadingUpdateHomework}
        cancel={{
          onClick: resetFields,
          testId: 'cancelButton',
        }}
        save={{
          disabled: !changed,
          testId: 'saveButton',
        }}
      />
    </form>
  );
}
