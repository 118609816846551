import { TeacherKlasses } from '@/components/teacher/TeacherKlasses';
import { TeacherReports } from '@/components/teacher/TeacherReports';
import { useTranslation } from 'react-i18next';
import Text from '@/components/common/dataDisplay/Text';
import useAuth from '@/data/hook/useAuth';
import { ListKlassesFilters } from '@/data/services/klassServices';
import Klass, { KlassStatusEnum } from '@/models/Klass';
import PendingHomeworks from '@/components/teacher/PendingHomeworks';
import SuggestionsCard from '@/components/common/SuggestionsCard';
import { KlassMembers } from '@/components/dashboard/student/KlassMembers';
import { useState } from 'react';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import { translateKlassName } from '@/utils/translateKlassName';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import AbsenceReports from '@/components/teacher/AbsenceReport/AbsenceReports';
import useInfiniteService from '@/data/hook/useInfiniteService';
import { klassesQueryKeys } from '@/data/services/querykeys';

export default function TeacherDashboardView() {
  const { user } = useAuth();

  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard',
  });

  const filters: ListKlassesFilters = {
    status: [KlassStatusEnum.IN_PROGRESS, KlassStatusEnum.OPEN],
    teacherId: user?.id ?? 0,
  };

  const {
    results: klasses,
    isInitialLoading: isLoadingKlasses,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteService({
    keepPreviousData: true,
    ...klassesQueryKeys.list(filters)._ctx.infinity,
  });

  const [selectedKlass, setSelectedKlass] = useState<Klass>();

  return (
    <div className="flex flex-col gap-6 h-full ">
      <div className="flex flex-col gap-4">
        <Text
          text={t('hello') + user?.firstName + ' 👋'}
          className="text-primary"
          format="poppins-600"
          size="text-28"
        />
        <Text
          text={t('wellcome.teacher')}
          format="rubik-400"
          size="text-16"
          className="break-words"
        />
      </div>
      <div
        className="flex flex-wrap h-fit max-h-[82vh]
       items-start gap-2.5 md:gap-5 flex-col sm:flex-row transition ease-in-out duration-500"
      >
        <TeacherKlasses
          klasses={klasses}
          isLoading={isLoadingKlasses}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
        />
        <TeacherReports klasses={klasses ?? []} />
        <PendingHomeworks />
      </div>
      <InfiniteSearchInput
        service={klassesQueryKeys.list}
        filters={{ unitId: getAuthorizedUnits(user) }}
        displayName={klass => translateKlassName(klass.name)}
        className={{ base: 'w-full max-w-sm' }}
        onSelect={klass => setSelectedKlass(klass)}
        onDeselect={() => setSelectedKlass(undefined)}
        input={{ className: { base: 'w-full sm:w-96 font-500' } }}
      />
      <div className="flex flex-col gap-5 md:flex-row">
        <KlassMembers klass={selectedKlass} />
        <AbsenceReports />
      </div>
      <SuggestionsCard />
    </div>
  );
}
