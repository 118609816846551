import ComponentGuard from '@/components/common/ComponentGuard';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import ConditionalWrapper from '@/components/common/ConditionalWrapper';
import Skeleton from '@/components/common/Skeleton';
import TooltipHandler from '@/components/common/TooltipHandler';
import IconButton from '@/components/common/buttons/IconButton';
import StudentListTooltip from '@/components/common/dataDisplay/StudentListTooltip';
import Tag from '@/components/common/dataDisplay/Tag';
import Text from '@/components/common/dataDisplay/Text';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import { ActivityColorIcon, DashedCircleIcon } from '@/components/icons';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import useAuth from '@/data/hook/useAuth';
import { klassesQueryKeys, usersQueryKeys } from '@/data/services/querykeys';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import { isAdmin, isSuperAdmin } from '@/functions/auth';
import { formatLessonName } from '@/functions/lessonsName';
import Klass from '@/models/Klass';
import ScheduledLesson, {
  ScheduledLessonTypeEnum,
} from '@/models/ScheduledLesson';
import User, { UserTypeEnum } from '@/models/User';
import {
  DownloadIcon,
  LinkIcon,
  PencilIcon,
  UsersIcon,
} from '@heroicons/react/outline';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import ModalHandler, { ModalState } from '../ModalHandler';
import { AlterDatePill } from './AlterDatePill';
import { AnticipatePostponePill } from './AnticipatePostponePill';
import { AttendancePill } from './AttendancePill';
import { ProgressPill } from './ProgressPill';
import { TeacherPill } from './TeacherPill';

type ManageClassProps = ScheduledLesson & {
  klassInfo?: Klass;
  teacherInfo?: User;
  isLoading: boolean;
  updateLessons?: (a: any) => Promise<void>;
};

export type StudentProgress = {
  student: string;
  progress: number;
  activitiesNotDone: number;
  chaptersUnread: number;
  presence: null | boolean;
};

export default function ManageScheduledLesson(props: ManageClassProps) {
  const {
    id,
    isLoading,
    klassInfo,
    teacherInfo,
    progress,
    type,
    lesson,
    updateLessons,
    klass: klassId,
    hasDone,
    datetime,
    presences,
    absences,
    url,
    isActive,
    teacher: teacherId,
  } = props;
  const { user } = useAuth();
  const navigate = useNavigate();

  const canAnticipate = !hasDone || moment(datetime).isAfter(moment());

  const { data: queryTeacher, isInitialLoading: isLoadingTeacher } = useQuery({
    ...usersQueryKeys.get(teacherId),
    enabled: !!teacherId && !teacherInfo,
  });

  const { data: klassData, isInitialLoading: loadingKlass } = useQuery({
    ...klassesQueryKeys.get(klassId),
    enabled: !isNaN(klassId) && !klassInfo,
    staleTime: REQUEST_STALE_TIME_IN_MS,
    initialData: klassInfo,
  });

  const klass = klassInfo || klassData;

  const teacher = teacherInfo ?? queryTeacher;

  const adminView = isAdmin(user?.userType);

  const disableReports = klass?.enrollsCount === 0 && !adminView;

  const clickableStyle =
    'cursor-pointer hover:opacity-75 transistion transition-all duration-75 ease-in-out';
  const [isEditView, setIsEditView] = useState(false);
  const [modalState, setModalState] = useState<ModalState>(ModalState.CLOSED);

  const { t } = useTranslation('translation', {
    keyPrefix: 'calendar.admin.manageKlass',
  });
  const { t: tCommon } = useTranslation('translation', {
    keyPrefix: 'common',
  });

  const checkUserEditPermission = () => {
    function isFromKlassUnit() {
      return klass?.unitId ? user?.unitsIds.includes(klass?.unitId) : false;
    }

    return isSuperAdmin(user?.userType) || isFromKlassUnit();
  };

  const hasPermissionToEdit = checkUserEditPermission();

  const now = moment();

  const datetimeCondition = moment(datetime)
    .add(klass?.duration, 'minutes')
    .isBefore(now);

  const isDelayed =
    !hasDone && datetimeCondition && klass && klass.enrollsCount > 0;

  const RenderReportButton = ({ disabled }: { disabled: boolean }) => {
    const tooltipMessage = disabled ? t('noStudentsInClass') : t('report');
    return (
      <div className="flex gap-2 items-center">
        <Tooltip text={tooltipMessage}>
          <ConditionalWrapper
            condition={!disabled}
            wrapper={
              <Link
                data-testid="report-link"
                to={'/class/' + klassId + '/lesson-report/' + id}
              />
            }
          >
            <IconButton
              disabled={disabled}
              className={`p-0.5 mr-2 bg-base-100 rounded-full border ${
                isDelayed ? 'border-warning' : 'border-primary'
              }  hover:ring ring-primary-content hover:bg-primary-content disabled:cursor-not-allowed
              transition ease-in-out duration-200 transform hover:scale-105`}
              icon={<ActivityColorIcon className="w-6 h-6" />}
            />
          </ConditionalWrapper>
        </Tooltip>
        <ConditionalRenderer condition={isDelayed && isActive}>
          <Tag color="warning" text={t('delayedReport')} />
        </ConditionalRenderer>
      </div>
    );
  };

  const publishedLesson = lesson.status === VersioningStatusEnum.PUBLISHED;

  const bankNotPublished = type === ScheduledLessonTypeEnum.EXTRA && !isActive;

  if (isLoading || loadingKlass)
    return (
      <Skeleton className="w-full rounded-lg min-h-20 bg-primary-content" />
    );

  return (
    <div
      data-testid="manageScheduledLesson"
      className={`flex items-center border h-fit ${
        hasDone ? 'border-success' : 'border-secondary'
      } rounded-lg ${
        hasDone ? 'bg-success-content' : 'bg-secondary-content'
      } p-3.5 manage-scheduled-lesson ${
        isActive ? '' : 'opacity-20 pointer-events-none'
      }`}
    >
      <span
        className={`w-14 h-14 shrink-0 overflow-hidden ${
          hasDone ? 'text-success' : 'bg-transparent'
        } mr-2`}
      >
        {hasDone ? (
          <CheckCircleIcon className="h-14 w-14" strokeWidth={4} />
        ) : (
          <DashedCircleIcon
            strokeWidth={1}
            strokeDasharray="2 2"
            data-testid="dashed-circle"
            className="stroke-1 w-14 h-14 text-secondary/40"
          />
        )}
      </span>
      <div className="w-full">
        <div className="flex-col flex lg:flex-row justify-between items-center lg:items-start mb-2 gap-1 lg:gap-0">
          <Text
            text={formatLessonName(lesson, props)}
            isLink={!!klass}
            format="rubik-500"
            className="flex w-full"
            onClick={() => {
              if (klass)
                navigate(
                  `/courses/${klass.coursePathSlug}/lesson/${lesson.id}/class/${klass.id}`,
                );
            }}
          />
          <div className="inline-flex shrink-0 w-full lg:w-auto lg:min-w-fit items-start lg:items-center text-primary font-700 gap-x-3">
            <div className="flex items-center gap-x-2 h-fit">
              <span className="font-rubik text-14 leading-none">
                {klass?.name || ''}
              </span>
              <div className="inline-flex ml-3 font-rubik">
                <StudentListTooltip klassId={klass?.id || 0}>
                  <span
                    data-testid="klassLength"
                    className="flex text-primary items-center gap-x-1"
                  >
                    <Text text={klass?.enrollsCount} format="rubik-500" />
                    <UsersIcon className="h-3.5 stroke-[3]" />
                  </span>
                </StudentListTooltip>
              </div>
            </div>
            <ConditionalRenderer condition={!hasDone}>
              {isEditView ? (
                <div
                  data-testid="classroom-url"
                  className={`flex items-center font-700 gap-x-1 shrink-0 ${clickableStyle}`}
                  onClick={() => {
                    setModalState(ModalState.KLASS_LINK);
                  }}
                >
                  <LinkIcon className="w-4 h-4" />
                  <Text text={t('alterLink')} format="rubik-700" />
                </div>
              ) : (
                <Tooltip
                  text={url ? t('klassLink') : t('noKlassLink')}
                  className="flex items-center font-400"
                >
                  <a
                    data-testid="classroom-url"
                    href={url || klass?.classRoomUrl || ''}
                    rel="noreferrer"
                    target="_blank"
                    className={`flex items-center font-700 gap-x-1 shrink-0 ${
                      url ? '' : 'disabled text-neutral/50'
                    } ${clickableStyle}`}
                  >
                    <LinkIcon className="w-4 h-4" />
                    <Text text={t('accessKlass')} format="rubik-700" />
                  </a>
                </Tooltip>
              )}
              <ComponentGuard
                roles={[
                  UserTypeEnum.SUPER_ADMIN,
                  UserTypeEnum.UNIT_ADMIN,
                  UserTypeEnum.TEACHER,
                ]}
              >
                <ConditionalRenderer condition={hasPermissionToEdit}>
                  <TooltipHandler
                    renderTooltip={!klass}
                    tooltipMessage={t('noKlassTooltip')}
                    className="font-400"
                  >
                    <button
                      className={`flex shrink-0 ${
                        klass
                          ? ''
                          : 'grayscale pointer-events-none cursor-default'
                      } ${clickableStyle}`}
                      onClick={() => setIsEditView(!isEditView)}
                    >
                      <span className="inline-block mr-1 align-middle">
                        {isEditView ? (
                          <DownloadIcon className="w-4 h-4" />
                        ) : (
                          <PencilIcon className="h-4 w-4" />
                        )}
                      </span>
                      <Text
                        text={isEditView ? t('finish') : t('edit')}
                        format="rubik-700"
                        className="text-primary inline"
                      />
                    </button>
                  </TooltipHandler>
                </ConditionalRenderer>
              </ComponentGuard>
            </ConditionalRenderer>
          </div>
        </div>
        <div className="flex gap-y-1 flex-wrap items-center justify-between">
          <AlterDatePill
            dateTime={moment(datetime).toDate()}
            klassDuration={klass?.duration}
            isEditView={isEditView}
            hasDone={hasDone}
            setModalState={() => setModalState(ModalState.KLASS_DATE)}
          />
          <ConditionalRenderer condition={hasDone && klass && progress}>
            {klass && (
              <AttendancePill
                presences={presences}
                absences={absences}
                studentsCount={klass.enrollsCount}
              />
            )}
            {progress && <ProgressPill progress={progress} />}
          </ConditionalRenderer>

          <ComponentGuard
            roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
          >
            {isEditView && !hasDone && (
              <Tooltip text={!canAnticipate ? t('doNotAnticipateTooltip') : ''}>
                <AnticipatePostponePill
                  state={ModalState.ANTICIPATE}
                  setModalState={() => setModalState(ModalState.ANTICIPATE)}
                  isDisable={!canAnticipate}
                />
              </Tooltip>
            )}
          </ComponentGuard>

          <ComponentGuard
            roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
          >
            {isEditView && !hasDone && (
              <AnticipatePostponePill
                state={ModalState.POSTPONE}
                setModalState={() => setModalState(ModalState.POSTPONE)}
                isDisable={false}
              />
            )}
          </ComponentGuard>

          <ConditionalRenderer condition={publishedLesson}>
            <RenderReportButton disabled={disableReports} />
          </ConditionalRenderer>

          <ConditionalRenderer condition={!publishedLesson || bankNotPublished}>
            <Tag
              color="warning"
              className="shrink-0 rounded-full px-3 py-1 font-500"
              text={tCommon('lessonUnpublished')}
            />
          </ConditionalRenderer>

          <ComponentGuard
            roles={[UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN]}
          >
            <TeacherPill
              isLoading={isLoadingTeacher}
              teacherInfo={teacher}
              setModalState={() => setModalState(ModalState.TEACHER)}
              isEditView={isEditView}
              hasDone={hasDone}
            />
          </ComponentGuard>
        </div>
      </div>
      <ComponentGuard
        roles={[
          UserTypeEnum.SUPER_ADMIN,
          UserTypeEnum.UNIT_ADMIN,
          UserTypeEnum.TEACHER,
        ]}
      >
        {klass && (
          <ModalHandler
            updateLessons={updateLessons}
            scheduledLesson={{ ...props } as ScheduledLesson}
            klassInfo={klass}
            modalState={modalState}
            teacherInfo={teacher}
            closeModal={() => setModalState(ModalState.CLOSED)}
            redirectToModal={setModalState}
          />
        )}
      </ComponentGuard>
    </div>
  );
}
