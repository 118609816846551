const apiUrl = process.env.REACT_APP_API_URL ?? '127.0.0.1:8000';
const isProductionEnv = process.env.NODE_ENV === 'production';

const httpProtocol = isProductionEnv ? 'https' : 'http';

const API_ROUTE = `${httpProtocol}://${apiUrl}`;

const BASE_URL = `${API_ROUTE}/api/v1/`;

const BASE_IMAGE_URL = API_ROUTE;

const wsProtocol = isProductionEnv ? 'wss' : 'ws';
const BASE_WS_URL = `${wsProtocol}://${apiUrl}/ws/v1`;

const ADOBE_PUBLISHER_URL = 'https://indd.adobe.com/';

const config = {
  BASE_URL,
  BASE_WS_URL,
  API_TIMEOUT_MILISECONDS: 180000,
  TOAST_DURATION: 2,
  ADOBE_PUBLISHER_URL,
  BASE_IMAGE_URL,
  SPINNER_DELAY_MILISECONDS: 50,
  DEFAULT_QUIZ_TIME_IN_SECONDS: 15,
  NYC3_BASE_URL: `https://nyc3.digitaloceanspaces.com/astro-space-${
    'dev' || 'prod'
  }`,
  ALTERNATIVE_DEFAULT_IMAGE: '/images/course-default-image.png',
};
export default config;
