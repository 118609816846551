import { KlassStatusEnum } from '@/models/Klass';
import TranslateInterface, {
  Sheet,
  TranslateBehaviorAssessmentForm,
  TranslateCommentAssessmentForm,
} from './translateInterface';
import {
  GameErrorCodes,
  GroundToken,
  InteractiveToken,
} from '@ctrlplayteam/astro-code';
import { ContentGivenEnum, PresenceEnum } from '@/models/ScheduledLessonReport';

const userStatus = {
  REGISTRED: 'Registred',
  ACTIVE_ENROLLMENT: 'Active Enrollment',
  INACTIVE: 'Inactive',
  WAITING_ENROLLMENT: 'Waiting Enrollment',
};

const klassStatus: Record<KlassStatusEnum, string> = {
  OPEN: 'Open',
  IN_PROGRESS: 'In progress',
  CONCLUDED: 'Concluded',
  CANCELED: 'Canceled',
};

const presenceStatus: Record<PresenceEnum, string> = {
  ATTENDED: 'Attended',
  MISSED: 'Missed',
  NONE: 'No data',
  RESCHEDULED: 'Rescheduled',
};

const sheet: Sheet = {
  title: 'Title',
  comment: 'Comment',
  assessment: 'Assessment',
  assessments: {
    noInfo: 'No information added about',
    loadMore: 'Load more',
    moreDetails: 'More details',
  },
};

const behaviorAssessmentForm: TranslateBehaviorAssessmentForm = {
  successCreatedMessage: 'Assessment created successfully',
  successUpdatedMessage: 'Assessment edited successfully',
  labels: {
    energy: 'Energy',
    maturity: 'Maturity',
    speed: 'Speed',
    attention: 'Attention',
    organization: 'Organization',
    initiative: 'Interest/Initiative',
    socialization: 'Socialization',
    apprenticeship: 'Apprenticeship',
  },
};

const commentAssessmentForm: TranslateCommentAssessmentForm = {
  successCreatedMessage: 'Comment created successfully',
  successUpdatedMessage: 'Comment edited successfully',
  commentInput: {
    label: {
      studentLabel: 'Add comment',
      klassLabel: 'Add observation',
    },

    placeholder: {
      studentCommentAbout:
        'Write a comment about {{name}}, the information added to the history will be for internal use only and will not be viewed by the student or guardian',
      klassCommentAbout:
        'Write a note about the class {{name}} , the information added will be for internal use and will not be viewed by the students or guardians',
    },
  },
  klassInput: {
    placeholder: 'Select a class',
  },
};

const en: TranslateInterface = {
  errors: {
    serverError: 'Internal Error, please try again later or contact support',
    errorPage: {
      backButton: 'Back',
      panelButton: 'Panel',
      reloadButton: 'Reload',
      notFound: {
        title: 'Oops! This page does not exist',
        subtitle: 'The page you are looking for was not found',
      },
      activityNotFound: {
        title: 'Oops! This activity does not exist',
        subtitle:
          'The activity you are looking for was not found, please check that the url is correct',
      },
      internError: {
        title: 'Oops! Something went wrong',
        subtitle: "We'll fix the problem, please try again later.",
      },
      materialNotFound: {
        title: 'Oops! This material does not exist',
        subtitle:
          'The activity you are looking for was not found, please check that the url is correct',
      },
      forbidden: {
        title: 'Oops! You do not have permission to access this page',
        subtitle:
          'You do not have permission to access this page, please contact the administrator',
      },
      badRequest: {
        title: "Oops! something didn't work as expected",
        subtitle: "We'll fix the problem, please try again later.",
      },
      lessonForbidden: {
        title: 'Oops! You do not have permission to access this lesson',
        subtitle:
          'Make sure you are enrolled in this course or contact an administrator',
      },
      reactException: {
        title: 'Oops! It is rare but it happens!',
        subtitle:
          'Try reloading the page, if the error persists, we will solve it as soon as possible!',
      },
    },
    errorPrefix: 'Fix the following errors: ',
  },
  formValidation: {
    user: {
      username: {
        pattern: 'Just letters, numbers and . - _ are accepted for username',
        minLength: 'Usernane must have at least 5 charactere',
        usernameExists: 'Username already exists',
        startsLetters: 'Username can start only with letters',
        required: 'Username is required',
      },
    },
  },
  common: {
    course: 'Course',
    lessons: 'Lessons',
    saved: 'Saved',
    clear: 'Clear',
    dateRangeInput: {
      selectDate: 'select dates',
      cancelSelect: 'cancel selection',
    },
    copied: 'Copied',
    copy: 'Copy',
    collapse: 'Collapse',
    expand: 'Expand',
    scheduledLessonReportStatus: {
      ACTIVE: 'Active report',
      CANCELED: 'Canceled report',
      TRANSFERED: 'Transfered report',
      NOT_ENROLLED: 'Not active in this lesson',
    },
    userType: {
      Super_Admin: 'Super Admin',
      Unit_Admin: 'Admin',
      Teacher: 'Teacher',
      Parent: 'Parent',
      Student: 'Student',
    },
    studentAge: {
      sixOrLess: '6 or less',
      sevenToNine: '7 to 9',
      tenToThirteen: '10 to 13',
      fourteenToSeveteen: '14 to 17',
    },
    madeBy: '© Made by',
    lessonUnpublished: 'Lesson unpublished',
    withLove: 'with',
    inBrazil: 'in 🇧🇷',
    userNotFound: 'User not found',
    courses: 'Courses',
    add: 'Add',
    filter: {
      admin: 'Admin',
      teacher: 'Teacher',
      clearButton: 'Clear',
      okButton: 'OK',
    },
    months: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
    },

    day: 'Day',
    confirm: 'Confirm',
    cancel: 'Cancel',
    save: 'Save',
    never: 'Never',
    generalInformation: 'General information',
    alterPassword: 'Alter password',
    rewards: 'Rewards',
    lastLogin: 'Last login',
    form: {
      required: 'This field is required',
    },
    completed: 'Completed',
    lessonTitle: 'Lesson title',
    edit: 'Edit',
    notEditable: 'Not editable',
  },
  imageInput: {
    chooseImage: 'Choose an image',
    delete: 'Delete',
  },
  login: {
    sendEmail: {
      forgotPassword: 'Forgot my password',
      emailLabel: 'E-mail',
      weRecoverPassword: "We'll recover your password",
      back: 'Back',
      emailSent: 'Email successfully sent',
      emailPlaceholder: 'Type your e-mail',
      usernamePlaceholder: 'Type your username',
      submit: 'Submit',
      checkEmail:
        'Check your email for instructions to finalize your password reset',
      specifyUser: 'Specify user to change password',
      userTypeRequired: 'Unspecified user type',
      usernameRequired: 'Username is required',
    },
    resetPassword: {
      alreadyRegister: 'Already have an account?',
      confirmNewPassword: 'Confirm the new password',
      confirmRedefine: 'Reset password',
      login: 'Login here',
      typeNewPassword: 'Type your new password',
      newPassword: 'New password',
      redefinePassword: 'Reset your password',
      differentPasswordError: 'The two passwords do not match!',
      modal: {
        canLogin: 'You already can login using your new password',
        success: 'Your password was reseted successfuly',
      },
    },
    motivationalMessage: 'Ready to start learning? 🚀',
    loginAccount: 'Log in with your account',
    login: 'Login',
    loginPlaceholder: 'Type your e-mail or username',
    password: 'password',
    passwordPlaceholder: 'Type your password',
    keepConnected: 'Keep me connected',
    signIn: 'Sign In',
    forgotPassword: 'Forgot your password?',
  },

  newAccount: {
    questionText: 'New around here?',
    linkText: 'Create a free account',
  },

  tableHeader: {
    search: 'Search',
    filter: 'Filter',
    increasing: 'Increasing',
    decreasing: 'Decreasing',
  },

  modalAddStudent: {
    addStudentPhrase: 'Which students would you like to add?',
    confirm: 'Confirm',
    cancel: 'Cancel',
  },

  modalChangePassword: {
    passwordChangeMessage:
      'Are you sure you want to generate a new password for this student?',
    confirm: 'Confirm',
    cancel: 'Cancel',

    warning: 'Warning!',
    lostChangesWarning:
      'You have unsaved changes that will be lost if you leave the page',
    confirmLeave: 'Are you sure you want to leave?',
    leave: 'Leave',
    stay: 'Stay',

    passwordChangeSuccess: 'New password generated successfully!',
    emailSent:
      "The new password has been sent to the student's and guardian's email",
    close: 'Close',
  },

  modalWarning: {
    modalAnswerKey: {
      attention: 'Attention!',
      warning:
        'If you open the answers key, you will not be able to redo the activity anymore',
      proceed: 'Do you want to see the answer key now?',
      confirm: 'See Answers',
      cancel: 'Cancel',
    },

    modalLeaveActivityPage: {
      attention: 'Attention!',
      warning: 'If you leave the activity, your progress will not be saved',
      proceed: 'Are you sure you want to quit?',
      confirm: 'Leave',
      cancel: 'Stay',
    },

    modalSaveChanges: {
      attention: 'Are you sure you want to change it?',
      warning: 'This action will record the information',
      proceed: '',
      confirm: 'Confirm',
      cancel: 'Cancel',
    },

    modalDiscardChanges: {
      attention: 'Are you sure you want to cancel the changes?',
      warning: 'This action will not save the information',
      proceed: '',
      confirm: 'Confirm',
      cancel: 'Cancel',
    },

    modalSaveHomework: {
      attention: 'Attention!',
      warning: 'The assignment will be published to all students',
      proceed: 'Are you sure you want to publish it?',
      confirm: 'Publish',
      cancel: 'Cancel',
    },

    modalDeleteChapter: {
      attention: 'Attention!',
      warning: 'Are you sure you want to delete this chapter?',
      proceed: 'This action cannot be undone.',
      confirm: 'Confirm',
      cancel: 'Cancel',
    },

    lessonReportModal: {
      attention: 'Are you sure you want to mark the class as complete?',
      warning: '',
      proceed: 'You will not be able to undo this action.',
      confirm: 'Confirm',
      cancel: 'Cancel',
    },

    modalNoComment: {
      attention: 'No comments were added for the student',
      warning: 'Are you sure you want to proceed?',
      proceed: '',
      confirm: 'Confirm',
      cancel: 'Cancel',
    },

    modalChangeKlass: {
      attention: 'Changes made to these classes were not saved',
      cancel: 'Cancel',
      confirm: 'Confirm',
      warning:
        'Selecting a new class or leaving the page will cause you to lose your changes',

      proceed: 'Are you sure you want to proceed?',
    },
  },

  modalDisable: {
    disable: 'Disable',
    cancel: 'Cancel',
    delete: 'Delete',
    confirm: 'Confirm',

    modalDisableActivityElement: {
      warning: 'Are you sure you want to disable this question?',
      lostChangesWarning:
        'This action will not delete question information for students',
    },

    modalDisableCourse: {
      warning: 'Are you sure you want to disable this course?',
      lostChangesWarning:
        'This action will not delete student progress or information',
    },

    modalDisableChallenge: {
      warning: 'Are you sure you want to disable this challenge?',
      lostChangesWarning:
        'This action will not delete student progress or information',
    },

    modalDisableUnit: {
      warning: 'Are you sure you want to disable this unit?',
      lostChangesWarning: 'This action will preserve the informations',
    },

    modalDisableKlass: {
      warning: 'Are you sure you want to deactivate this class?',
      lostChangesWarning:
        'This action will not cause students to lose their progress',
    },

    modalDisableLesson: {
      warning: 'Are you sure you want to delete this lesson?',
      lostChangesWarning:
        'This action will not delete student progress or information',
    },
    modalDeleteLesson: {
      warning: 'Are you sure you want to delete this lesson?',
      lostChangesWarning:
        'This action will delete all changes done in this lesson',
    },

    modalDisableTeacher: {
      warning: 'Are you sure you want to disable this teacher?',
      lostChangesWarning: 'This action will preserve the informations',
    },

    modalDisableActivity: {
      warning: 'Are you sure you want to disable this activity?',
      lostChangesWarning:
        'This action will not delete student progress or information',
    },

    modalRemoveBook: {
      warning: 'Are you sure you want to remove the material?',
      lostChangesWarning:
        'This action cannot be undone and all information will be lost.',
    },

    modalStudentAbsence: {
      warning: `Are you sure you want to remove this student's attendance?`,
      lostChangesWarning:
        'This action will also remove the entire class report, including grades and annotations if they have already been filled in.',
    },

    modalDisableScheduledLesson: {
      warning: 'Are you sure you want to delete this lesson?',
      lostChangesWarning: 'This will change all scheduled lessons',
    },
    modalDisableTopic: {
      warning: 'Are you sure you want to delete this topic?',
      lostChangesWarning: 'This action may affect students progress',
    },
    modalDisableDifficulty: {
      warning: 'Are you sure you want to delete this difficulty?',
      lostChangesWarning: 'This action may affect students progress',
    },
    orderLessons: {
      lostChangesWarning:
        'Students will already receive this update and will be able to see that the order has changed.',
      warning: 'Are you sure you want to make this change?',
    },
    deleteProject: {
      warning: 'Are you sure you want to delete the project?',
      lostChangesWarning: 'This action cannot be undone',
    },
  },

  avatar: {
    profile: 'Profile',
  },

  suggestionsPage: {
    suggestions: 'Suggestions',
    seeAll: 'See all',
    subheading:
      'Here you can suggest or vote for new features, as well as see their development progress',
    updates: 'Updates',
  },

  countdown: {
    materialButton: 'Material',
    classroomButton: 'Classroom',
    nextClass: 'NEXT CLASS',
    days: 'days',
    hours: 'hours',
    minutes: 'minutes',
    seconds: 'seconds',
    accessLesson: 'Access Lesson',
    watchVideo: 'Watch Video',
    instructionUrlText:
      'This class has a video on how to install and/or use the program. Watch before your class using the button below:',
    materialText:
      'Before your class starts you can already access the material and read about it in the button below:',
    lessonText:
      'Before your class starts you can already access its content clicking in the button below:',
    accessClassroom: 'Classroom',
  },
  sideMenu: {
    home: 'Home',
    classroom: 'My Lessons',
    courses: 'Courses',
    feedback: 'Feedback',
    achievements: 'Achievements',
    notifications: 'Notification',
    admin: 'Admin',
    howWorks: 'How it Works',
    calendar: 'Calendar',
    config: 'Config',
    leave: 'Logout',
    teacher: 'Teacher',
    projects: 'Projects',
  },
  courses: {
    status: {
      EDITING: 'Editing',
      VERSIONING: 'Versioning',
      BETA: 'Beta',
      PUBLISHED: 'Published',
      NEW: 'Draft',
    },
    legalInformation: 'Legal Information',
  },
  coursePathForm: {
    codeWillOverride:
      'You are in limited edition mode. Any changes made will overwrite the previous version as soon as this version is published.',
    isbnCode: 'Insert the ISBN code or other legal information',
    saveSuccessfuly: 'Changes saved successfully',
  },
  klassPage: {
    nextKlass: 'Next Lesson',
    myLessons: 'My Lessons',
    myLessonsMessage:
      'Here you can find all the lessons available in your module',
    myLessonsCompleted: "There's no lessons to do!",
    errorMessage: {
      lessonListPhrase: 'Unable to load class list',
      nextLessonPhrase: 'Unable to load next class',
    },
  },
  lesson: {
    availableSoon: 'This lesson will be available soon!',
    availableExplain:
      'This lesson is not yet available. You will be able to access it one week before the date of the lesson, or if your teacher changes the order of the lessons',
    backLessons: 'See lessons',

    teacher: {
      activity: {
        answerError: 'An error occurred while saving the response',
        errorTextSubTitle: 'There was an error opening the activity',
        errorTextTitle: 'Activity not found',
        klass: {
          submittions: 'Submittions',
          errorTextTitle: 'Class not found',
          errorTextSubTitle: 'There was an error fetching the class',
          noStudents: 'This class has no students',
        },
      },
    },
    manage: {
      selectKlass: 'Select a klass',
      bankLesson: {
        title: 'Lesson Bank',
        details: 'Add a lesson from the lesson bank',
      },
      addLesson: {
        cantAdd: 'Unable to add lesson between two lessons that passed',
        title: 'Add Lesson',
        details: 'Add a free or regular lesson in course.',
      },
      editLesson: {
        title: 'Edit Lessons',
        details: 'Change content or order of course lessons.',
      },
    },
    access: {
      accessVideo: 'Access video',
      accessProgram: 'Access program',
      accessAndInstallation: 'Access and Installation',
      hasVideoInstallationText:
        'This class has a video on how to install and/or use the program.',
      clickToWatchVideoInstalationText:
        'Click on the buttons below to watch the video on how to use the program and to access it.',
    },
    attention: 'Attention!',
    closeActivity: {
      buttonCancel: 'Cancel',
      buttonConfirm: 'Close',
      warning: 'If you close activity your progress will not be saved',
      confirmMessage: 'Are you sure want to close activity?',
    },
    activityProgress: 'Activity',
    controlButton: {
      bookNext: 'CONTINUE',
      bookPrev: 'BACK',
      activityNext: 'NEXT',
      activityPrev: 'PREVIOUS',
      finish: 'FINISH',
    },
    activity: {
      false: 'F',
      true: 'T',
      titleHeader: 'Activities',
      start: 'Start',
      view: 'View',
      score: 'Score',
      notDone: 'Not done',
      feedback: 'Feedback',
      redo: 'Redo',
      homework: 'Homework',
      noQuestions: 'No questions in this activity',
      noQuestionsSubtitle:
        'This activity has no questions, either add questions to it or remove it',
      givenByTeacher: 'given by teacher',
      activityNext: 'NEXT',
      activityPrev: 'PREVIOUS',
      notFoundTitle: 'Activity not found',
      notFoundSubtitle: 'Fail quering activity',
      result: {
        finished: 'Finished',
        resultText: 'Result',
        congratulationsText:
          'Congratulations, you have completed the activity! 🎉',
        questions: 'Questions',
        correct: 'Correct',
        percentage: 'Percentage',
        review: 'Review',
        detailReview: 'Review the questions and their answers',
        remake: 'Remake',
        detailRemake: 'Retry the activity',
        leave: 'Leave',
      },
      card: 'Activity',
      text: {
        instruction: 'Write your answer in the field below',
        correction: "Teacher's correction",
        grade: 'Grade',
      },
      task: {
        instruction: 'Upload the file in the field below',
        upload: 'Click here and select a file to upload',
      },
      quiz: {
        statement: 'Choose the correct option',
        correctAnswer: 'Correct answer',
      },
      trueOrFalse: {
        correctAnswer: 'Correct answer',
        question: 'True or false?',
      },
      dragAndDropOrdenation: {
        instruction: 'Arrange the items in the correct order',
        errorReordenation: 'Error when trying to reorder items',
        wrongAnswer: 'Correct order',
      },
      dragAndDropPositional: {
        instruction:
          'Arrange the items on the right to match the correct order on the left',
        errorReordenation: 'Error when trying to reorder items',
      },
      video: {},
    },
  },
  modalBank: {
    manage: 'Manage',
    disabledButton: 'Unavailable',
    inUseLesson: 'lesson present in the course',
    inUseActivity: 'in the course',
    private: 'Private',
    public: 'Public',
    expansibleContent: {
      lesson: {
        description: 'Description',
        links: 'Links',
      },
      activity: {
        name: 'Name',
        viewActivities: 'View questions',
      },
    },
    openModal: {
      activities: 'Activity Bank',
      questions: 'Question Bank',
      lessons: 'Lesson Bank',
      all: 'Content Bank',
    },
    tooltip: {
      insideBank: 'Inside the bank',
      outsideBank: 'Outside the bank',
    },
    list: {
      empty: {
        lessons: 'No lesson found',
        activities: 'No activity found',
        questions: 'No question found',
        all: 'No item found',
      },
    },
    selectTypeButton: {
      activities: 'Activities',
      questions: 'Questions',
      lessons: 'Lessons',
      all: 'All',
    },
    section: {
      types: 'Types',
      filters: 'Filters',
    },
    filters: {
      author: 'Author',
      unit: 'Unit',
      tool: 'Tool',
      my: 'my authorship',
      course: 'my course',
      search: 'Search',
    },
    authorFilter: {
      pedagogical: 'Pedagogical',
      teacher: 'Teacher',
      my: 'My',
      all: 'All',
    },
    useButton: {
      activities: 'Use Activity',
      questions: 'Use Question',
      lessons: 'Use Lesson',
      all: 'Use Content',
      addingActivity: 'Adding activity...',
    },
  },
  accountPage: {
    accountTitle: 'My Configurations',
    accountSubheading: 'You can change your account information here',
    accountInfoTitle: 'Account Information',
    accountPasswordTitle: 'Account Password',
    personalInfoTitle: 'Personal Information',
    accountSystemTitle: 'System Configuration',
    theme: 'Theme',
    alterTimezone: 'Timezone',
    personalInfo: 'Personal',
    accountInfo: 'Account',
    systemInfo: 'System',
    fieldName: 'Name',
    fieldLastname: 'Lastname',
    fieldEmail: 'E-mail',
    fieldUsername: 'Username',
    fieldPhoneNumber: 'Phone Number',
    fieldDateOfBirth: 'Date of Birth',
    fieldAddress: 'City, State',
    fieldState: 'State',
    fieldCity: 'City',
    selectCity: 'Type the city',
    currentPassword: 'Current password',
    saveErrorDifferentPassword: 'The two passwords do not match!',
    fieldNewPassword: 'New password',
    fieldConfirmPassword: 'Confirm new password',
    saveButton: 'Save',
    editButton: 'Edit',
    cancelButton: 'Cancel',
    saveSuccess: 'Saved successfully',
    saveError:
      'Something went wrong, make sure the current password is correct',
    visualSettings: 'Visual Settings',
    changeConsent: 'Change consent information',
  },
  klass: {
    selectKlass: 'Select a class',
    lessons: {
      inBank: 'Lesson Bank',
      readOnly: 'Not editable',
      success: 'Changes saved successfully',
      cantOrder: 'Unable to order this lesson',
      errorTextTitle: 'There was an error loading lessons',
      errorTextSubTitle: 'Please contact support',
      finish: 'Finish',
      cancel: 'Cancel',
      view: 'View',
      title: 'Edit Class Course',
      details: 'Information',
      activity: 'Activity',
      teacherBook: 'Teacher Material',
      studentBook: 'Student Material',
      challenge: 'Challenge',
      modal: {
        cancel: {
          success: 'Changes discarded successfully',
        },
      },
      lessonDetail: {
        errorTool: 'Invalid url',
        deleteSuccess: 'Lesson deleted successfully',
        ImageUploadSuccess: 'The image was successfully uploaded:',
        ImageUploadError_1: 'Warning: The image upload',
        ImageUploadError_2: 'failed! Try again!',
        normal: 'Normal',
        free: 'Free',
        lessonTitle: 'Lesson Title',
        toolUrl: 'Add a program access link',
        instructionsUrl: 'Add a video link on how to use the program',
        addLink: 'Add Link',
        save: 'Save',
        exclude: 'Delete Lesson',
        freeExplain:
          'When the content of the class is outside the curriculum, increasing the total number of classes in the course. Recommended for events or holidays.',
        normalExplain:
          'When you need to replace a tool or spend more time finishing a project. You can disable another class to keep the total of 18 classes.',
      },
      alert: {
        saved: 'Saved',
      },
    },
  },
  studentHistoric: {
    title: 'Student History',
    enrollTransfer: 'Enrollment transferred on class',
    enrollCanceled: 'Enrollment canceled on class',
    enrolled: 'Enrolled on class',
    noData: 'No data about this student',
    fullScreen: 'full screen',
  },
  adminPage: {
    noTeachers: 'No teacher found in this unit',
    noAdminScreen: "The admin screen isn't available for mobile devices",
    useComputer: 'Please use a computer',
    editCourseRules: {
      cantModify: 'Not allowed in limited editing',
      activityWarning:
        'You are in limited edition courses so changes such as: editing answers or create/deleting questions are not allowed',
    },
    createCourse: 'Create Course',
    addStudent: 'Add Student',
    createTeacher: 'Create Teacher',
    createClass: 'Create Class',
    students: 'Students',
    teachers: 'Teachers',
    topics: 'Topics',
    classes: 'Classes',
    trial: 'Trial Lessons',
    courses: 'Courses',
    paths: 'Paths',
    units: 'Units',

    calendars: 'Calendars',
    recesses: 'Recesses',
    team: 'Team',
    lesson: {
      modal: {
        beta: {
          title: 'Warning!',
          warning:
            'The course will be available to teachers see e and make sugestions.',
          question: 'Are you sure you want finish editing?',
          cancel: 'Cancel',
          confirm: 'Finish',
          success: 'The course has been successfully added to beta',
        },
        cancel: {
          title: 'Are you sure you want to discard this edit?',
          warning:
            'Cancel all changes is an action cannot be reverted. All saved and unsaved changes will be lost',
          question: 'Are you sure you want to cancel all changes?',
          cancel: 'Back',
          confirm: 'Discard',
          success: 'Changes canceled successfully',
        },
        publishEdit: {
          title: 'Warning!',
          warning:
            'Changes will be published to all students immediately. Review well the changes before published them',
          question: 'Do want to publish changes?',
          cancel: 'Cancelar',
          confirm: 'Publish',
          success: 'Course published successfully',
        },
        publishBeta: {
          title: 'Warning',
          warning:
            'The course will be available to new classes. Review and be sure all is right before publish course',
          question: 'Are you sure you want to publish the course?',
          cancel: 'Cancel',
          confirm: 'Publish',
          success: 'Changes published successfully',
        },
        enableEdit: {
          title: 'Warning!',
          warning:
            'When enable edit, the course will not be available to teachers view and will turn be a draft',
          question: 'Are you sure you want to enable edit?',
          cancel: 'Cancel',
          confirm: 'Enable',
        },
      },
      successPublish: 'Course updated successfully',
      editingTitle: 'Course Editing',
      draftTitle: 'Course in Draft',
      versioningTitle: 'New Course Version',
      see: 'View',
      finish: 'Finish',
      publish: 'Publish',
      discard: 'Discard',
      editingWarning:
        'You are in limited edition courses only for minor corrections or changes. Published changes will be displayed to students who are already enrolled in courses. To make more changes, select the "New Version" option.',
      versioningWarning:
        'The new course version will not available to students who are already enrolled in the course. Only new students will see the changes',
      newLesson: 'New Lesson',
      successDeleteMessage: 'Class successfully deleted',
      errorDeleteMessage: 'There was an error deleting the class',
      successOrderMessage: 'Lessons reordered successfully',
      errorOrderMessage: 'There was an error reordering the lessons',
      successCreateMessage: 'Class created successfully',
      errorCreateMessage: 'There was an error creating the class',
      modalDeleteConfirm: 'Are you sure you want to delete this class?',
      modalWarning: 'This action cannot be undone',
      errorNotValidExtension: 'The file must be of type .zip',
      lessonTabs: {
        details: 'Info',
        material: 'Student Material',
        teacherMaterial: 'Teacher Material',
        activity: 'Activity',
        challenge: 'Challenge',
      },
      lessonDetails: {
        title: 'Lesson Title:',
        description: 'Lesson Description:',
        videoInstallMessage:
          'If this class has a video on how to install/access the program for the class, enter the video:',
        videoId: 'Video Url:',
        bannerUpload: 'Select an image for the lesson',
        deleteLesson: 'Delete Lesson',
        saveLesson: 'Save',
        programLinkMessage:
          'If the program has an access address, enter it below:',
        programUrl: 'Program access address:',
        programLinkExample: 'Example: https://www.google.com.br/',
        motivation: 'Motivation message:',
        success: 'Lesson saved successfully',
        error: 'An error occurred while trying to save the lesson',
      },
      addMaterial: {
        materialTitle: 'Material Title',
        teacherMaterialTitle: 'Teacher Material Title',
        addMaterial: 'Add Material',
        selectMaterial: 'Select course material',
        waitComplete: 'The chapters are being formatted. Please wait.',
        errorMessageUploadZip: 'Attention: material upload failed! Try again!',
        successDeleteMaterial: 'Material successfully deleted',
        errorDeleteMaterial:
          'There was an error when trying to delete the Book',
        successUploadZip: 'The material was successfully uploaded !',
        errorSaveZip: 'An error occurred while saving the material',
        addButton: 'Upload',
        modalConfirm: 'Are you sure you want to delete this book?',
        modalWarning: 'This action cannot be undone',
        back: 'Back',
      },
      alert: {
        saved: 'Saved',
      },
    },
    manageUnit: {
      units: 'Units',
      title: 'Unit Management',
      addUnit: 'ADD UNIT',
      totalUnit: 'TOTAL UNITS',
      timezone: 'Timezone',
      successAddUnitMessage: 'Unit added successfully',
      errorAddUnitMessage: 'There was an error adding the drive',
      successEditUnitMessage: 'Unit edited successfully',
      errorEditUnitMessage: 'An error occurred while editing the unit',
      name: 'Name',
      nameErrorMessage: 'Enter the unit name',
      address: 'Address',
      neighbourhood: 'Neighbourhood',
      addressErrorMessage: 'Enter an address',
      neighbourhoodErrorMessage: 'Enter a neighbourhood',
      addressNumber: 'Address number',
      addressNumberErrorMessage: 'Enter the unit address number',
      addressComplement: 'Complement',
      addressReference: 'Reference',
      city: 'City',
      cityErrorMessage: 'Enter the city of the unit',
      state: 'State',
      stateErrorMessage: 'Select unit state',
      zipcode: 'Zipcode',
      phone: 'Cell',
      cnpj: 'CNPJ',
      corporateName: 'Corporate Name',
      emailErrorMessage: 'Enter unit email',
      add: 'Add',
      save: 'Save',
      cancel: 'Cancel',
      status: 'Status',
      typeStatus: {
        active: 'Active',
        inactive: 'Inactive',
      },
    },
    listUnits: {
      errorTextSubTitle: 'Contact suport',
      errorTextTitle: 'Error listing units',
      recess: 'Recess',
      infoTooltip: 'General information',
      search: 'Search:',
      clearFilters: 'Clear Filters',
      clearOrder: 'Clear Orders',
      clearAll: 'Clear Orders and Filters',
      searchNotFound: 'Search not found',
      name: 'Unit Name',
      city: 'City',
      state: 'State',
      actions: 'Actions',
      editTooltip: 'Edit',
      deleteTooltip: 'Delete Unit',
      lastUpdate: 'Last update',
      modalConfirmMessage: 'Are you sure you want to delete this unit?',
      modalWarningMessage: 'This action cannot be undone.',
      successDeleteUnitMessage: 'Unit successfully deleted',
      errorDeleteUnitMessage: 'An error occurred while deleting the drive',
      teachers: 'Teachers',
      students: 'Students',
      classes: 'Classes',
      churn: 'Churn',
      presence: 'Presence',
      status: 'Status',
      units: 'Units',
    },
  },

  teacherCalendar: {
    lesson: 'Lesson',
    nextLesson: 'Next lesson:',
    teacherNoKlass: 'This teacher has no classes yet',
    endDate: 'End date:',
    students: 'Students:',
    noMoreLessons:
      'This class has no more lessons, update the status to inactive',
  },

  teacherPage: {
    title: 'Teacher',
    classes: 'Classes',
    scheduledKlasses: 'Lessons',
    students: 'Students',
    reports: 'Reports',
  },

  teacherComments: {
    successAdd: 'Comment added successfully',
    errorAdd: 'There was an error adding the comment',
    errorUpvote: 'There was an error upvoting the comment',
    successDelete: 'Comment deleted successfully',
    errorDelete: 'There was an error deleting the comment',
    at: 'at',
    comment: 'Comment',
    comments: 'Comments',
    reply: 'Reply',
    submit: 'Submit',
    response: 'Response',
    responses: 'Responses',
  },

  manageTrialLesson: {
    trialLessons: 'Trial Lessons',
    title: 'Manage Trial Lessons',
    add: 'Add Lesson',
    totalSeats: 'Total Seats',
    seatsPerTeacher: 'Seats per Teacher',
    deleteTooltip:
      'This lesson already has students, change the enrollment first',
    filledSeats: 'Filled Seats',
    noSchedule: 'This unit has no available lessons',
    deleteSuccess: 'Date deleted successfully',
    deleteError: 'There was an error deleting this date',
    attendance: 'Attendance',
    addLesson: {
      canotChangeTeacher: 'You have already added classes for this teacher',
      teacher: 'Teacher',
      dates: 'Dates',
      seats: 'Seats',
      units: 'Unit',
      notTrialLesson: 'There are no trial classes scheduled',
      successMessage: 'Trial lessons created successfully',
    },
  },

  manageCourse: {
    courses: 'Courses - Admin',
    title: 'Courses Management',
    teachersQuantity: 'TOTAL TEACHERS',
    studentsPerTeacher: 'STUDENTS PER TEACHER',
    weeklyAccesses: 'WEEKLY ACCESSES',
    add: 'ADD COURSE',
    coursesQuantity: 'TOTAL COURSES',
    klassesTotal: 'TOTAL CLASSES',
    studentsTotal: 'TOTAL STUDENTS',
    addCourse: {
      name: 'Course name',
      slug: 'Course slug',
      abbreviation: 'Course abbreviation',
      type: 'Course type',
      category: 'Category',
      f2f: 'In person',
      online: 'Online',
      isActive: 'Course is Active?',
      duration: 'Course Duration',
      dragger: 'Select a image for the course',
      addButton: 'Add',
      saveButton: 'Save',
      addSuccess: 'Course created successfully',
      addError: 'There was an error creating the course',
      editSuccess: 'Changes saved successfully',
      editError: 'An error occurred while trying to save the changes',
      ImageUploadSuccess: 'The image was successfully uploaded:',
      ImageUploadError_1: 'Warning: The image upload',
      ImageUploadError_2: 'upload failed! Try again!',
      ImageTypeError: 'Banner image must be one of this file types:',
      slugPopover:
        'The short name is needed to create the course URL(link), Example: Ctrl Kids 2 = ctrl-kids-2',
      errorMessageName: 'Insert the course name!',
      errorMessageSlug: 'Insert the course slug',
      errorMessageAbbreviation: 'Insert the course abbreviation!',
      errorMessageType: 'Select the course type!',
      errorMessageDuration: 'Insert the course duration!',
      cancelButton: 'Cancel',
      Quick: 'QUICK',
      typeSelect: 'Select course type',
    },
    editCourse: {
      category: 'Category',
      abbreviation: 'Course abbreviation',
      duration: 'Duration',
      isActive: 'Status',
      name: 'Course name',
      type: 'Course type',
      errorMessageAbbreviation: 'Insert the course abbreviation!',
      errorMessageName: 'Insert the course name!',
      saveButton: 'Save',
      errorMessageDuration: 'Insert the course duration!',
      cancelButton: 'Cancel',
      dragger: 'Select a image for the course',
      published: 'Published',
      disabled: 'Disabled',
      editing: 'Editing',
      versioning: 'Versioning',
      editError: 'There was an error editing the course',
      editSuccess: 'Course edited successfully',
      ImageUploadSuccess: 'The image was successfully uploaded:',
      ImageUploadError_1: 'Warning: The image upload',
      ImageUploadError_2: 'upload failed! Try again!',
      active: 'Active',
      inactive: 'Inactive',
      Quick: 'Quick',
      editButton: 'Edit',
      info: 'Info',
      saveCreated: 'Course created successfully',
      saveEdited: 'Course edited successfully',
      wanings: {
        emptyField: 'Fill in this field',
        noChanges: 'No changes identified',
      },
      errors: {
        name: {
          maxLength: 'The name must not exceed 200 characters',
        },
        abbreviation: {
          maxLength: 'The abbreviation must not exceed 7 characters',
          exists: 'This abbreviation already exists',
        },
      },
    },
    dataCourseTable: {
      editTooltip: 'Edit Course',
      deleteTooltip: 'Delete Course',
      modalConfirm: 'Are you sure you want to delete this course?',
      modalWarning:
        'All progress of this course will be excluded and this action cannot be undone',
      deleteSuccess: 'Course successfully deleted',
      deleteFail: 'There was an error when trying to delete the course',
      branchTooltip: 'New Version',
      infoTooltip: 'Info',
      viewTooltip: 'View Course',
      statisticNotFound: 'N/A',
      Quick: 'QUICK',
      searchNotFound: 'Search not found',
      noCourses: 'There are no registered courses',
      noCoursePath:
        "It's not possible to edit this course. Please contact support",
      school: 'School',
    },
    listCourses: {
      search: 'Search:',
      clearFilters: 'Clear Filters',
      clearOrder: 'Clear Order',
      clearAll: 'Clear Order and Filters',
      name: 'Course',
      type: 'Type',
      students: 'Students',
      status: 'Status',
      actions: 'Actions',
      category: 'Category',
      churn: 'Churn',
      presence: 'Presence',
      version: 'Version',
    },
  },
  manageStudent: {
    students: 'Students',
    title: 'Student Management',
    accesses: 'ACCESSES',
    weeklyAccesses: 'WEEKLY ACCESSES',
    quantity: 'STUDENTS',
    add: 'ADD STUDENT',
    back: 'back',
    listUsers: {
      search: 'Search:',
      clearFilters: 'Clear Filters',
      clearOrder: 'Clear Order',
      clearAll: 'Clear Order and Filters',
      name: 'Name',
      email: 'E-mail',
      unit: 'Unit',
      klass: 'Class',
      phoneNumber: 'Phone Number',
      role: 'Role',
      lastAccess: 'Last Access',
      status: 'Status',
      actions: 'Actions',
    },
    dataUserTable: {
      passwordTooltip: 'Change Password',
      infoTooltip: 'Info',
      never: 'Never',
      searchNotFound: 'Search not found',
      noStudents: 'There are no registered students',
      rewardsTooltip: 'Rewards',
      pedagogicalSheetTooltip: 'Pedagogical Sheet',
      historicTooltip: 'Student Historic',
    },
    status: userStatus,
    addUser: {
      info: 'Info',
      edit: 'Edit',
      unit: 'Unit',
      name: 'Name',
      lastName: 'Last name',
      selectCity: 'Type a city',
      email: 'Email',
      username: 'Username',
      password: 'Password',
      usernameRule: 'Only letters, dot, hyphen e underline',
      passwordConfirm: 'Password confirm',
      errorMessagePasswordMatch:
        'The two passwords that you entered do not match!',
      birth: 'Birth',
      state: 'State',
      city: 'City',
      bio: 'Bio',
      klass: 'Class',
      phoneNumber: 'Phone Number',
      role: 'Role',
      status: 'Active',
      Registred: 'Registred',
      Active_Enrollment: 'Active Enrollment',
      Inactive: 'Inactive',
      Waiting_Enrollment: 'Waiting Enrollment',
      errorMessageUnit: 'Select the Unit',
      errorMessageName: 'Enter student name',
      errorMessageLastName: "Enter student's last name",
      errorMessageEmail: 'Enter student email',
      errorMessageUsername: 'Enter student user',
      errorMessagePassword: 'Enter student password',
      errorMessagePasswordConfirm: 'Confirm student password',
      errorMessageBirth: "Enter student's date of birth",
      errorMessageState: 'Enter student state',
      errorMessageCity: 'Enter student city',
      errorMessageKlass: 'Select a class for the student',
      addButton: 'Add Student',
      successCreateUser: 'Student created successfully',
      errorCreateUser: 'There was an error creating the student',
      successEditUser: 'Changes saved successfully',
      errorEditUser: 'An error occurred while trying to save the changes',
      saveButton: 'Save',
      cancelButton: 'Cancel',
      addUserRuleOne:
        'just letters, numbers and . - _ are accepted for username or email',
      addUserRuleTwo: 'fill all fields',
      addUserRuleThree: 'make sure the password fields match',
    },
  },

  feedbackPage: {
    pageTitle: 'Student Feedback',
    all: 'all',
    byCourse: 'by course',
    byModule: 'by module',
    byKlass: 'by class',
    header: {
      average: 'Average',
      lessonRating: 'Lesson content',
      teacherRating: `Teacher's explanation`,
      notFound: 'No feedback found',
      closeFeedback: 'Close feedback',
      loadFeedback: 'Load feedback',
      oldest: 'oldest',
      all: 'All',
      newest: 'newest',
      positive: 'Positive 😍',
      neutral: 'Neutral 😐',
      negative: 'Negative 😖',
    },
    filter: {
      course: 'Course',
      module: 'Module',
      selectCourse: 'Select a course first',
      units: 'Units',
      rating: 'Rating',
    },
    tag: { feedbackSingular: 'feedback', feedbackPlural: 'feedbacks' },
  },

  manageTeam: {
    team: 'Team',
    title: 'Team Management',
    accesses: 'ACCESSES',
    teacherWarning:
      'Attention! It is not possible to create teachers through the Portal, only on Loja',
    weeklyAccesses: 'WEEKLY ACCESSES',
    quantity: 'TEAM',
    add: 'ADD STUDENT',
    back: 'back',
    Super_Admin: 'Super Admin',
    Unit_Admin: 'Admin',
    Teacher: 'Teacher',
    status: userStatus,
    newUser: 'New user',
    fromUnit: 'From another unit',
    selectUser: 'Select user',
    selectUnit: 'Select unit',
    unitLabel:
      'The user will retain any previous units and add the new one selected below:',
    errorUserAlreadyHasUnit: 'User already in this unit',
    listUsers: {
      search: 'Search:',
      clearFilters: 'Clear Filters',
      clearOrder: 'Clear Order',
      clearAll: 'Clear Order and Filters',
      name: 'Name',
      email: 'E-mail',
      unit: 'Unit',
      klass: 'Klass',
      phoneNumber: 'Phone Number',
      role: 'Role',
      lastAccess: 'Last Access',
      status: 'Status',
      actions: 'Actions',
    },
    dataUserTable: {
      searchNotFound: 'Search not found',
      passwordTooltip: 'Change Password',
      infoTooltip: 'Info',
      never: 'Never',
    },
    addUser: {
      info: 'Info',
      edit: 'Edit',
      unit: 'Unit',
      name: 'Name',
      lastName: 'Last name',
      selectCity: 'Type a city',
      selectRole: 'Select a role',
      email: 'Email',
      username: 'Username',
      password: 'Password',
      usernameRule: 'Only letters, dot, hyphen e underline',
      passwordConfirm: 'Password confirm',
      errorMessagePasswordMatch: 'The two passwords do not match!',
      birth: 'Birth',
      state: 'State',
      city: 'City',
      bio: 'Bio',
      klass: 'Klass',
      phoneNumber: 'Phone Number',
      role: 'Role',
      status: 'Active',
      Registred: 'Registred',
      Active_Enrollment: 'Active Enrollment',
      Inactive: 'Inactive',
      Waiting_Enrollment: 'Waiting Enrollment',
      errorMessageUnit: 'Select the Unit',
      errorMessageName: 'Enter team member name',
      errorMessageLastName: "Enter team member's last name",
      errorMessageEmail: 'Enter team member email',
      errorMessageUsername: 'Enter team member user',
      errorMessagePassword: 'Enter team member password',
      errorMessagePasswordConfirm: 'Confirm team member password',
      errorMessageBirth: "Enter team member's date of birth",
      errorMessageState: 'Enter team member state',
      errorMessageCity: 'Enter team member city',
      errorMessageKlass: 'Select a class for the team member',
      addButton: 'Add team member',
      successCreateUser: 'Teammate created successfully',
      errorCreateUser: 'There was an error creating the team member',
      successEditUser: 'Changes saved successfully',
      errorEditUser: 'An error occurred while trying to save the changes',
      saveButton: 'Save',
      cancelButton: 'Cancel',
      addUserRuleOne:
        'just letters, numbers and . - _ are accepted for username',
      addUserRuleTwo: 'fill all fields',
      addUserRuleThree: 'make sure the password fields match',
    },
  },
  game: {
    edit: {
      title: 'Edit map',
      resize: { rows: 'Rows', cols: 'Columns' },
      allEnemiesShouldMove: 'All enemies should move',
    },
    layers: {
      ground: 'Ground layer',
      interactive: 'Interactive layer',
    },
    selectBlocks: {
      interactive: {
        title: 'Items',
        description: 'Add or remove items that the student must interact with',
      },
      ground: {
        title: 'Block',
        description: 'Add or remove blocks that the student must pass through',
      },
    },
    validators: {
      [GameErrorCodes.DUPLICATE_SPRITE]:
        'You cannot add two items with the same sprite',
      [GameErrorCodes.EXISTS_INTERACTIVE_ITEM_IN_COORD]:
        'There is already an interactive item in this coordinate',
      [GameErrorCodes.INVALID_COLLECTIBLE_POSITION]:
        'The collectible item must be in a grass block',
      [GameErrorCodes.INVALID_ENEMY_POSITION]:
        'The enemy cannot be in a limit block',
      [GameErrorCodes.INVALID_MIN_MATRIX_DIMENSIONS]:
        'The matrix must be at least 3x3',
      [GameErrorCodes.INVALID_MAX_MATRIX_DIMENSIONS]:
        'The matrix must be at most 13x13',
      [GameErrorCodes.INVALID_GROUND_POSITION]:
        'Invalid position for ground block',
      [GameErrorCodes.GROUND_SHOULD_CONTAIN_ALL_INTERACTIVE_ITEMS]:
        'The ground must contain all interactive items',
      [GameErrorCodes.PLAYER_SIDE_ENEMY]:
        'An enemy that moves cannot be next to the player',
      [GameErrorCodes.INVALID_INITIAL_POSITION_PLAYER]:
        'Invalid initial position for the character',
      [GameErrorCodes.REGULAR_MATRIX]:
        'The matrix must be regular, all lines must have the same size',
      [GameErrorCodes.WORKSPACE_UNDEFINED]: 'The workspace is not defined',
    },
    tokens: {
      names: {
        [GroundToken.FINAL]: 'Podium',
        [GroundToken.REGULAR]: 'Grass',
        [GroundToken.DANGER]: 'Lava',
        [GroundToken.LIMIT]: 'Limits',
        [InteractiveToken.PLAYER]: 'Character',
        [InteractiveToken.COLLECTIBLE]: 'Collectible',
        [InteractiveToken.ENEMY]: 'Enemy',
        [InteractiveToken.DIRECTION]: 'Player direction indicator',
      },
      describes: {
        [GroundToken.DANGER]: 'Forbidden path (the student cannot pass here)',
        [GroundToken.REGULAR]: 'Free path (the student can pass here)',
        [GroundToken.FINAL]: 'Arrival point (the student must arrive here)',
        [GroundToken.LIMIT]: 'Ground block that indicates the map limit',
        [InteractiveToken.PLAYER]: 'set where the character should start',
        [InteractiveToken.COLLECTIBLE]:
          'Add or remove an item that the student must collect on the map',
        [InteractiveToken.ENEMY]:
          'Add or remove an enemy that the student must avoid',
        [InteractiveToken.DIRECTION]: 'Player direction indicator',
      },
    },
  },
  manageTeacher: {
    teachers: 'Teachers',
    title: 'Student Management',
    accesses: 'ACCESSES',
    weeklyAccesses: 'WEEKLY ACCESSES',
    quantity: 'TEACHERS',
    add: 'ADD TEACHER',
    back: 'back',
    status: userStatus,
    listUsers: {
      search: 'Search:',
      clearFilters: 'Clear Filters',
      clearOrder: 'Clear Order',
      clearAll: 'Clear Order and Filters',
      name: 'Name',
      email: 'E-mail',
      unit: 'Unit',
      klass: 'Class',
      phoneNumber: 'Phone Number',
      role: 'Role',
      lastAccess: 'Last Access',
      status: 'Status',
      actions: 'Actions',
      students: 'Students',
      presence: 'Presence',
      churn: 'Churn',
    },
    dataUserTable: {
      noTeachers: 'There are no registered teachers',
      searchNotFound: 'Search not found',
      passwordTooltip: 'Change Password',
      editTooltip: 'Edit Teacher',
      deleteTooltip: 'Delete Teacher',
      never: 'Never',
      IntoTooltip: 'General information',
      lessonsTooltip: 'Classes',
      noProfileFound: 'Teacher profile not found',
    },
    addUser: {
      info: 'Info',
      edit: 'Edit',
      unit: 'Unit',
      name: 'Name',
      lessons: 'Lessons',
      filters: {
        allLessons: 'all lessons',
        completedLessons: 'completed lessons',
        plannedLessons: 'planned lessons',
      },
      noContent: {
        allLessons: 'No class the teacher',
        completedLessons: 'No class completed by teacher',
        plannedLessons: 'No classes scheduled for this teacher',
      },
      lastName: 'Last name',
      email: 'Email',
      username: 'Username',
      usernameRule: 'Only letters, dot, hyphen e underline',
      password: 'Password',
      passwordConfirm: 'Password confirm',
      errorMessagePasswordMatch:
        'The two passwords that you entered do not match!',
      birth: 'Birth',
      state: 'State',
      city: 'City',
      selectCity: 'Type a city',
      bio: 'Bio',
      klass: 'Class',
      phoneNumber: 'Phone Number',
      role: 'Role',
      status: 'Status',
      Registred: 'Active',
      Active_Enrollment: 'Active Enrollment',
      Inactive: 'Inactive',
      Waiting_Enrollment: 'Waiting Enrollment',
      errorMessageUnit: 'Select the Unit',
      errorMessageName: 'Enter teacher name',
      errorMessageLastName: "Enter teacher's last name",
      errorMessageEmail: 'Enter teacher email',
      errorMessageUsername: 'Enter teacher user',
      errorMessagePassword: 'Enter teacher password',
      errorMessagePasswordConfirm: 'Confirm teacher password',
      errorMessageBirth: "Enter teacher's date of birth",
      errorMessageState: 'Enter teacher state',
      errorMessageCity: 'Enter teacher city',
      errorMessageKlass: 'Select a class for the teacher',
      addButton: 'Add teacher',
      successCreateUser: 'Teacher created successfully',
      errorCreateUser: 'There was an error creating the student',
      successEditUser: 'Changes saved successfully',
      errorEditUser: 'An error occurred while trying to save the changes',
      saveButton: 'Save',
      cancelButton: 'Cancel',
      addUserRuleOne:
        'just letters, numbers and . - _ are accepted for username or email',
      addUserRuleTwo: 'fill all fields',
      addUserRuleThree: 'make sure the password fields match',
      typeStatus: {
        active: 'Active',
        inactive: 'Inactive',
      },
    },
  },
  manageKlasses: {
    status: klassStatus,
    klasses: 'Classes',
    title: 'Class Management',
    klassesPerTeacher: 'STUDENT PER CLASS',
    klassesQuantity: 'TOTAL CLASSES',
    studentsPerTeacher: 'STUDENTS PER TEACHER',
    weeklyAccesses: 'WEEKLY ACCESSES',
    add: 'ADD KLASS',
    addKlass: {
      warning: 'Changes to the class can only be made on Loja',
      selectDayTimes: 'First select the day of the week and class time',
      info: 'Info',
      edit: 'Edit',
      course: 'Course',
      f2f: 'In person',
      online: 'Online',
      startDate: 'Start',
      endDate: 'End',
      klassStatus,
      duration: 'Duration',
      status: 'Status',
      klassDateAndTime: 'Day and Time',
      recurrence: 'Recurrence',
      recessCalendar: 'Calendar',
      teacher: 'Teacher',
      students: 'Students',
      add: 'Add',
      save: 'Save',
      cancel: 'Cancel',
      addStudentButton: 'Add Students',
      addSuccess: 'CLass created successfully',
      addError: 'There was an error creating the class',
      saveSuccess: 'Changes saved successfully',
      saveError: 'An error occurred while trying to save the changes',
      moveTooltip: 'Move Student',
      deleteTooltip: 'Remove Student',
      unit: 'Unit',
      meetLink: 'Meet Link',
      dayOfWeek: 'Day of the week',
      time: 'Time',
      day: 'Day',
      error: {
        unit: 'Select the unit',
        course: 'Enter the course',
        klassDate: 'Enter the course start date',
        klassTime: 'Enter the class schedule',
        recurrence: 'Select class recurrence',
        recessCalendar: 'Select the Recess Calendar',
        teacher: 'Enter the class teacher',
        dayWeek: 'Enter the weekday',
        students: 'Enter at least one student',
        duration: 'Enter the class duration',
        invalidTime: 'Enter a valid time',
        klassType: 'Enter the class type',
        klassStatus: 'Enter the class status',
      },
      select: {
        course: 'Select the course',
        teacher: 'Select the teacher',
        duration: 'Select the class duration',
        day: 'Select the class weekday',
      },

      maxCapacity: 'Max capacity',
      conectaSeats: 'Conecta Seats',
      klassType: 'Class type',
      coursePathId: 'Course',
    },
    dataKlassTable: {
      noKlasses: 'There are no classes registered',
      searchNotFound: 'Search not found',
      infoTooltip: 'Info',
      editTooltip: 'Edit Class',
      processingEnrollment: 'Processing Enrollment',
      deleteTooltip: 'Delete Class',
      noDate: 'No date',
      teacherStudentsTooltip: 'Teacher and students',
      classProgressTooltip: 'Class progress',
      klassSheetTooltip: 'Class sheet',
      teacherStudentsPill: 'Teacher and students',
      lessonsTooltip: 'Scheduled lessons',
      modalConfirm: 'Are you sure you want to delete this class?',
      modalWarning:
        'All progress of this class will be excluded and this action cannot be undone',
      deleteSuccess: 'Class successfully deleted',
      deleteFail: 'There was an error when trying to delete the class',
      teacher: 'Teacher',
      students: 'Students',
      noStudentsToFollow: 'No students to follow',
      months: {
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December',
      },
      scheduledLessonProgressView: {
        klasses: 'Classes',
        everyKlass: 'every class',
        completedKlasses: 'completed classes',
        scheduledKlasses: 'scheduled classes',
        noScheduledLessons: 'No scheduled lessons for show',
        pendingKlasses: 'with pending reports',
        ascending: 'ascending',
        descending: 'descending',
        sort: 'Sort',
      },
    },
    listKlasses: {
      search: 'Search:',
      clearFilters: 'Clear Filters',
      clearOrder: 'Clear Order',
      clearAll: 'Clear Order and Filters',
      klass: 'Class',
      unit: 'Unit',
      start: 'Start',
      end: 'End',
      status: 'Status',
      actions: 'Actions',
      activeClasses: 'Active classes:',
    },
  },

  klassProgressPage: {
    lessonBlocked: 'This lesson is not released yet, it will be soon',
    pageTitle: 'Class Progress',
    notFoundTitle: "It looks like the class you're looking for doesn't exist",
    notFoundText: 'Please try another class',
    genericErrorTitle: "Oops, there's an issue",
    genericErrorText: 'Contact the support team or try again later',
    noStudents: 'This class has no student yet, please select another class',
    lessons: 'Lessons',
    lesson: 'Lesson',
    attendances: 'Attend.',
    noProgress: 'No progress for this lesson associated with this class',
    students: 'Students',
    absences: 'Absences',
    aboutKlass: 'About the class',
    progressAverage: 'Progress Average',
    averagePP: 'Average PP',
    pleaseFillReport: 'Please, fill up the lesson report',
    givenContent: 'Given Content',
    lessonScheduled: 'Lesson Scheduled',
    lessonIsScheduled: 'This lesson is scheduled for the next few days',
    reportNotFilled: 'Lesson report not filled in',
    seeReport: 'see report',
    missed: 'Missed',
    active: 'Active',
    inactive: 'Inactive',
    all: 'All',
    noInactiveStudent: 'No inactive student',
    noActiveStudent: 'No active student',
    transferred: 'Transferred',
    attended: 'Attended',
    pending: 'Pending',
    done: 'Done',
  },

  activity: {
    errorTextTitle: "Can't find activities",
    errorTextSubTitle: 'Verify if you are in correct page or contact support',
    newActivity: 'New Activity',
    textLabel: 'Text',
    videoLabel: 'Video',
    quizLabel: 'Quiz',
    trueFalseLabel: 'T/F',
    codeLabel: 'Code',
    dragAndDropOrdenation: 'Drag and Drop ordenation',
    dragAndDropPositional: 'Drag and Drop positional',
    deleteSuccess: 'Activity deleted successfully',
    deleteFail: 'Ocorreu um erro ao deletar a atividade',
    addSuccess: 'There was an error deleting the activity',
    addError: 'Error adding class',
    modalConfirm: 'Are you sure you want to delete this activity?',
    modalWarning: 'This action cannot be undone.',
    manageActivity: {
      editActivity: 'Edit activity',
      errorTextTitle: "Can't find activity",
      errorTextSubTitle: 'Verify if you are in correct page or contact support',
      newQuestion: 'New Question',
      save: 'Save',
      add: 'Add',
      detailsLabel: 'Info',
      notAllowed: 'Not allowed in limited edition',
      activityTypes: {
        TEXT: 'Text',
        VIDEO: 'Video',
        QUIZ: 'Quiz',
        TRUE_OR_FALSE: 'T/F',
        DND_ORDENATION: 'Drag',
        DND_POSITIONAL: 'Position',
        CODE: 'Code',
      },
      examLabel: 'Exam',
      pdfLabel: 'PDF',
      taskLabel: 'Task',
      validationError: 'Error validating activities',
      saveSuccess: 'Activity saved successfully',
      deleteSuccess: 'Activity deleted successfully',
      saveError: 'An error occurred while saving the activity',
      activityDetails: {
        name: 'Activity Name:',
        inBank: 'Add in the Activity Bank',
        isPublicInBank: 'Public',
        config: '',
        noQuestionsWarning:
          'No questions created, click + New Question to add a question',
        save: 'Save',
        delete: 'Delete Activity',
        deleteError: 'There was an error deleting the activity',
        deleteSuccess: 'Activity deleted successfully',
        saveError: 'An error occurred while saving the activity',
        saveSuccess: 'Activity saved successfully',
      },
      activityCode: {
        title: { label: 'Title', required: 'Title is required' },
        tip: {
          label: 'Tip',
          placeholder: 'Optional: Add a tip if the student gets lost',
        },
        instructions: {
          label: 'Instructions',
          placeholder: 'Insert the activity instructions',
          required: 'Instructions are required',
        },
        map: {
          run: 'RUN',
          reset: 'RESET',
          edit: 'Edit Map',
        },
        workspaceOptions: {
          title: 'Options',
          maxBlocks: 'Block Limit',
          maxBlocksAlert:
            'The block limit cannot be less than the number of blocks already added',
          coins: 'Coins',
        },
        save: 'Save',
        success: 'Activity saved successfully',
      },
      activityText: {
        title: 'Title:',
        titleError: 'Enter text title',
        subtitle: 'Subtitle',
        subtitleError: 'Enter text subtitle',
        content: 'Content:',
        contentError: 'Write something as the content of the question',
        save: 'Save',
        saveSuccess: 'Activity saved successfully',
        saveError: 'There was an error saving the activity',
      },
      activityVideo: {
        title: 'Title:',
        titleError: 'Enter video title',
        subtitle: 'Subtitle:',
        subtitleError: ' Enter video subtitle:',
        videoID: 'Video ID:',
        videoIDError: 'The video must have an ID',
        videoPlayer: 'Video Player:',
        videoPlayerError: 'Select one of the video players above',
        exampleMessage1: 'Example: Input ',
        exampleMessage2: ' from the URL ',
        save: 'Save',
        saveSuccess: 'Activity saved successfully',
        saveError: 'There was an error saving the activity',
      },
      activityQuiz: {
        answers: {
          answers: 'Answers:',
          noValue: 'Answer without content',
          cantRemove: 'The quiz must have at least 2 alternatives',
        },
        image: 'image',
        text: 'text',
        cantChangeType: 'Can not change quiz type. Create another quiz.',
        imageDescription: 'Image description: ',
        missingAlt: 'Add description of image',
        missingImages: 'Some alternatives are without image',
        title: 'Question:',
        content: 'Content:',
        titleError: 'Enter the activity question',
        answersError: 'At least two answers must be added',
        atLeastOneTrueAnswerError: 'One answer must be marked as correct',
        newAnswer: 'New Answer',
        justificative: 'Explanation of the correct answer:',
        justificativeError: 'The question needs a justification',
        save: 'Save',
        saveSuccess: 'Activity saved successfully',
        saveError: 'There was an error saving the activity',
      },
      activityTrueFalse: {
        title: 'Question:',
        content: 'Content:',
        titleError: 'Enter the activity question',
        trueOrFalse: 'The statement is true or false?',
        trueOrFalseError: 'An option must be selected as an answer',
        true: 'True',
        false: 'False',
        justificative: 'Explanation of the correct answer:',
        justificativeError: 'The question needs a justification',
        save: 'Save',
        saveSuccess: 'Activity saved successfully',
        saveError: 'There was an error saving the activity',
      },
      challenge: {
        title: 'Challenge',
        save: 'Save',
        edit: 'Edit Challenge',
        add: 'Add Challenge',
        deleteSuccess: 'Challenge deleted successfully',
        deleteFail:
          'There was a problem deleting the challenge, try again later or contact the support team',
        delete: 'Delete Challenge',
        cancel: 'Cancel',
        saveSuccess: 'Challenge successfully saved',
        saveError: 'There was an error saving the challenge',
        content: 'Content',
      },
      activityDNDOrdenation: {
        title: 'Question:',
        subtitle: 'Subtitle:',
        columns: 'Columns:',
        columnName: 'Column name:',
        text: 'Text',
        descriptionImage: 'Image description',
        newItem: 'New item',
        newColumn: 'Add new column',
        removeColumn: 'Remove column',
        save: 'Save',
        textTooltipRemoveColumn:
          'Can only remove if it has more than one column',
        textTooltipRemoveItem:
          'You can only remove if you have more than three items',
        nameColumnRequired: 'Column name is required',
        noAltText: 'Add a description to the image',
        noImage: 'Add an image',
        noContent: 'Add some content to the item',
        noTitle: 'Enter the activity question',
        saveSuccess: 'Activity saved successfully',
        saveOrderSuccess: 'New order saved',
        saveDeleteItemSuccess: 'Item deleted',
        saveAddItemSuccess: 'New item added',
        saveDeleteColumnSuccess: 'Deleted column',
        saveAddColumnSuccess: 'Added column',
        maxLength: 'The content must not exceed 150 characters',
        maxLengthAltText: 'Image description should not exceed 150 characters',
      },

      dndPositionalElementEdit: {
        saveSuccess: 'Activity saved successfully',
        saveOrderSuccess: 'New order saved',
        saveDeleteSuccess: 'Item deleted',
        saveAddItemSuccess: 'New item added',
        dndErrors: {
          onlyOneItem: 'Activity must contain more items',
        },
        dndActivityInfo: {
          title: 'Question:',
          subtitle: 'Subtitle:',
          dndErrors: {
            noTitle: 'Enter the activity question',
          },
        },
        dndColumnEdit: {
          columnName: 'Column name:',
          descriptionImage: 'Image description',
          newItem: 'New item',
          question: 'Question:',
          answer: 'Answer:',
          textTooltipRemoveItem:
            'You can only remove if you have more than one item',
          textTooltipReorderItem:
            'You can only reorder if you have more than one item',
          dndErrors: {
            nameColumnRequired: 'Column name is required',
            noTitle: 'Enter the activity question',
            noContent: 'Add some content to the item',
            noAltText: 'Add a description to the image',
            maxLength: 'The content must not exceed 150 characters',
            maxLengthAltText:
              'Image description should not exceed 150 characters',
          },
        },
        save: 'Save',
      },
    },
    activityBank: 'Activity bank',
  },

  changeLanguage: 'Change Language',
  configuration: 'Configuration',
  back: 'back',
  dashboard: {
    hello: 'Hello, ',
    wellcome: {
      teacher: 'Welcome back teacher! Ready to continue your journey? 🚀',
      admin: 'Welcome back admin!',
      unitAdmin: 'Welcome back unit admin!!',
      parent: "Welcome to Ctrl+Play, ready to transform your child's future?",
    },
    yourCourse: 'COURSE',
    accessClasses: 'Classes',
    totalClasses: 'CLASSES',
    totalActivities: 'ACTIVITIES',
    welcome:
      'Welcome å Ctrl + Play. Take a look at how our courses work and register!',
    dashboardClass: {
      yourClass: 'YOUR CLASS',
      teacher: 'Teacher',
      colleagues: 'Collegues',
      noTeacherText: 'Teacher not selected',
      teacherKlassMembers: `Select a class to see the avatars and access students' profiles`,
    },
    error: 'Could not load progress',
    support:
      'Looks like something went wrong. Please speak to the support team.',
  },
  coursesView: {
    myCourses: 'My Courses',
    myLessons: 'My Lessons',
    myCoursesInstructions:
      'Here you can access all lessons and activities available in your course',
    completedCourses: 'COMPLETED COURSES',
    nextSteps: 'Next Steps',
    continueText:
      "Continue to evolve and learn with Ctrl+Play's most advanced courses.",
    admin: {
      errorTextTitle: 'Course not found',
      errorTextSubTitle: 'Check if you are in the right page',
      unpublishedCourses: 'Unpublished Courses',
      betaDraftExplanation:
        'The ”Beta” courses below are available for some teachers to look at, review or make suggestions. "Draft" courses are still being edited and are not shown to teachers until the edits are complete.',
      courses: 'Courses',
      backEditing: 'Continue Editing',
      finish: 'Finish',
      publish: 'Publish Course',
      f2f: 'In person',
      online: 'Online',
      newCourse: 'New Course',
      viewCourse: 'View Course',
      newVersion: 'New Version',
      editCourse: 'Edit Course',
      enableEditing: 'Enable Editing',
      myLessons: 'My Lessons',
      addExtraLesson: {
        add: 'Add',
        cancel: 'Cancel',
        selectLesson: 'Select a lesson',
        selectLessonExplain:
          'The extra lesson will be added before the selected lesson. You cannot select lessons that have already passed.',
        title: 'Select a lesson',
        newLesson: 'Lesson Title',
      },
    },
    progress: 'PROGRESS',
  },
  profile: {
    title: 'My Profile',
    level: {
      levelName: 'Level {{level}}',
      levelProgress: '{{currentXp}}/{{targetXp}} EXP',
    },
    errors: {
      username: 'This username already exists',
      links: 'Invalid links',
    },
    sixLinks: 'Is allowed add until 6 links',
    addLinks: 'Add Link',
    saveProfile: 'Save Profile',
    editProfile: 'Edit Profile',
    saveSuccess: 'Changes saved successfully',
    saveError:
      'There was a problem, try again later or contact the support team',
    notFoundTitle: 'Ops, no user found',
    notFoundSubtitle: "It looks like the user you're looking for doesn't exist",
    cancel: 'Cancel',
    create: 'Create your Profile',
    checklist: {
      avatar: 'Add avatar',
      banner: 'Add banner',
      links: 'Add social links',
      bio: 'Add bio',
    },
    bioCard: {
      title: 'This is your profile',
      description:
        'Click on edit profile button to add your Bio, social links, banner, or change your profile picture.',
    },
    totalActivities: 'Total Activitiess',
    completedCourses: 'Completed Courses',
  },
  klassView: {
    controls: {
      extraLesson: 'Extra Lesson',
      lesson: 'Lesson',
    },
    feedback: {
      feedbackInformation: `The feedback will be use by the educational team to improve the courses, don't forget to also ask the students to leave their feedback on the lesson.`,
      comment: 'Comment (optional)',
      content: 'Content',
      save: 'Save',
      saveError:
        'Ops, there was an error. Try again later or contact the support team',
      saveSuccess: 'Feedback saved successfully!',
      alertWarning: 'Add a rating before saving',
      contentAndActivity: 'Lesson content and activities',
      teacherExplanation: `Teacher's explanation`,
    },
    lessonNotFound: 'Can not find this lesson',
    instruction: {
      errorTextTitle: 'Video not found',
      errorTextSubTitle: 'The tool video was not found',
      vizualized: 'Viewed',
      accessAndInstalation: 'Access and Installation',
    },
    iconType: {
      access: 'Tool Access',
      activity: 'Activities',
      attendance: 'Presence',
      teacherFeedback: 'Feedback',
      studentFeedback: 'What did you think of the lesson?',
      challenge: 'Challenge',
      studentBook: 'Book',
      teacherBook: `Teacher's book`,
    },
    homework: {
      success: 'Answer submitted',
      notDone: 'Not done',
      waitingFeedback: 'Awaiting feedback',
      pending: 'Pending',
      revised: 'Revised',
      submit: 'Submit',
      feedback: {
        feedback: 'Feedback',
        addComment: 'Add comment to student',
        editComment: 'Edit comment for student',
        cancel: 'Cancel',
        grade: 'Grade',
        save: 'Submit to student',
        submittion: 'Submittion',
        notDone: 'Not done',
        hasChangesTooltip: 'There are changes that need to be saved',
        saved: 'Correction saved successfully',
      },
      error: {
        internalError:
          'An error occurred while trying to load the lesson. Contact support',
      },
    },
    noHomework: "There's no homework in this lesson",
    challengeView: {
      title: 'Challenge',
      visualized: 'Viewed',
      errorTextSubTitle: 'Check if you are in the right page',
      errorTextTitle: 'Challenge not found',
    },
    initialView: 'Class progress',
    noContent: "This class doesn't have a content yet",
    material: 'Material',
    studentMaterial: 'Student Material',
    teacherMaterial: 'Teacher Material',
    activity: 'Activities',
    challenge: 'Challenge',
    noneMaterial: 'No material available at the moment',
    toolAccess: 'Tool access',
    goBack: 'Go back',
    errorMessage: {
      homeworkPhrase: 'Unable to check and bring homework',
      progressPhrase: 'Unable to load your progress',
    },
  },
  manageRecessCalendars: {
    manageRecessCalendars: 'Manage Recess Calendars',
    recessCalendar: 'Recess Calendar',
    recess: 'Recess',
    calendar: 'Calendar',
    edit: 'Edit',
    save: 'Save',
    cancel: 'Cancel',
    dates: 'Dates',
    successMessage: 'Changes saved successfully',
    errorMessage: 'There was an error saving the information',
  },
  calendarPage: {
    calendar: 'Calendar',
    recess: 'Recess',
    classCalendar: 'Class Calendar',
    seeMore: 'see more',
    nextClasses: 'Next Classes',
    pastClasses: 'Past Classes',
    studentCalendarPhrase: 'Here you can see the date of the next classes 🗓️',
    noTeacher: 'No teacher',
  },
  calendar: {
    classCalendar: 'CLASS CALENDAR',
    seeMore: 'see more',
    student: {
      seeLess: 'see less',
      calendar: 'Calendar',
      upcomingClasses: 'UPCOMING CLASSES',
    },
    admin: {
      calendar: 'Classes Calendar',
      selectDates: 'SELECT THE DATES',
      nextClasses: 'Next Classes',
      allUnits: 'All units',
      filters: {
        allLessons: 'all lessons',
        completedLessons: 'completed lessons',
        plannedLessons: 'planned lessons',
      },
      noContent: {
        allLessons: 'No classes for this date',
        completedLessons: 'No classes completed on this date',
        plannedLessons: 'No classes planned for this date',
      },
      statisticCard: {
        classes: 'CLASSES',
        frequency: 'ATTENDANCE',
        weekFrequency: 'ATTENDANCE IN LAST 7 DAYS',
        monthFrequency: 'ATTENDANCE IN LAST 30 DAYS',
      },
      subtitleCard: {
        classesCompleted: 'classes completed',
        studentFrequency: 'students frequency',
      },
      manageKlass: {
        klassLink: 'Access class link',
        noKlassLink: 'This class has no link',
        report: 'Report',
        teacher: 'Teacher',
        delayedReport: 'Report Pending',
        noKlassTooltip: 'Try accessing it through the klass table',
        edit: 'edit',
        finish: 'finish',
        alter: 'alter',
        alterLessonDate: 'alter only this lesson',
        attendance: 'Attendance:',
        progress: 'Progress:',
        accessKlass: 'access class',
        alterLink: 'alter link',
        anticipate: 'anticipate lessons',
        postpone: 'postpone lessons',
        noStudentsInClass: 'No students, only admin can edit',
        manageAttendance: {
          attendances: 'Attendances:',
          absences: 'Absences:',
          reschedulings: 'Reschedulings:',
        },
        updateKlassLink: {
          updateKlassLinkTitle:
            'Do you want to add the link to the online classes?',
          success: 'Link updated successfully',
          error: 'Error updating link',
          placeholder: 'Insert Google Meet link',
          confirm: 'Confirm',
          cancel: 'Cancel',
        },
        updateDateKlass: {
          insertValidTime: 'Insert a valid time',
          updateDateKlassTitle:
            'Are you sure you want to change the date of this class?',
          selectDate: 'Select the new date for the next class:',
          confirmDate: 'Do you want to confirm the change?',
          success: 'Date changed successfully',
          error: 'Error changing date',
          confirm: 'Confirm',
          cancel: 'Cancel',
          or: 'or',
          postponeAnticipateWarning:
            'To change the date of multiple classes, use',
          timezoneWarning: 'Dates are in {{timezone}} time zone',
          noAvailableDatetime: 'No dates available',
          updateOtherLessonDate:
            'To change the date/time of this class, change the date/time of the previous class or the next class',
        },
        updateTeacher: {
          updateTeacherTitle:
            'Are you sure you want to replace the teacher for this class?',
          success: 'Replacement done successfully',
          error: 'Error replacing teacher',
          placeholder: 'Select another teacher',
          confirm: 'Confirm',
          cancel: 'Cancel',
        },
        anticipatePostpone: {
          warningResults:
            'This will be the new date for the next lesson, and all following classes will have their dates updated:',
          success: 'Dates changed successfully',
          confirm: 'Confirm',
          cancel: 'Cancel',
          noDateMessage: 'There are no dates for this action',
          postpone: {
            title:
              'Are you sure you want to postpone this and the next lessons?',
            warningMessage: 'Do you want to confirm postponing?',
            errorMessage: 'Error when postponing the lesson',
          },
          anticipate: {
            title:
              'Are you sure you want to antecipate this and the next lessons?',
            warningMessage: 'Do you want to confirm antecipating?',
            errorMessage: 'Error when antecipating the lesson',
          },
        },
        doNotAnticipateTooltip: 'It is not possible to anticipate this class',
      },
    },
  },

  manageScheduledLessonReport: {
    reportFromAnotherTeacherMessage:
      'This class is assigned to another teacher, it is not possible to complete this report.',
    contactAdminMessage:
      'If you taught this class, ask the unit administrator to change the teacher for this class.',
    hasNoPermission: 'You do not have permission to make this change',
    cantEditReport: 'You can not edit this report',
    publisInstructions:
      'This lesson is not published yet. Access edit page and finish all changes.',
    goEditPage: 'Access edit page',
    emptyContent: 'Enter a content for homework',
    cashMessage:
      'Students who did not miss class will receive Ctrl+Cash when the class was marked complete.',
    title: 'Class Completion Report',
    day: 'Day',
    notDone: 'Not done',
    students: 'Students',
    presence: 'Presence',
    hasDoneDesmark: 'Only an administrator can unmark this class as completed',
    challenge: 'Challenge',
    grades: 'Grades',
    placeholderLessonComment: 'Make remarks about {{student}} in this lesson',
    allValues: 'All values',
    hasSubmittion: 'Has submittion',
    noStudents: 'Without student',
    activeStudent: 'Active',
    isLockedDesc: 'Only an administrator can perform this action.',
    homeworkNotPublishedDesc:
      'Add a homerwork and your students will receive it',
    homeworkPublishedDesc: 'Homework published, waiting for student submittion',
    annotationsDesc: 'Annotations used by the pedagogical team',
    hasHomeworkActivity: 'Do not send homework',
    seeHomework: 'See student Submittion',
    submittion: 'Submittion',
    noSubmittion: 'Student did not answer activity.',
    addGrade: 'Add Grade',
    missedPresenceStatus: 'M',
    nonMandatoryPhrase: 'It is not mandatory to fill in all fields.',
    notHomeworkPhrase: 'You have not yet added an assignment for this class.',
    notCtrlCashPhrase:
      'This lesson has already been marked as completed and you cannot change the Ctrl+Cash',
    pending: 'Pending',
    published: 'Published',
    draft: 'Draft',
    publish: 'Publish',
    homework: 'Homework',
    behavior: 'Behavior',
    addComments: 'Add Comments',
    practicalChallenge: 'Pratical Challenge',
    effort: 'Effort',
    cancel: 'Cancel',
    save: 'Save',
    reset: 'Reset',
    savedActiveStudent: 'Student activated',
    hasDone: 'Completed',
    gotoLesson: 'Go to lesson',
    selectStudentPhrase:
      'Select a student on the side by clicking on their name.',
    contentGiven: 'Content Given',
    contentGiveOptions: {
      [ContentGivenEnum.GAVE_100_PERCENT_AND_MORE]:
        'I managed to give all the content and more',
      [ContentGivenEnum.GAVE_100_PERCENT]: 'I gave 100% of the content',
      [ContentGivenEnum.GAVE_UP_T0_50_PERCENT]:
        'I gave up to 50% of the content',
      [ContentGivenEnum.GAVE_BETWEEN_50_99_PERCENT]:
        'I gave between 50-99% of the content',
    },
    selectAnOptionPhrase: 'Select an option',
    classNotes: 'Class notes:',
    canWritePhrase:
      'You can write down where you left off in class, or any other details.',
    homeworkDisplayedPhrase: 'Homework will be displayed to students',
    addHomeworkPhrase: 'Add a homework assignment for students.',
    classCompleted: 'Mark class as completed',
    classNotCompleted: 'Mark class as not completed',
    studentHasNoComments: 'This student has no comments',
    saved: 'Saved',
    savedScheduledLesson: 'Lesson alterations saved',
    savedHomework: 'Homework saved',
    savedReport: 'Report saved',
    savedGivenContent: 'Given content saved',
    selectAValidOptionPhrase: 'Select a valid option',
    requestMessages: {
      studentPresenceConfimed: 'Student attendance saved',
      studentPresenceRemoved: 'Student attendance removed',
      studentAbsenceConfirmed: 'Confirmed student absence',
      genericError: 'An internal error has occurred. Please contact support',
    },
    absences: 'Absences',
    presences: 'Presences',
    classCompletedButtonPhrase: 'Class completed',
    notPossibleToChangePhrase:
      'It is not possible to change the class after it is finished',
    annotationsPlaceholder:
      'You can write down where you left off in class, or any other details.',
    homeworkGradeMarker: {
      homeworkPreviousButton: 'Homework from the previous class',
      saved: 'Saved',
    },
    active: 'Active',
    inactive: 'Inactive',
    transfered: 'Transferred',
    noActiveStudentPhrase: 'No active students on the day of this lesson.',
    noReportForActiveStudent:
      '{{student}} does not have a report in this lesson',
    noActiveStudentsInKlass: 'There are no active students in this class',
    anotherKlassTag: 'Another class',
    selectStudentReport:
      'Select a student from the list to access their report in this area',
  },
  teacherDashboard: {
    hello: 'Hello,',
    visitor: 'Visitor',
    absenceReports: {
      newer: 'newest',
      older: 'oldest',
      markAsRead: 'Mark as read',
      openReport: 'Open Report',
      absenceReports: 'Absences Reports',
      markAllAsRead: 'Mark all as read',
      noAbsenceReports: 'No absences',
      allReadSuccessfuly: 'Notifications read successfully',
      klassNotFound: 'Class not found',
      groups: {
        klass: 'class',
        teacher: 'teacher',
        all: 'all',
      },
      totalItems_one: '{{count}}} item',
      totalItems_other: '{{count}} items',
      lastLessonsHelpMessage: {
        default: 'Present at {{lessonName}} lesson',
        MISSED: 'Missed {{lessonName}} lesson',
      },
      seeMore: 'see more',
    },
    teacherKlasses: {
      yourClasses: 'Your classes',
      informations: 'Informations',
      progress: 'Progress',
      classes: 'Classes',
      noStudents: 'No students',
      noClassesPhrase: 'There are currently no classes',
      noStudentsToFollow: 'No students to follow',
      sheet: 'Class sheet',
      studentSheet: 'Student sheet',
    },
    teacherReports: {
      pendingReports: 'Pending Reports',
      noReportsPhrase: 'There are no pending reports',
    },
    pendingHomeworks: {
      homeworkForCorrection: 'Homeworks for Correction',
      noHomeworks: 'No homework to correct',
      klassNotFound: 'Class not found',
    },
  },
  adminDashboard: {
    totalStudents: 'Total Students',
    totalKlasses: 'Total Classes',
    totalTeachers: 'Total Teachers',
    churnCount: 'Churn',
    attendanceCount: 'Attendance',
    studentsPerKlass: 'Students per Class',
    studentsPerTeacher: 'Students per Teacher',
    lastUpdated: 'Last updated',
    allUnits: 'All units',
    pendingReports: 'Pending Reports',
  },
  componentPagination: {
    next: 'Next',
    prev: 'Previous',
    totalOf: {
      students: 'Total of students:',
      klasses: 'Total of classes:',
      teachers: 'Total of teachers:',
      courses: 'Total of courses:',
      units: 'Total of units:',
      team: 'Total of members:',
    },
    of: 'of',
    pages: 'pages',
    page: 'page',
  },

  editMaterialPage: {
    editMaterial: 'Edit Material',
    legalInformation: 'Legal Information',
    save: 'Save',
    chapterTitle: 'Chapter Title',
    content: 'Content',
    chapters: 'Chapters',
    placeholderEditor: 'H2: For sections within chapters, use ”Heading 2”',
    newChapterButton: 'New Chapter',
    saveButton: 'Save',
    cancelButton: 'Cancel',
    cantModify: 'Not allowed in limited editing',
    createChapterMessage: 'Added chapter',
    saveChapterMessage: 'Chapter successfully saved',
    saveOrderMessage: 'New order successfully saved',
    deletedChapterMessage: 'Successfully deleted chapter',
    oneChapterMessage: 'The book must contain at least one chapter',
    uploadMaterial: 'Upload Material',
    createMaterial: 'Create Material',
    deleteMaterial: 'Delete Material',
    bookIsBeingFormatedMessage: 'Please wait while the book is being formatted',
    deleted: 'Material deleted successfully',
    updateSuccess: 'Changes saved successfully',
    autosave: 'Autosave...',
    chapterFormattingFailureMessage:
      'An error occurred while formatting this chapter. Please check for any broken links, corrupted images, or empty spaces.',
  },

  rewardsView: {
    coinTooltip:
      'You can earn coins by completing activities, material, challenges, and more',
    gemTooltip:
      'You can earn gems by 100% completing a lesson or finishing some projects ',
    xpTooltip:
      'You can earn xp by doing activities, earning likes on projects, gaining attendance in classes, among others.',
    xp: '{{xp}} EXP',
    level: 'Level {{lvl}}',
    errors: {
      genericError: 'Unable to load rewards. contact support',
    },
  },

  modalRewards: {
    attentionPhrase: 'Are you sure you want to add or withdraw Ctrl+Cash?',
    rewardsButtons: {
      addCoins: 'add coins',
      withdrawCoins: 'withdraw coins',
    },
    inputPlaceholderPhrase: 'Enter what the student purchased',
    inputPlaceholderValue: 'Value',
    inputPlaceholderReason: 'Enter the reason for this transaction',
    confirmWithdrawButton: 'Withdraw',
    confirmDepositButton: 'Deposit',
    cancelButton: 'Cancel',
    select: {
      book: 'Book',
      activity: 'Activity',
      challenge: 'Challenge',
      homework: 'Homework',
      videoAccess: 'Video access',
      programAccess: 'Program access',
      lesson: 'Lesson',
      minorProject: 'Minor Project',
      majorProject: 'Major Project',
      behavior: 'Behavior',
      effort: 'Effort',
      presence: 'Presence',
      selectTheReason: 'Select reward reason',
    },
    messages: {
      transactionSuccess: 'Transaction done successfully',
      transactionWarning: 'Fill in all fields',
      genericError: 'An error occurred while fetching the rewards',
    },
  },

  modalHomework: {
    addHomeworkAssessment: 'Add homework assessment',
    notDone: 'Not done',
    confirmButton: 'Confirm',
    cancelButton: 'Cancel',
  },

  modalEmptyActivity: {
    attentionPhrase: 'Attention! This course has empty activity(s)',
    deletePhrase:
      'Do you want to delete the activity(s) before publishing it or continue editing?',
    confirmButton: 'Delete and Publish',
    cancelButton: 'Cancel',
    lesson: 'Lesson',
    extraLesson: 'Extra Lesson',
  },

  userForm: {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    dateOfBirth: 'Birth',
    username: 'Username',
    state: 'State',
    city: 'City',
    school: 'School',
    unit: 'Unit',
    phoneNumber: 'Phone Number',
    klass: 'Class',
    userType: 'Role',
    status: 'Status',
    cancel: 'Cancel',
    save: 'Save',
    saved: 'Saved',
    userStatus: userStatus,
    errors: {
      required: 'This field is required',
    },
  },
  projectPage: {
    projects: 'Projects',
    noProjectsFound: 'No projects found',
    filters: {
      allProjects: 'All',
      myProjects: 'My projects',
      myClassProjects: 'My class',
      myCourseProjects: 'My course',
    },
    projectInfoModal: {
      cancel: 'Cancel',
      description: 'Description',
      projectUrl: 'Project link',
      publishProject: 'Publish project',
      selectTool: 'Select tool',
      title: 'Title',
      publishNewProject: 'Publish new project',
      projectUrlPlaceholder: 'Insert project link',
      titlePlaceholder: 'Insert project title',
      updateProject: 'Updat project',
      imageUploadFail: 'An error occurred while uploading image:',
      isStudentProject: 'Are you publishing a student project?',
      studentSelectPlaceholder: 'Select student',
      invalidLink: 'Invalid link',
      projectUrlRequired: 'Project link is required',
      titleRequired: 'Title is required ',
      toolRequired: 'Tool is required',
      imageRequired: 'Project image is required',
      successAddedMessage: 'Project successfully added',
    },
    allTools: 'All tools',
    addProject: 'Add project',
    search: 'Search',
    all: 'All',
    highlights: 'Highlights',
    recents: 'Recent',
  },
  projectView: {
    description: 'Description',
    projects: 'Projects',
    checkInfo: 'Check if you are in correct route',
    projectNotFound: 'Project not found',
    editProject: 'Edit',
    deleteProject: 'Delete project',
  },
  inventory: {
    backpack: 'Backpack',
    save: 'Save',
    cancel: 'Cancel',
    alterFail: 'There was an issue saving the avatar',
    alterSuccess: 'Changes saved successfully',
    removeItem: 'Remove item',
    goToShop: 'Go to shop',
    purchaseSuccess: 'Purchase fulfilled successfully',
    purchaseError: 'There was an error trying to purchase the item',
    inventory: 'Inventory',
    shop: 'Shop',
    notEnoughCash: 'Not enough balance',
    confirmPurchase: "Are you sure you'd like to purchase the item below?",
    equipItem: 'Equip Item',
    confirm: 'Confirm',
    noItems: 'No item available',
    itemCategory: {
      base: 'Base',
      top: 'Top',
      bottom: 'Bottom',
      accessories: 'Accessories',
      equipment: 'Equipment',
    },
    inventoryItems: {
      hair: 'Hair',
      base: 'Body',
      hat: 'Hat',
      head: 'Head',
      face: 'Face',
      topFaceAcessory: 'Eyes',
      bottomFaceAcessory: 'Mouth',
      neckAcessory: 'Neck',
      shirt: 'Shirt',
      coat: 'Coat',
      pants: 'Pants',
      shoe: 'Shoe',
      hand: 'Hand',
      leftHandAcessory: 'Left Hand',
      rightHandAcessory: 'Right Hand',
    },
  },
  klassProgress: {
    myLessons: 'My Lessons',
    lessonProgressList: {
      didNotFindLesson: 'Could not find the lesson. Talk to support.',
      generalError:
        'An error occurred while trying to load the lesson progress',
      noLessons: 'It seems that there are no lessons. Talk to support.',
    },
  },

  nextLessonView: {
    noNextLesson: "Looks like you don't have an upcoming class scheduled.",
    noTeacher: 'Teacher not selected',
  },

  editActivity: {
    title: 'Question:',
    subtitle: 'Subtitle:',
    fields: {
      errors: {
        noTitle: 'Enter the activity question',
      },
    },
  },

  klassInput: {
    placeholder: 'Select a class',
    warnings: {
      noResults: 'No results',
      noStudentsToFollow: 'No students to follow',
    },
    errors: {
      internalError: 'An internal error has occurred. Contact support',
    },
  },

  rewardInput: {
    placeholder: 'Value',
  },

  lessonForm: {
    name: 'Class Title',
    description: 'Class Description',
    message: 'Motivational Message:',
    toolUrl: 'Program access link',
    instructionsUrl: 'Install/access the program',
    toolLinkMessage: 'Example: https://scratch.mit.edu/',
    videoInstallMessage: 'Example: https://www.youtube.com/watch?v=PWGX3QfAwCA',
    additHere: 'add it',
    saved: 'Changes saved successfully',
    tools: 'Tools',
    topics: 'Topics',
    toolOption: 'Tool option',
    normal: 'Normal',
    free: 'Free',
    inBank: 'Add in the Lesson Bank',
    isPublicInBank: 'Public',
    links: 'Links',
    freeExplain:
      'When the content of the class is outside the curriculum, increasing the total number of classes in the course. Recommended for events or holidays.',
    normalExplain:
      'When you need to replace a tool or spend more time finishing a project. You can disable another class to keep the total of 18 classes.',
    addAuxiliaryLinks: 'Add links',
    warnings: {
      required: 'This field is required',
      validateLink: 'Enter a valid link',
      mainTool: 'Select a main tool',
    },
    toolPhrase: 'If the tool is not on the list, you can',
    addToolTitle: 'Add tool',
  },

  enrollmentStatus: {
    ACTIVE: 'Active',
    AWAITING_CLASS: 'Awaiting class',
    AWAITING_PAYMENT: 'Awaiting payment',
    COURSE_TRANSFERRED: 'Course transfered',
    CLASS_TRANSFERRED: 'Class transfered',
    CONCLUDED: 'Graduated',
    CANCELED: 'Canceled',
    TRANSFERRED_FROM_CLASS: 'Class transferred',
    TRANSFERRED_TO_CLASS: 'Report in another class',
    ACTIVE_IN_THIS_LESSON: 'Canceled in future lessons',
  },

  tagInput: {
    tool: 'Tools',
    mainToolBanner: 'Set as banner',
    editTag: 'Edit tag',
    banner: 'Banner',
  },

  tagForm: {
    name: 'Name',
    image: 'Image',
    link: 'Access link',
    color: 'Color',
    clear: 'Clear',
    confirm: 'Confirm',
    warnings: {
      required: 'This field is required',
      validateLink: 'Enter a valid link',
    },
    editSaved: 'Changes saved successfully',
    addSaved: 'Tag created successfully',
  },

  colorInput: {
    color: 'Color',
    clear: 'Clear',
    confirm: 'Confirm',
    warnings: {
      validateHexadecimalCode: 'Enter a valid color',
    },
  },

  listLessons: {
    successUpdateOrder: 'Updated class order',
    warnings: {
      noCourse:
        'The course could not be loaded. Please try again later or contact the support team',
    },
  },

  coursesTab: {
    warnings: {
      noCoursesPath: 'No courses found',
    },
  },

  klassStatus,

  userTypes: {
    Student: 'Student',
    Teacher: 'Teacher',
    Super_Admin: 'Super Admin',
    Unit_Admin: 'Unit Admin',
    Parent: 'Parent',
  },

  courseTypes: {
    CTRL_KIDS: 'Ctrl+Kids',
    CTRL_TEENS: 'Ctrl+Teens',
    CTRL_YOUNG: 'Ctrl+Young',
    CTRL_QUICK: 'Ctrl+Quick',
    CTRL_PRO: 'Ctrl+Pro',
  },

  userStatus: {
    REGISTRED: 'Registred',
    ACTIVE_ENROLLMENT: 'Active Enrollment',
    INACTIVE: 'Inactive',
    WAITING_ENROLLMENT: 'Waiting Enrollment',
  },
  ckeditor: {
    placeholder: 'Write here...',
  },

  pedagogicalSheet: { ...sheet, title: 'Pedagogical Sheet' },

  scrollControl: {
    upButtonTooltip: 'Go to top',
    downButtonTooltip: 'Go to end',
  },

  commentAssessmentForm,

  presenceStatus,

  studentBehaviorRating: {
    1: 'Totally disinterested (Does activities different from what is proposed by the course, or does not do them at all)',
    2: 'Introverted, but with brief moments of socialization.',
    3: 'Has focus, can maintain attention throughout the class',
    4: "Organized but shows a certain discomfort regarding other people's disorganization",
    5: 'Totally interested (Does activities and challenges proposed by the course)',
  },

  klassSheet: { ...sheet, title: 'Class Sheet' },

  assessmentHeader: sheet,

  behaviorAssessmentForm,

  courseCategory: {
    ONLINE: 'Online',
    F2F: 'Face to face',
    SCHOOL: 'In school',
  },

  klassEnergyRating: {
    1: 'Quiet class',
    2: 'Normal class',
    3: 'Energetic class',
  },

  klassSpeedRating: {
    1: 'Slow class',
    2: 'Normal class',
    3: 'Fast class',
  },

  klassMaturityRating: {
    1: "Children's class",
    2: 'Normal class for age',
    3: 'Mature class',
  },

  buttonInfiniteList: {
    loadMore: 'Load more',
  },

  consentModal: {
    message:
      'We use cookies and local storage to enhance your browsing experience.',
    accept: 'Accept',
  },
  topics: {
    pageTitle: 'Topics',
    topbar: {
      new: 'Create new',
      searchPlaceholder: 'Search area',
      newArea: 'New area',
      save: 'Salvar',
      newTag: 'New tag',
    },
    tree: {
      createNew: 'Create a area',
    },
    workspace: {
      selectArea: 'Select a area',
    },
    moveTo: {
      cancel: 'Cancel',
      move: 'Move',
      placeholder: 'Search area',
      currentArea: 'current area',
      label: 'Move to',
      transformRoot: 'transform in root topic',
    },
    input: {
      label: 'Area',
      searchForTopics: 'Search for topics',
    },
    tooltip: {
      move: 'Move area',
      renaming: 'Rename',
      delete: 'Delete',
      area: {
        title: 'Area',
        desc: 'Areas can contain tags or other areas',
      },
      tag: {
        title: 'Tag',
        desc: 'Tags are content markers that add difficulty and level in an area',
      },
    },

    tags: {
      difficulty: {
        label: 'Difficulty',
        values: {
          ADVANCED: 'Advanced',
          INTERMEDIATE: 'Intermediate',
          BEGINNER: 'Beginner',
        },
      },
      level: {
        label: 'Nível',
        values: { 1: 'I', 2: 'II', 3: 'III', 4: 'IV', 5: 'V' },
      },
      description: {
        label: 'Description',
        required: 'Description is required',
      },

      add: 'Add',
      cancel: 'Cancel',
      addDifficulty: 'Add difficulty',
    },
  },
  wellcome: {
    hello: 'Hello, {{firstName}} 👋',
    student: 'Welcome back, crewman! Ready to continue your journey? 🚀',
  },
  warning: {
    addAndEditClass:
      'Attention! It is not possible to add or edit classes through the Portal, only through the Store',
  },

  weekdays: {
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday',
    SUNDAY: 'Sunday',
  },

  klassType: {
    REGULAR: 'Regular Class',
    CONECTA: 'Conecta Class',
  },
};

export default en;
