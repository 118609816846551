import { useTranslation } from 'react-i18next';

import KlassesIcon from './KlassesIcon';
import { ScheduledLessonReportEnum } from '@/models/ScheduledLessonReport';
import LessonProgress from '@/models/LessonProgress';
import Text from '@/components/common/dataDisplay/Text';
import {
  bookNotification,
  presenceNotification,
} from '@/utils/lessonProgressNotifications';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import ScheduledLesson, {
  ScheduledLessonTypeEnum,
} from '@/models/ScheduledLesson';
import Skeleton from '@/components/common/Skeleton';

interface KlassesIconCellProps {
  studentLessonProgress?: LessonProgress;
  isDisabled: boolean;
  testId?: string;
  scheduledLesson: ScheduledLesson;
  isLoading?: boolean;
}

export default function KlassesIconCell(props: KlassesIconCellProps) {
  const {
    studentLessonProgress,
    isDisabled,
    testId,
    scheduledLesson,
    isLoading,
  } = props;
  const isTransfer =
    studentLessonProgress?.scheduledLessonReport?.status ===
    ScheduledLessonReportEnum.TRANSFERED;
  const { t } = useTranslation();

  const freeLesson = scheduledLesson.type === ScheduledLessonTypeEnum.FREE;

  const handleBookNotification = (
    studentLessonProgress: LessonProgress | undefined,
  ): number => {
    if (studentLessonProgress && studentLessonProgress.bookProgress) {
      return bookNotification(studentLessonProgress.bookProgress, freeLesson);
    }

    return -1;
  };
  const bookNotificatonCount = handleBookNotification(studentLessonProgress);

  const activityNotificationCount = (
    studentLessonProgress: LessonProgress | undefined,
  ): number => {
    if (
      studentLessonProgress &&
      studentLessonProgress.activitiesProgress.length
    ) {
      return studentLessonProgress.activitiesProgress.filter(
        hasDone => !hasDone.hasDone,
      ).length;
    }
    return -1;
  };

  const activityNotification = activityNotificationCount(studentLessonProgress);

  const presenceNotificationCount = presenceNotification(
    studentLessonProgress?.scheduledLessonReport?.presence,
  );

  const className = 'w-52 h-20 border-b border-neutral-content p-3.5';

  if (isLoading) {
    return <Skeleton className={`${className} bg-primary-content shrink-0`} />;
  }

  if (!studentLessonProgress?.id) {
    return (
      <span className="flex text-center h-fit">
        <Text
          className={`${className} text-base-content/50 text-14 flex text-center items-center justify-center`}
          text={t('klassProgressPage.noProgress')}
        />
      </span>
    );
  }

  const reportFromAnotherClass =
    studentLessonProgress.scheduledLessonReport?.scheduledLesson !==
    scheduledLesson.id;

  return (
    <div
      data-testid={testId}
      className={`${className} flex shrink-0 relative gap-6 justify-center items-center ${
        isDisabled ? 'grayscale-[20] opacity-30' : ''
      }`}
    >
      <ConditionalRenderer condition={isTransfer}>
        <div className="absolute top-0 h-full w-3 rounded-r-md left-0 bg-primary-content text-12">
          <Text
            className="rotate-90 mt-2"
            text={t('klassProgressPage.transferred')}
          />
        </div>
      </ConditionalRenderer>

      <ConditionalRenderer condition={bookNotificatonCount !== -1}>
        <KlassesIcon
          notificationCount={bookNotificatonCount}
          type="studentBook"
        />
      </ConditionalRenderer>
      <ConditionalRenderer condition={activityNotification !== -1}>
        <KlassesIcon notificationCount={activityNotification} type="activity" />
      </ConditionalRenderer>

      <KlassesIcon
        disabled={reportFromAnotherClass || presenceNotificationCount === -1}
        notificationCount={presenceNotificationCount}
        type="attendance"
      />
    </div>
  );
}
