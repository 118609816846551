import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueries, useQuery } from '@tanstack/react-query';
import { BookOpenIcon, CheckIcon } from '@heroicons/react/outline';

import MainButton from '@/components/common/buttons/MainButton';
import Badge from '@/components/common/dataDisplay/Badge';
import { ModalHomework } from '@/components/common/modals/ModalHomework/ModalHomework';
import { HomeworkActivityProgress } from '@/models/HomeworkActivity';
import { handleTeacherHomeworkRevision } from '@/utils/teacherHomeworkNotification';
import { formatLessonName } from '@/functions/lessonsName';
import { REQUEST_STALE_TIME_IN_MS } from '@/constants';
import Skeleton from '@/components/common/Skeleton';
import Text from '@/components/common/dataDisplay/Text';
import { getErrorMessage } from '@/utils/getErrorMessage';
import {
  homeworkActivitiesQueryKeys,
  homeworkProgressQueryKeys,
  scheduledLessonsQueryKeys,
} from '@/data/services/querykeys';
import { useActiveStudents } from '../scheduledLessonReport/StudentReports.hook';

type HomeworkGradeMarkerProps = {
  klassId: number;
  scheduledLessonOrder?: number;
};

export const HomeworkGradeMarker = ({
  klassId,
  scheduledLessonOrder,
}: HomeworkGradeMarkerProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageScheduledLessonReport.homeworkGradeMarker',
  });

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const {
    data: { results: previousScheduledLessons } = {},
    isInitialLoading: loadingPrevScheduledLesson,
    error: previousScheduledLessonError,
  } = useQuery({
    enabled: !!scheduledLessonOrder,
    staleTime: REQUEST_STALE_TIME_IN_MS,
    ...scheduledLessonsQueryKeys.list({
      klassId,
      isActive: true,
      orderLt: scheduledLessonOrder,
      ordering: '-datetime',
      pageSize: 1,
    }),
  });

  const previousScheduledLesson = previousScheduledLessons?.at(0);

  const { enrollments } = useActiveStudents({
    active: true,
    klassId,
    scheduledLessonId: previousScheduledLesson?.id,
  });

  const scheduledLessonErrorDetail = getErrorMessage(
    previousScheduledLessonError,
  );

  const {
    data: { results: previousHomeworks } = {},
    isInitialLoading: loadingHomework,
  } = useQuery({
    ...homeworkActivitiesQueryKeys.list({
      activity: previousScheduledLesson?.homeworkActivity ?? 0,
    }),
    enabled: !!previousScheduledLesson?.homeworkActivity,
  });

  const previousHomework = previousHomeworks?.at(0);

  const prevHomeworkProgressResult = useQueries({
    queries: enrollments.map(({ student }) => ({
      ...homeworkProgressQueryKeys.list(previousHomework?.id ?? 0, {
        studentId: student.id,
      }),
      enabled: !!previousHomework?.id,
    })),
  });

  const homeworkProgresses = prevHomeworkProgressResult
    .map(({ data }) => data?.results.at(0))
    .filter(
      homeworkProgress => homeworkProgress !== undefined,
    ) as HomeworkActivityProgress[];

  const filterPendingHomeworks = (
    homeworkProgress?: HomeworkActivityProgress,
  ) => {
    if (homeworkProgress) {
      const {
        status,
        homework: { assignmentType },
      } = homeworkProgress;

      return (
        handleTeacherHomeworkRevision(status, assignmentType) === 'pending'
      );
    }
    return false;
  };

  const homeworkGradeNotification = homeworkProgresses.filter(
    filterPendingHomeworks,
  ).length;

  const haveNotification = homeworkGradeNotification !== 0;

  const loading = loadingPrevScheduledLesson || loadingHomework;

  if (loading)
    return <Skeleton className="h-8 rounded-md bg-primary/40 w-56" />;

  if (scheduledLessonErrorDetail)
    return (
      <Text className="text-primary/40" text={scheduledLessonErrorDetail} />
    );

  if (previousScheduledLesson?.homeworkActivity)
    return (
      <div>
        <ModalHomework
          homeworkProgresses={homeworkProgresses}
          isVisible={isOpenModal}
          onClickCancel={() => setIsOpenModal(false)}
          lessonName={formatLessonName(
            previousScheduledLesson.lesson,
            previousScheduledLesson,
          )}
          enrollments={enrollments}
        />

        <Badge count={homeworkGradeNotification} position="-left-1 bottom-6">
          <MainButton
            color="custom"
            size="medium"
            dataTestId="homeworkGradeMarkerButton"
            text={t('homeworkPreviousButton')}
            icon={haveNotification ? <BookOpenIcon /> : <CheckIcon />}
            className={`select-none py-2 px-2.5 text-base-100 ${
              haveNotification
                ? 'bg-primary'
                : 'bg-success cursor-default disabled'
            }`}
            onClick={() => haveNotification && setIsOpenModal(true)}
          />
        </Badge>
      </div>
    );

  return <Fragment />;
};
