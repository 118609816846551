import {
  useState,
  MouseEvent,
  FormEvent,
  ChangeEvent,
  MouseEventHandler,
  useEffect,
} from 'react';
import { SearchIcon, XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

import { LoadingIcon } from '../icons';
import OutlineButton from './buttons/OutlineButton';
import TextInput, { TextInputProps } from './dataInput/TextInput';
import ConditionalRenderer from './ConditionalRenderer';

interface SearchAndFilterButtonsProps {
  onChange: (e: any) => void;
  searchInitial?: string;
  isSearch?: boolean;
  clearFiltersButton?: MouseEventHandler<HTMLButtonElement>;
  clearOrderButton?: MouseEventHandler<HTMLButtonElement>;
  clearAllButton?: MouseEventHandler<HTMLButtonElement>;
  clearSearch?(): void;
  isLoading?: boolean;
}

export default function SearchAndFilterButtons({
  onChange,
  searchInitial = '',
  clearAllButton,
  clearFiltersButton,
  clearOrderButton,
  clearSearch,
  isSearch,
}: SearchAndFilterButtonsProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageTeam.listUsers',
  });

  const [search, setSearch] = useState<string>(searchInitial);

  const clearFilters = (e: MouseEvent<HTMLButtonElement>) => {
    setSearch('');
    clearFiltersButton?.(e);
  };

  const clearAll = (e: MouseEvent<HTMLButtonElement>) => {
    setSearch('');
    clearAllButton?.(e);
  };

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    onChange(e);
  };

  const onDeselect = () => {
    setSearch('');
    clearSearch?.();
  };

  useEffect(() => {
    setSearch(searchInitial);
  }, [searchInitial]);

  return (
    <div className="flex items-center gap-2.5">
      <SearchAndFilterInput
        search={search}
        searchingMode={isSearch}
        onChange={onChangeSearch}
        onDeselect={onDeselect}
      />

      <ConditionalRenderer condition={clearFiltersButton}>
        <OutlineButton text={t('clearFilters')} onClick={clearFilters} />
      </ConditionalRenderer>
      <ConditionalRenderer condition={clearOrderButton}>
        <OutlineButton text={t('clearOrder')} onClick={clearOrderButton} />
      </ConditionalRenderer>
      <ConditionalRenderer condition={clearAllButton}>
        <OutlineButton text={t('clearAll')} onClick={clearAll} />
      </ConditionalRenderer>
    </div>
  );
}

export type SearchAndFilterInputProps = Pick<
  TextInputProps,
  'onChange' | 'isLoading'
> & {
  searchingMode?: boolean;
  search: string;
  onDeselect?(): void;
};

function SearchAndFilterInput({
  searchingMode,
  search,
  onDeselect,
  isLoading,
  onChange,
}: SearchAndFilterInputProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageTeam.listUsers',
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => e.preventDefault();

  const searchIcon = search ? (
    <XIcon
      data-testid="clearSearchButton"
      className="text-error cursor-pointer"
      onClick={onDeselect}
    />
  ) : (
    <SearchIcon className="w-4 h-4" />
  );

  const inputIcon = isLoading ? (
    <LoadingIcon className="w-4 h-4 text-primary" />
  ) : (
    searchIcon
  );

  return (
    <ConditionalRenderer condition={searchingMode}>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <TextInput
          type="text"
          className={{ base: 'text-primary w-40 shrink-0' }}
          icon={inputIcon}
          onChange={e => onChange?.(e)}
          value={search}
          placeholder={t('search')}
        />
      </form>
    </ConditionalRenderer>
  );
}
