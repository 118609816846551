import {
  createContext,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

import Klass from '@/models/Klass';
import ScheduledLesson from '@/models/ScheduledLesson';
import { SimplifiedCourseProgress } from '@/models/StudentCourseProgress';
import useAuth from '../hook/useAuth';
import { isStudent } from '@/functions/auth';
import { Course } from '@/models/Course';
import Rewards from '@/models/Rewards';
import { REQUEST_STALE_TIME_IN_MS, REWARDS_TRIGGER_EVENTS } from '@/constants';
import useListService from '../hook/useListService';
import { RequestEvent } from '@/utils/observers/RequestObserver';
import { getRequestObserver } from '@/axios';
import {
  klassesQueryKeys,
  nextScheduledLessonsQueryKeys,
  nextStepsQueryKeys,
  rewardsQueryKeys,
  simplifiedCourseProgressesQueryKeys,
} from '../services/querykeys';
import { enrollmentValidation } from '@/utils/enrollmentValidation';
import { getSimplifiedCourseProgress } from '../services/courseProgressServices';
import JsonApiResponse from '@/models/JsonApiResponse';

type StudentContextParams = {
  slugCourseName?: string;
};

export interface StudentContextProps {
  updateProgress(): Promise<void>;
  selectProgress(progress: SimplifiedCourseProgress): void;

  rewardsData?: Rewards;
  isLoadingRewards: boolean;
  shouldAnimateReward: boolean;

  nextSteps: Course[];

  loadingNextLesson?: boolean;
  nextLessonError?: unknown;
  nextLesson?: ScheduledLesson;

  loadingKlass: boolean;
  klassError: unknown;
  klass?: Klass;

  loadingProgress: boolean;
  progressError: unknown;
  progress: SimplifiedCourseProgress[];
  currentProgress?: SimplifiedCourseProgress;

  setParams: (params: StudentContextParams) => void;

  validEnrollment: boolean;
}

export const StudentContext = createContext<StudentContextProps>(
  {} as StudentContextProps,
);

export function StudentProvider({ children }: PropsWithChildren) {
  const { user } = useAuth();
  const queryClient = useQueryClient();

  const enabled = !!user && isStudent(user.userType);

  const [shouldAnimateReward, setShouldAnimateReward] =
    useState<boolean>(false);

  const previousRewardsData = useRef<Rewards>();

  const [{ slugCourseName = '' }, setParams] = useState<StudentContextParams>(
    {},
  );

  const { queryFn: rewardsQueryFn, queryKey: rewardsQueryKey } =
    rewardsQueryKeys.get(user?.id ?? 0);

  const { data: rewardsData, isInitialLoading: isLoadingRewards } = useQuery({
    queryKey: rewardsQueryKey,
    queryFn: rewardsQueryFn,
    enabled,
  });

  const eventMatches = (
    currentEvent: RequestEvent,
    expectedEvent: RequestEvent,
  ) => {
    const isExpectedUrl = currentEvent.url.includes(expectedEvent.url);
    const isExpectedMethod = currentEvent.method === expectedEvent.method;
    const isExpectedStatus =
      currentEvent.statusCode === expectedEvent.statusCode;

    return isExpectedUrl && isExpectedMethod && isExpectedStatus;
  };

  useEffect(() => {
    const invalidateRewards = async () =>
      await queryClient.invalidateQueries(rewardsQueryKey);
    const observerId = 'rewards';
    const requestObserver = getRequestObserver();

    if (enabled) {
      const onTriggerRewards = async (event: RequestEvent) => {
        for (const expectedEvent of REWARDS_TRIGGER_EVENTS) {
          if (eventMatches(event, expectedEvent)) {
            await invalidateRewards();
            break;
          }
        }
      };

      requestObserver.subscribe({
        id: observerId,
        action: onTriggerRewards,
      });
    }

    return () => {
      requestObserver.unsubscribe(observerId);
    };
  }, [queryClient, rewardsQueryKey, enabled]);

  useEffect(() => {
    let mounted = true;
    let timeOut: NodeJS.Timeout;
    if (mounted && rewardsData) {
      const previousRewards = previousRewardsData.current;
      const rewardsHasChange = previousRewards?.coins !== rewardsData.coins;
      if (previousRewards && rewardsHasChange) {
        setShouldAnimateReward(true);
        timeOut = setTimeout(() => {
          setShouldAnimateReward(false);
        }, 2000);
      }
      previousRewardsData.current = rewardsData;
    }
    return () => {
      mounted = false;
      clearTimeout(timeOut);
    };
  }, [rewardsData]);

  const {
    results: progress,
    isInitialLoading: loadingProgress,
    error: progressError,
  } = useListService({
    ...simplifiedCourseProgressesQueryKeys.list(user?.id ?? 0),
    enabled,
    staleTime: REQUEST_STALE_TIME_IN_MS,
  });

  const [currentProgress, setCurrentProgress] =
    useState<SimplifiedCourseProgress>();

  useEffect(() => {
    if (progress.length && !currentProgress) {
      if (slugCourseName)
        setCurrentProgress(
          progress.find(course => course.coursePath.slug === slugCourseName),
        );
      else setCurrentProgress(progress.at(-1));
    }
  }, [currentProgress, progress, slugCourseName]);

  const manualUpdateProgress = (updated: SimplifiedCourseProgress) => {
    setCurrentProgress(updated);

    if (currentProgress && user) {
      const queryKey = simplifiedCourseProgressesQueryKeys.list(
        user.id,
      ).queryKey;

      const queryData =
        queryClient.getQueryData<JsonApiResponse<SimplifiedCourseProgress>>(
          queryKey,
        );

      if (queryData) {
        const updatedCourseProgresses = queryData.results.map(data =>
          data.id === currentProgress.id ? updated : data,
        );

        queryClient.setQueryData<JsonApiResponse<SimplifiedCourseProgress>>(
          queryKey,
          { ...queryData, results: updatedCourseProgresses },
        );
      }
    }
  };

  const updateProgress = async () => {
    if (currentProgress && user) {
      const updated = await getSimplifiedCourseProgress(
        user.id,
        currentProgress.id,
      );

      if (updated) manualUpdateProgress(updated);
    }
  };

  function selectProgress(progress: SimplifiedCourseProgress) {
    setCurrentProgress(progress);
  }

  const validEnrollment = enrollmentValidation(
    currentProgress?.enrollmentStatus,
  );

  const enabledKlass = enabled && !!currentProgress && validEnrollment;

  const {
    data: klass,
    isInitialLoading: loadingKlass,
    error: klassError,
  } = useQuery({
    ...klassesQueryKeys.get(currentProgress?.klassId ?? 0),
    enabled: enabledKlass,
  });

  const {
    data: nextLesson,
    isInitialLoading: loadingNextLesson,
    error: nextLessonError,
  } = useQuery({
    enabled: enabled && !!klass,
    ...nextScheduledLessonsQueryKeys.get(klass?.id ?? 0),
    retry: false,
  });

  const { results: nextSteps } = useListService({
    enabled: enabled && !!klass,
    staleTime: REQUEST_STALE_TIME_IN_MS,
    ...nextStepsQueryKeys.list(currentProgress?.id ?? 0),
  });

  const { pathname } = useLocation();

  const isLogin = pathname === '/login';

  useEffect(() => {
    if (isLogin) {
      setCurrentProgress(undefined);
      previousRewardsData.current = undefined;
    }
  }, [isLogin]);

  return (
    <StudentContext.Provider
      value={{
        loadingProgress,
        progressError,
        progress,
        currentProgress,

        loadingKlass,
        klass,
        klassError,

        rewardsData,
        isLoadingRewards,
        shouldAnimateReward,
        nextSteps,

        loadingNextLesson,
        nextLessonError,
        nextLesson,

        updateProgress,
        selectProgress,
        setParams,

        validEnrollment,
      }}
    >
      {children}
    </StudentContext.Provider>
  );
}

export default StudentContext;
