import { InputHTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import ConditionalRenderer from '../ConditionalRenderer';

export type CheckboxProps = {
  value?: string | number | number[];
  text?: string;
} & InputHTMLAttributes<HTMLInputElement>;
const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (checkboxProps, ref) => {
    const { id, onClick, checked, value, text, ...props } = checkboxProps;
    const alternativeId = 'checkbox' + JSON.stringify(checkboxProps);
    return (
      <div className="flex gap-2 items-center">
        <input
          ref={ref}
          id={id || alternativeId}
          data-testid={id || alternativeId}
          type="checkbox"
          {...props}
          className={twMerge(
            'checkbox-sm checkbox checkbox-primary border rounded-sm w-3.5 h-3.5',
            props.className,
          )}
          onClick={onClick}
          checked={checked}
          value={value}
        />
        <ConditionalRenderer condition={text}>
          <label
            className="text-left text-14 font-400 text-base-content cursor-pointer leading-4"
            htmlFor={id || alternativeId}
          >
            {text}
          </label>
        </ConditionalRenderer>
      </div>
    );
  },
);

export default Checkbox;
