import BetaModal from '@/components/admin/courses/BetaModal';
import EnableEditModal from '@/components/admin/courses/EnableEditModal';
import PublishBetaModal from '@/components/admin/courses/PublishBetaModal';
import PublishEditModal from '@/components/admin/courses/PublishEditModal';
import ComponentGuard from '@/components/common/ComponentGuard';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import ErrorComponent from '@/components/common/ErrorComponent';
import HeadTitle from '@/components/common/HeadTitle';
import Heading3 from '@/components/common/Heading3';
import ActionDetailCard from '@/components/common/buttons/ActionDetailCard';
import MainButton from '@/components/common/buttons/MainButton';
import Text from '@/components/common/dataDisplay/Text';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import ModalBank from '@/components/common/modals/ModalBank/ModalBank';
import ModalEmptyActivity from '@/components/common/modals/ModalEmptyActivity';
import CourseStatus from '@/components/courses/CourseStatus';
import LegalInformationCourseCollapse from '@/components/courses/LegalInformationCourseCollapse';
import { BranchIcon } from '@/components/icons';
import AddExtraLessonModal from '@/components/lessons/extra-lessons/AddExtraLessonModal';
import Layout from '@/components/template/Layout';
import useCourseEditing from '@/data/hook/useCourseEditing';
import { deleteActivity } from '@/data/services/activityServices';
import {
  getEmptyActivities,
  listLessonsByCourseSlug,
} from '@/data/services/lessonServices';
import { deleteScheduledLesson } from '@/data/services/scheduledLessonsServices';
import { EmptyActivityWithLesson } from '@/models/Activity';
import CoursePath, { ICoursePath } from '@/models/Course';
import { ApiError } from '@/models/Errors';
import Klass from '@/models/Klass';
import { Lesson } from '@/models/Lesson';
import { UserTypeEnum } from '@/models/User';
import alert from '@/utils/UseAlert';
import { translateKlassName } from '@/utils/translateKlassName';
import {
  ChevronLeftIcon,
  CogIcon,
  CollectionIcon,
  PencilIcon,
  PlusIcon,
  UploadIcon,
} from '@heroicons/react/outline';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LessonListManager from '@/components/lessons/LessonListManager';
import useAuth from '@/data/hook/useAuth';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import {
  coursePathsQueryKeys,
  klassesQueryKeys,
  scheduledLessonsQueryKeys,
} from '@/data/services/querykeys';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
export default function KlassPageAdmin() {
  type activeRequestType = 'none' | 'publish' | 'edit' | 'version';
  type modalsTypes =
    | 'none'
    | 'beta'
    | 'beta-publish'
    | 'edit-publish'
    | 'edit'
    | 'activity';
  const { t } = useTranslation('translation', {
    keyPrefix: 'coursesView.admin',
  });
  const { t: tErrors } = useTranslation('translation', {
    keyPrefix: 'erros',
  });

  const { user: authUser } = useAuth();

  const { slugCourseName = '', klassId } = useParams();
  const [modalOpen, setModalOpen] = useState<modalsTypes>('none');
  const [activeRequest, setActiveRequest] = useState<activeRequestType>('none');
  const [reload, setReload] = useState(false);

  const [emptyActivities, setEmptyActivities] = useState<
    EmptyActivityWithLesson[]
  >([]);

  const [isLoadingDeleteActivities, setIsLoadingDeleteActivities] =
    useState(false);

  const navigate = useNavigate();
  const {
    allowEditingMode,
    allowVersioningMode,
    courseToPublish,
    handleKlassChange,
  } = useCourseEditing();

  const { data: coursePath, isError } = useQuery({
    refetchOnWindowFocus: false,
    enabled: !!slugCourseName,
    ...coursePathsQueryKeys.get(slugCourseName),
  });

  const [selectedCoursePath, setSelectedCoursePath] = useState<ICoursePath>();

  useEffect(() => {
    setSelectedCoursePath(coursePath);

    return () => {
      setSelectedCoursePath(undefined);
    };
  }, [coursePath]);

  const hasKlassId = !isNaN(Number(klassId));

  const { data: klass } = useQuery({
    refetchOnWindowFocus: false,
    enabled: hasKlassId,
    ...klassesQueryKeys.get(Number(klassId)),
  });

  const { status, slug, version } = coursePath || ({} as CoursePath);
  const courseAbbreviation = coursePath?.course.abbreviation ?? '';
  const courseTitle = `${courseAbbreviation} - ${t('courses')}`;
  const title = klass
    ? `${translateKlassName(klass.name)} - ${t('courses')}`
    : courseTitle;

  const closeModal = (needReload: boolean = true) => {
    setModalOpen('none');
    needReload && setReload(!reload);
  };

  const onSelectKlass = (klass: Klass) => {
    if (handleKlassChange(klass)) {
      navigate(`/courses/${klass.coursePathSlug}/class/${klass.id}`);
    }
  };
  const onDeselectKlass = () => navigate(`/courses/${slugCourseName}`);

  const checkEmptyActivities = async () => {
    const { results: versioningLessons } = await listLessonsByCourseSlug(
      slugCourseName,
    );
    const emptyActivities = await getEmptyActivities({ slugCourseName });

    const hasEmptyActivities = !!emptyActivities.length;

    if (hasEmptyActivities) {
      const emptyActivitiesAux: EmptyActivityWithLesson[] = [];
      versioningLessons?.forEach(versioningLesson => {
        const filterEmptyActivity = emptyActivities.filter(({ lesson }) =>
          lesson.includes(versioningLesson.id),
        );
        if (filterEmptyActivity && filterEmptyActivity.length) {
          filterEmptyActivity.map(activity =>
            emptyActivitiesAux.push({
              ...activity,
              lessonInfo: versioningLesson,
            }),
          );
        }
      });
      setEmptyActivities(emptyActivitiesAux);
    }
    setModalOpen(hasEmptyActivities ? 'activity' : 'beta');
  };

  const requestDeleteActivities = async (
    emptyActivities: EmptyActivityWithLesson[],
  ) => {
    setIsLoadingDeleteActivities(true);
    const [request] = await Promise.allSettled(
      emptyActivities.map(({ lessonInfo: { id: lessonId }, id: activityId }) =>
        deleteActivity({
          activityId,
          lessonId,
        }),
      ),
    );

    if (request.status === 'rejected') {
      alert.error(tErrors('serverError'));
    } else {
      setIsLoadingDeleteActivities(false);
      setModalOpen('none');
    }
  };

  function CourseStatusActionsHandler() {
    switch (status) {
      case VersioningStatusEnum.BETA:
        return <BetaActions />;
      case VersioningStatusEnum.EDITING:
        return <EditActions />;
      case VersioningStatusEnum.VERSIONING:
        return <VersioningActions />;
      case VersioningStatusEnum.PUBLISHED:
        return <PublishedActions />;
      default:
        return <div className="h-7 content-none" />;
    }
  }

  function PublishedActions() {
    return (
      <React.Fragment>
        <MainButton
          className="max-w-none md:max-w-none"
          onClick={handleVersioning}
          icon={<BranchIcon className="w-4 h-4" />}
          loading={activeRequest === 'version'}
          disabled={activeRequest !== 'none'}
          text={t('newVersion')}
          color="secondary"
        />
        <MainButton
          className="max-w-none md:max-w-none"
          onClick={handleEditingMode}
          disabled={activeRequest !== 'none'}
          loading={activeRequest === 'edit'}
          icon={<PencilIcon strokeWidth={1.4} />}
          text={t('editCourse')}
        />
      </React.Fragment>
    );
  }

  function EditActions() {
    return (
      <React.Fragment>
        <MainButton
          className="max-w-none md:max-w-none"
          onClick={() => setModalOpen('edit-publish')}
          icon={<UploadIcon className="w-4 h-4" />}
          disabled={activeRequest !== 'none'}
          text={t('publish')}
          color="secondary"
        />
        <MainButton
          dataTestId="backEditingButton"
          className="max-w-none md:max-w-none"
          onClick={handleEditingMode}
          disabled={activeRequest !== 'none'}
          loading={activeRequest === 'version'}
          icon={<PencilIcon strokeWidth={1.4} />}
          text={t('backEditing')}
        />
      </React.Fragment>
    );
  }

  function BetaActions() {
    return (
      <React.Fragment>
        <MainButton
          dataTestId="publishButton"
          className="max-w-none md:max-w-none"
          onClick={() => setModalOpen('beta-publish')}
          icon={<UploadIcon className="w-4 h-4" />}
          disabled={activeRequest !== 'none'}
          text={t('publish')}
          color="secondary"
        />
        <MainButton
          dataTestId="enableEditingButton"
          className="max-w-none md:max-w-none"
          onClick={() => setModalOpen('edit')}
          disabled={activeRequest !== 'none'}
          loading={activeRequest === 'version'}
          icon={<PencilIcon strokeWidth={1.4} />}
          text={t('enableEditing')}
        />
      </React.Fragment>
    );
  }

  function VersioningActions() {
    return (
      <React.Fragment>
        <MainButton
          className="max-w-none md:max-w-none"
          onClick={() => checkEmptyActivities()}
          icon={<UploadIcon className="w-4 h-4" />}
          loading={activeRequest === 'version'}
          disabled={activeRequest !== 'none'}
          text={t('finish')}
          color="secondary"
        />
        <MainButton
          dataTestId="backEditingButton"
          className="max-w-none md:max-w-none"
          onClick={handleVersioning}
          disabled={activeRequest !== 'none'}
          loading={activeRequest === 'version'}
          icon={<PencilIcon strokeWidth={1.4} />}
          text={t('backEditing')}
        />
      </React.Fragment>
    );
  }

  async function handleVersioning() {
    try {
      setActiveRequest('version');
      await allowVersioningMode(coursePath!, version! <= 1.0);
    } catch (error: any) {
      const api = new ApiError(error);
      alert.error(api.getErrorMessage());
    } finally {
      setActiveRequest('none');
    }
  }
  async function handleEditingMode() {
    try {
      setActiveRequest('edit');
      await allowEditingMode(coursePath!);
    } catch (error: any) {
      const api = new ApiError(error);
      alert.error(api.getErrorMessage());
    } finally {
      setActiveRequest('none');
    }
  }

  if (!coursePath && isError) {
    return (
      <ErrorComponent
        errorTextSubTitle={t('errorTextSubTitle')}
        errorTextTitle={t('errorTextTitle')}
      />
    );
  }

  return (
    <Layout>
      <HeadTitle routeInfo={title} />
      <div className="flex items-center gap-10">
        <Link
          to="/courses"
          className="hover:opacity-75 transition-all ease-in-out duration-150"
        >
          <ChevronLeftIcon className="w-8 h-8 text-primary" />
        </Link>
        <Heading3 text={t('viewCourse')} className="text-primary" />
      </div>

      <PublishEditModal
        onClose={closeModal}
        slug={slug}
        visible={modalOpen === 'edit-publish'}
      />
      <EnableEditModal
        course={coursePath!}
        onClose={() => closeModal(false)}
        visible={modalOpen === 'edit'}
      />
      <PublishBetaModal
        onConfirm={() => courseToPublish(coursePath!.slug)}
        onClose={closeModal}
        visible={modalOpen === 'beta-publish'}
      />
      <BetaModal
        onClose={closeModal}
        slug={slug}
        visible={modalOpen === 'beta'}
      />

      <div className="flex flex-col gap-4">
        <div className="grid md:grid-cols-2 gap-4 pt-4">
          <InfiniteSearchInput
            selectedItem={selectedCoursePath}
            service={coursePathsQueryKeys.list}
            displayName={coursePath =>
              `${coursePath.course.name} v${coursePath.version}`
            }
            onSelect={coursePath => {
              navigate(`/courses/${coursePath.slug}`);
              setSelectedCoursePath(coursePath);
            }}
            filters={{ course: coursePath?.course.id }}
            options={{ enabled: !!coursePath }}
            onDeselect={() => setSelectedCoursePath(undefined)}
            input={{ className: { base: 'md:w-96 font-500' } }}
          />

          <div className="flex items-center justify-end gap-4">
            <ComponentGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
              {coursePath && <CourseStatus version={version} status={status} />}
              <CourseStatusActionsHandler />
            </ComponentGuard>
            <ComponentGuard
              roles={[UserTypeEnum.UNIT_ADMIN, UserTypeEnum.TEACHER]}
            >
              <ExtraLessonsOptions klassId={Number(klassId)} />

              <InfiniteSearchInput
                service={klassesQueryKeys.list}
                filters={{ unitId: getAuthorizedUnits(authUser) }}
                displayName={klass => translateKlassName(klass.name)}
                selectedItem={klass}
                onSelect={onSelectKlass}
                onDeselect={onDeselectKlass}
                input={{
                  className: { base: 'w-full md:w-72 lg:w-96 font-500' },
                }}
              />
            </ComponentGuard>
          </div>
        </div>

        <LessonListManager
          klassId={Number(klassId)}
          slugCourseName={slugCourseName}
        />
        <LegalInformationCourseCollapse course={coursePath} isViewMode />
      </div>

      <ModalEmptyActivity
        isRequesting={isLoadingDeleteActivities}
        isVisible={modalOpen === 'activity'}
        emptyActivities={emptyActivities}
        onClickConfirm={async () => {
          setIsLoadingDeleteActivities(true);
          await requestDeleteActivities(emptyActivities);
          setModalOpen('beta');
        }}
        onClickCancel={closeModal}
      />
    </Layout>
  );
}

type ExtraLessonsOptionsProps = {
  klassId: number;
};

function ExtraLessonsOptions({ klassId }: ExtraLessonsOptionsProps) {
  const hasKlassId = Boolean(klassId);

  const { t } = useTranslation('translation', {
    keyPrefix: 'lesson.manage',
  });

  const [modalOpen, setModalOpen] = useState<'extraLesson' | 'lessonBank'>();
  const [lesson, setLesson] = useState<Lesson>();
  const navigate = useNavigate();
  const goToKlassLessons = () => navigate(`/class/${klassId}/lessons`);
  const closeModal = () => setModalOpen(undefined);
  const onUse = async (lesson: Lesson) => {
    setLesson(lesson);
    setModalOpen('extraLesson');
  };
  const onError = (error: ApiError, scheduledLessonId: number) => {
    if (error.codes.includes('lesson_type_repeated_error')) {
      deleteScheduledLesson({ klassId, scheduledLessonId });
      setModalOpen('lessonBank');
      setTimeout(() => alert.warning('Escolha outra lição'), 2000);
    } else closeModal();
  };

  const queryClient = useQueryClient();
  const queryKey = scheduledLessonsQueryKeys.list({ klassId }).queryKey;

  const updateScheduledLessons = async () => {
    setModalOpen(undefined);
    await queryClient.invalidateQueries(queryKey);
    goToKlassLessons();
  };

  function ActionCards() {
    return (
      <Fragment>
        <ConditionalRenderer condition={hasKlassId}>
          <div className="flex items-start flex-col gap-1.5">
            <ActionDetailCard
              title={t('addLesson.title')}
              details={t('addLesson.details')}
              icon={PlusIcon}
              onClick={() => setModalOpen('extraLesson')}
            />
            <ActionDetailCard
              onClick={() => setModalOpen('lessonBank')}
              title={t('bankLesson.title')}
              details={t('bankLesson.details')}
              icon={CollectionIcon}
            />
            <ActionDetailCard
              onClick={goToKlassLessons}
              title={t('editLesson.title')}
              details={t('editLesson.details')}
              icon={PencilIcon}
            />
          </div>
        </ConditionalRenderer>
        <ConditionalRenderer condition={!hasKlassId}>
          <Text
            testId="tooltipalert"
            text={t('selectKlass')}
            className="text-primary"
          />
        </ConditionalRenderer>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <ConditionalRenderer condition={klassId}>
        {klassId && (
          <Fragment>
            <AddExtraLessonModal
              isVisible={modalOpen === 'extraLesson'}
              onCancel={closeModal}
              klassId={+klassId}
              onError={onError}
              lesson={lesson}
              updateScheduledLessons={updateScheduledLessons}
            />
            <ModalBank
              visible={modalOpen === 'lessonBank'}
              initial="lessons"
              onUseLesson={onUse}
              onClose={closeModal}
            />
          </Fragment>
        )}
      </ConditionalRenderer>
      <Tooltip
        color="white"
        classNameContainer="flex items-center justify-center h-full"
        options={{
          placement: 'auto',
          interactive: true,
          delayHide: 500,
        }}
        text={<ActionCards />}
      >
        <CogIcon
          data-testid="engineIcon"
          className={`w-4 h-4 ${!hasKlassId ? 'opacity-20' : ''} text-primary`}
        />
      </Tooltip>
    </Fragment>
  );
}
