import ListTeachers from '@/components/admin/teachers/ListTeachers';
import TeacherForm from '@/components/admin/teachers/TeacherForm';
import AdminButton from '@/components/common/AdminButton';
import ComponentGuard from '@/components/common/ComponentGuard';
import Dropdown from '@/components/common/filter/Dropdown';
import HeadTitle from '@/components/common/HeadTitle';
import TableStatistics from '@/components/common/table/TableStatistics';
import TableSearchIcon from '@/components/common/TableSearchIcon';
import TableSortIcons from '@/components/common/TableSortIcons';
import Layout from '@/components/template/Layout';
import { MANAGEMENT_PAGE_SIZE } from '@/constants';
import useAuth from '@/data/hook/useAuth';
import useStatisticService from '@/data/hook/useStatistics';
import {
  accessCountQueryKeys,
  teachersQuerykeys,
  usersCountQueryKeys,
} from '@/data/services/querykeys';
import {
  ListUsersFilters,
  orderingUserAccess,
} from '@/data/services/userServices';
import { isSuperAdmin, isUnitAdmin } from '@/functions/auth';
import { UserStatusEnum, UserTypeEnum } from '@/models/User';
import { accordion } from '@/utils/animations/commom';
import { TeacherHeader } from '@/utils/HeaderTypes';
import useFilterParams from '@/utils/UseFilterParams';
import { useQuery } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import NoAdminScreenForMobile from './NoAdminScreenForMobile';
import PaginationComponent from './PaginationComponent';

export default function ManageTeacher() {
  const { user } = useAuth();
  const { t } = useTranslation('translation', { keyPrefix: 'manageTeacher' });
  const [isSearch, setIsSearch] = useState(false);
  const { actionId, username: teacherUsername } = useParams();
  const [isAddTeacher, setIsAddTeacher] = useState(actionId === 'add');
  const { filterParams, setFilterParams } = useFilterParams<ListUsersFilters>();

  const unit = filterParams.getAll('unit').map(Number) ?? undefined;
  const status = filterParams.getAll('status') ?? undefined;
  const pageSize = MANAGEMENT_PAGE_SIZE;
  const pageNumber = Number(filterParams.get('pageNumber') ?? '1');
  const search = filterParams.get('search') ?? undefined;
  const ordering =
    (filterParams.get('ordering') as orderingUserAccess) ?? undefined;

  const params = {
    unit,
    pageNumber,
    pageSize,
    search,
    ordering,
    status,
  };

  const { queryKey: teachersQueryKey, queryFn: teachersQueryFn } =
    teachersQuerykeys.list(params);

  const {
    data: teachersPage,
    isInitialLoading: isLoadingTeachers,
    isPreviousData,
  } = useQuery({
    keepPreviousData: true,
    queryFn: teachersQueryFn,
    queryKey: teachersQueryKey,
  });

  const isMultipleUnits = user && user.unitsIds?.length > 1;

  const titles: TeacherHeader = {
    name: {
      title: t('listUsers.name'),
      extraFunction: [
        <TableSearchIcon onClick={() => setIsSearch(!isSearch)} />,
      ],
      size: 25,
    },
    students: {
      title: t('listUsers.students'),
      size: 10,
    },
    churn: {
      title: t('listUsers.churn'),
      size: 8,
    },
    presence: {
      title: t('listUsers.presence'),
      size: 10,
    },
    lastAccess: {
      title: t('listUsers.lastAccess'),
      size: 12,
      extraFunction: [
        <TableSortIcons
          onClickSort={value => {
            const orderBy = value === 'ASC' ? '-last_login' : 'last_login';
            setOrderingParams(orderBy);
          }}
        />,
      ],
    },
    unit: {
      title: t('listUsers.unit'),
      size: 10,
      extraFunction: [
        <Dropdown filter={unit?.map(String) ?? []} type="unit" />,
      ],
      roles: [UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN],
      blockRender: true,
      blockCondition: isUnitAdmin(user?.userType) && !isMultipleUnits,
    },
    status: {
      title: t('listUsers.status'),
      size: 6,
    },
    actions: {
      title: t('listUsers.actions'),
      size: 5,
    },
  };
  const setOrderingParams = (orderBy: orderingUserAccess) => {
    setFilterParams({ ordering: orderBy, pageNumber: 1 });
  };

  const changePages = (numberOfPage: string) => {
    setFilterParams({ pageNumber: Number(numberOfPage) });
  };

  const statisticsFilters = {
    userType: UserTypeEnum.TEACHER,
    unitId: isSuperAdmin(user?.userType) ? undefined : user?.unitsIds,
    status: UserStatusEnum.REGISTRED,
  };

  const {
    statistic: teacherStatistics,
    isInitialLoading: isLoadingTeacherStatistics,
  } = useStatisticService({
    keepPreviousData: true,
    ...accessCountQueryKeys.list(statisticsFilters),
  });
  const { statistic: totalTeachers, isInitialLoading: isLoadingTotalTeachers } =
    useStatisticService({
      keepPreviousData: true,
      ...usersCountQueryKeys.list(statisticsFilters),
    });

  const statisticsCards = [
    {
      title: t('quantity'),
      info: totalTeachers?.usersCount || 0,
      isLoading: isLoadingTotalTeachers,
    },
    {
      title: t('accesses'),
      info: teacherStatistics?.thirtyDaysCount || 0,
      isLoading: isLoadingTeacherStatistics,
    },
    {
      title: t('weeklyAccesses'),
      info: teacherStatistics?.sevenDaysCount || 0,
      isLoading: isLoadingTeacherStatistics,
    },
  ];

  return (
    <React.Fragment>
      <HeadTitle routeInfo={t('teachers')} />
      <div className="md:hidden">
        <NoAdminScreenForMobile />
      </div>

      <div className="hidden md:block w-full">
        <Layout className="gap-2 flex flex-col">
          <TableStatistics
            headingText={t('title')}
            statisticsCards={statisticsCards}
            adminButton={
              <AdminButton
                title={t('add')}
                onClick={() => setIsAddTeacher(old => !old)}
                open={isAddTeacher}
              />
            }
          />
          <ComponentGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
            <AnimatePresence>
              {isAddTeacher && (
                <motion.div className="flex justify-end" {...accordion}>
                  <TeacherForm
                    formMode="create"
                    className="py-2 w-[600px]"
                    onCancel={() => setIsAddTeacher(false)}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </ComponentGuard>
          <ListTeachers
            isLoading={isLoadingTeachers || isPreviousData}
            titles={titles}
            teachersList={teachersPage?.results || []}
            searchTeacher={teacherUsername}
            isSearch={isSearch}
          />
          <PaginationComponent
            amountPages={teachersPage?.meta.pagination.pages || 1}
            changePages={changePages}
            paramsPage={pageNumber}
            amountOf={teachersPage?.meta.pagination.count || 0}
            managerType="teachers"
          />
        </Layout>
      </div>
    </React.Fragment>
  );
}
