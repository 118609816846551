import DateInput from '@/components/common/dataInput/DateInput';
import Input from '@/components/common/dataInput/TextInput';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import { updateUserProfile } from '@/data/services/userProfileServices';
import { createUser, updateUser } from '@/data/services/userServices';
import { ApiError } from '@/models/Errors';
import Rewards from '@/models/Rewards';
import User, { UserForm as UserFormType, UserTypeEnum } from '@/models/User';
import UserProfile from '@/models/UserProfile';
import alert from '@/utils/UseAlert';
import { buildChangedObject } from '@/utils/buildChangedObject';
import {
  extractCityAndState,
  returnLocationString,
} from '@/utils/placesLocationUtils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { every, isEmpty, some } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import SaveCancelGroup from '@/components/common/buttons/SaveCancelGroup';
import { IconByStatus } from '@/components/common/dataDisplay/IconByUserStatus';
import PasswordAndConfirmInput from '@/components/common/dataInput/PasswordAndConfirmInput';
import PlacesAutoCompleteInput from '@/components/common/dataInput/PlacesAutoCompleteInput';
import UsernameInput from '@/components/common/dataInput/UsernameInput';
import useAuth from '@/data/hook/useAuth';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import { RenderRewards } from '@/components/common/dataDisplay/RewardsView';
import { HistoricTagGroup } from '@/components/historic/HistoricCourseTag';
import Text from '@/components/common/dataDisplay/Text';
import useInfiniteService from '@/data/hook/useInfiniteService';
import {
  simplifiedCourseProgressesQueryKeys,
  unitsQueryKeys,
  studentsQuerykeys,
  userProfilesQueryKeys,
} from '@/data/services/querykeys';

interface UserFormProps {
  user?: User;
  profile?: UserProfile;
  formMode?: 'create' | 'edit';
  disabled?: boolean;
  className?: string;
  onCancel?(): void;
  rewards?: Rewards;
  isLoadingRewards?: boolean;
}

export default function StudentForm(props: UserFormProps) {
  const {
    user = {} as User,
    profile = {} as UserProfile,
    disabled,
    formMode = 'edit',
    className,
    onCancel,
    rewards,
    isLoadingRewards,
  } = props;

  const { user: authUser } = useAuth();
  const [hasAlter, setHasAlter] = useState<boolean>(false);

  const { results: progress, isInitialLoading: isLoadingProgress } =
    useInfiniteService({
      ...simplifiedCourseProgressesQueryKeys.list(user.id)._ctx.infinity,
      enabled: formMode === 'edit' && !!user,
    });

  const { t } = useTranslation('translation', {
    keyPrefix: 'manageStudent.addUser',
  });

  const { t: tStatus } = useTranslation('translation', {
    keyPrefix: 'manageStudent.status',
  });

  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm<UserFormType>({
    defaultValues: {
      ...user,
      ...profile,
      dateOfBirth: profile?.dateOfBirth,
      location: returnLocationString(profile?.city, profile?.state),
    },
  });

  const blockChanges = some(errors, error => !!error?.message);

  const onEdit = async ({
    firstName,
    lastName,
    username,
    userType,
    location,
    dateOfBirth,
    phoneNumber,
    email,
  }: UserFormType) => {
    if (!user) throw new Error('User not found');
    const { city, state } = extractCityAndState(location);

    const parcialProfile = buildChangedObject<UserProfile>(profile, {
      city,
      state,
      phoneNumber,
      dateOfBirth: dateOfBirth
        ? moment(dateOfBirth).format('YYYY-MM-DD')
        : undefined,
    });

    const parcialUser = buildChangedObject<User>(user, {
      firstName,
      lastName,
      username,
      userType,
      email,
    });

    !every(parcialUser, isEmpty) && (await updateUser(user.id, parcialUser));

    !every(parcialProfile, isEmpty) &&
      (await updateUserProfile(profile.id, parcialProfile));
  };

  const { mutate: edit, isLoading: isUpdating } = useMutation(onEdit, {
    onSuccess: () => {
      alert.success(t('successEditUser'));
      queryClient.invalidateQueries(userProfilesQueryKeys.get(user.username));
      queryClient.invalidateQueries(studentsQuerykeys.list._def);
      onCancel?.();
    },
    onError: (error: any) => {
      const apiError = new ApiError(error);

      alert.error(apiError.getErrorMessage());
    },
  });

  const onCreate = async ({
    location,
    dateOfBirth,
    phoneNumber,
    ...user
  }: UserFormType) => {
    const { city, state } = extractCityAndState(location);

    await createUser({
      ...user,
      userType: UserTypeEnum.STUDENT,
      profile: {
        city,
        state,
        phoneNumber: phoneNumber || undefined,
        dateOfBirth: dateOfBirth
          ? moment(dateOfBirth).format('YYYY-MM-DD')
          : undefined,
      },
    });
  };

  const { mutate: create, isLoading: isCreating } = useMutation(onCreate, {
    onSuccess: () => {
      alert.success(t('successCreateUser'));
      queryClient.invalidateQueries(studentsQuerykeys.list._def);
      onCancel?.();
    },
    onError: (error: any) => {
      const apiError = new ApiError(error);
      alert.error(apiError.getErrorMessage());
    },
  });

  const onSubmit = async (data: UserFormType) => {
    formMode === 'create' ? create(data) : edit(data);
    setHasAlter(false);
  };

  const onReset = () => {
    reset({
      ...profile,
      ...user,
      dateOfBirth: profile?.dateOfBirth,
      location: returnLocationString(profile?.city, profile?.state),
    });
    setHasAlter(false);
    onCancel?.();
  };

  useEffect(() => {
    const subscription = watch(() => setHasAlter(true));
    return () => subscription.unsubscribe();
  }, [watch, setHasAlter]);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`flex flex-col gap-4 pb-2 ${className || ''}`}
    >
      <ConditionalRenderer condition={formMode === 'create'}>
        <Controller
          name="unitsIds"
          control={control}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <InfiniteSearchInput
              service={unitsQueryKeys.list}
              displayName={unit => unit.name}
              onSelect={({ id }) => {
                const units = [id];
                onChange(units);
              }}
              filters={{
                idIn: getAuthorizedUnits(authUser)?.toString(),
              }}
              input={{
                label: t('unit'),
                errorLabelText: error?.message,
                labelPosition: 'left',
                disabled,
                testId: 'selectUnit',
                className: { base: 'font-500 text-16' },
              }}
              onDeselect={() => onChange(null)}
            />
          )}
          rules={{
            required: formMode === 'create' ? t('errorMessageUnit') : undefined,
          }}
        />
      </ConditionalRenderer>
      <Input
        label={t('name')}
        testId="name"
        register={register('firstName', {
          required: t('errorMessageName'),
        })}
        disabled={disabled}
        errorLabelText={errors.firstName?.message}
        labelPosition="left"
        className={{ base: 'font-500 text-16' }}
      />
      <Input
        label={t('lastName')}
        testId="lastName"
        register={register('lastName', {
          required: t('errorMessageLastName'),
        })}
        disabled={disabled}
        errorLabelText={errors.lastName?.message}
        labelPosition="left"
        className={{ base: 'font-500 text-16' }}
      />

      <Controller
        control={control}
        name="dateOfBirth"
        render={({ field: { onChange, value } }) => (
          <DateInput
            label={t('birth')}
            labelPosition="left"
            testId="birth"
            value={value}
            onDateChange={onChange}
            disabled={disabled}
            className={{ base: 'font-500 text-16' }}
          />
        )}
      />

      <UsernameInput
        control={control}
        user={user ?? undefined}
        disabled={disabled}
        labelPosition="left"
        required
        className={{ base: 'font-500 text-16' }}
      />

      <Input
        label={t('email')}
        testId="email"
        register={register('email', {
          required: t('errorMessageEmail'),
        })}
        type="email"
        disabled={disabled}
        errorLabelText={errors.email?.message}
        labelPosition="left"
        className={{ base: 'font-500 text-16' }}
      />

      <PlacesAutoCompleteInput
        disabled={disabled}
        testId="city"
        placeholder={disabled ? '' : t('selectCity')}
        label={t('city')}
        labelPosition="left"
        control={control}
        name="location"
        className={{ base: 'font-500 text-16' }}
      />
      <ConditionalRenderer condition={formMode === 'create'}>
        <PasswordAndConfirmInput
          control={control}
          disabled={disabled}
          labelPosition="left"
          className={{ base: 'font-500 text-16' }}
        />
      </ConditionalRenderer>
      <ConditionalRenderer condition={formMode === 'edit'}>
        <RenderKlass user={user} />

        <div className="flex gap-2">
          <Text
            text={t('status')}
            className="font-500 self-center shrink-0 text-left w-28"
          />
          <span className="flex gap-2 items-center w-full px-3">
            <IconByStatus status={user.status} />
            {tStatus(user.status)}
          </span>
        </div>

        <RenderRewards
          rewardsData={rewards}
          isFetchingRewards={isLoadingRewards}
        />
        <HistoricTagGroup progress={progress} isLoading={isLoadingProgress} />
      </ConditionalRenderer>

      <ConditionalRenderer condition={!disabled}>
        <SaveCancelGroup
          loading={isUpdating || isCreating}
          cancel={{
            onClick: onReset,
          }}
          save={{
            disabled: !hasAlter || blockChanges,
          }}
        />
      </ConditionalRenderer>
    </form>
  );
}

const RenderKlass = ({ user }: { user: User }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageStudent.addUser',
  });

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-2 w-full">
        <Text
          text={t('klass')}
          className="font-500 self-start text-left w-28"
        />
        <div className="flex flex-col gap-1">
          {user?.klasses?.map(klass => (
            <Text
              key={klass}
              text={klass}
              className="flex gap-2 items-center w-full px-3"
            />
          ))}
        </div>
      </div>
    </div>
  );
};
