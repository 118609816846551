import { useTranslation } from 'react-i18next';
import Text from '../../common/dataDisplay/Text';
import { handleUserFullName } from '@/functions/handleUserFullName';
import DateInput from '../../common/dataInput/DateInput';
import SaveCancelGroup from '../../common/buttons/SaveCancelGroup';
import moment from 'moment';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import TimeInput from '../../common/dataInput/TimeInput';
import { useState } from 'react';
import Tag from '../../common/dataDisplay/Tag';
import { XIcon } from '@heroicons/react/outline';
import TextInput from '../../common/dataInput/TextInput';
import alert from '@/utils/UseAlert';
import ComponentGuard from '../../common/ComponentGuard';
import { UserTypeEnum } from '@/models/User';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getUnitRecessCalendar } from '@/data/services/unitServices';
import { createScheduledDay } from '@/data/services/scheduledDayServices';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import TooltipHandler from '@/components/common/TooltipHandler';
import useAuth from '@/data/hook/useAuth';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import { unitsQueryKeys, usersQueryKeys } from '@/data/services/querykeys';

interface IInputs {
  date: Date | string | undefined;
  time: string | undefined;
  teacher: string;
  seats: number;
  unitId: number;
}

interface AddTrialLessonProps {
  setOpenForm: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AddTrialLesson({ setOpenForm }: AddTrialLessonProps) {
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const {
    setValue,
    getValues,
    register,
    handleSubmit,
    watch,
    control,
    resetField,
  } = useForm<IInputs>({ defaultValues: { unitId: user?.unitsIds?.[0] } });
  const [dateArray, setDateArray] = useState<Date[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleAddDate = (newDate: Date) => {
    setDateArray(prevDateArray => [...prevDateArray, newDate]);
  };
  const handleRemoveDate = (indexToRemove: number) => {
    setDateArray(prevDateArray =>
      prevDateArray.filter((_, index) => index !== indexToRemove),
    );
  };
  const { t } = useTranslation('translation', {
    keyPrefix: 'manageTrialLesson.addLesson',
  });
  const { t: tErrors } = useTranslation('translation', {
    keyPrefix: 'errors',
  });

  const handleBeforeClose = () => {
    const valueDate = getValues('date');
    const valueTime = getValues('time');
    if (valueDate && valueTime) {
      const formatDate = moment(valueDate).format('DD/MM/YYYY');
      handleAddDate(
        moment(`${formatDate} ${valueTime}`, 'DD/MM/YYYY HH:mm').toDate(),
      );
    }
  };

  const onSubmit: SubmitHandler<IInputs> = async ({
    teacher,
    seats,
    unitId,
  }) => {
    if (!dateArray.length) {
      alert.error(t('notTrialLesson'));
      return;
    }
    setIsSubmitting(true);
    try {
      await Promise.all(
        dateArray.map(date =>
          createScheduledDay({
            dateTime: date,
            teacher: Number(teacher),
            seats,
            unitId,
          }),
        ),
      );
      alert.success(t('successMessage'));
      setDateArray([]);
      resetField('date');
      queryClient.invalidateQueries(['trialLessonList']);
    } catch {
      alert.error(tErrors('serverError'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const unitId = watch('unitId');
  const { data: recessCalendar, isFetching: isFetchingCalendar } = useQuery(
    ['recessCalendar', unitId],
    async () => getUnitRecessCalendar(Number(unitId)),
    {
      enabled: !!unitId,
    },
  );

  const disabledDates = recessCalendar?.recess?.map(date =>
    moment(date).toDate(),
  );

  return (
    <div className="flex w-[29rem] flex-col gap-2 my-6 items-end">
      <ComponentGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
        <Controller
          name="unitId"
          control={control}
          render={({ field: { onChange } }) => (
            <InfiniteSearchInput
              service={unitsQueryKeys.list}
              filters={{ idIn: getAuthorizedUnits(user)?.toString() }}
              displayName={unit => unit.name}
              onSelect={({ id }) => onChange(id)}
              className={{
                input: 'w-full rounded-md placeholder:font-400 max-w-2xl',
              }}
              input={{
                label: t('units'),
                labelPosition: 'left',
                testId: 'unitSelect',
                className: { base: 'w-96' },
              }}
              onDeselect={() => onChange(null)}
            />
          )}
        />
      </ComponentGuard>

      <div
        className={`${
          !!unitId || isFetchingCalendar ? '' : 'disabled'
        } flex flex-col gap-2 w-[29rem] items-end transition ease-in-out duration-300`}
      >
        <TooltipHandler
          renderTooltip={dateArray.length > 0}
          tooltipMessage={t('canotChangeTeacher')}
        >
          <Controller
            name="teacher"
            control={control}
            render={({ field: { onChange } }) => (
              <InfiniteSearchInput
                service={usersQueryKeys.list}
                options={{ enabled: !!unitId }}
                filters={{
                  userType: [UserTypeEnum.TEACHER],
                  unit: unitId ? [unitId] : undefined,
                }}
                displayName={handleUserFullName}
                onSelect={({ id }) => onChange(id)}
                className={{
                  input: 'w-full rounded-md placeholder:font-400 max-w-2xl',
                }}
                input={{
                  label: t('teacher'),
                  testId: 'trialLessonTeacher',
                  labelPosition: 'left',
                  className: { base: 'w-96' },
                  disabled: dateArray.length > 0,
                }}
                onDeselect={() => onChange(null)}
              />
            )}
          />
        </TooltipHandler>
        <div className="flex flex-row gap-12 w-[29rem] items-center">
          <Text text={t('seats')} format="rubik-500" />
          <TextInput
            autoComplete="off"
            dataTestId="seatsInput"
            register={register('seats', {
              valueAsNumber: true,
              required: true,
              onChange(e) {
                const value = e.target.value;
                e.target.value = value.replace(/[^0-9]/g, '');
              },
            })}
            className={{ base: 'bg-base-100 w-96' }}
          />
        </div>
        <div className="flex flex-row gap-1.5 w-fit items-center">
          <Text text={t('dates')} format="rubik-500" />
          <DateInput
            disabledDates={disabledDates}
            autoComplete="off"
            className={{ base: 'w-36' }}
            onDateChange={dateChange => {
              setValue('date', dateChange);
            }}
            testId="dateInput"
          />
          <TimeInput
            defaultSelected="--:--"
            autoComplete="off"
            isClearAfter
            className={{ base: 'w-24' }}
            testId="timeInput"
            beforeClose={handleBeforeClose}
            onTimeChange={timeChange => {
              setValue('time', timeChange);
            }}
          />
        </div>
        <div className="flex flex-wrap max-w-md gap-1.5">
          {dateArray.map((date, index) => {
            return (
              <Tag
                key={index}
                color="custom"
                text={moment(date).format('DD/MM HH:mm')}
                icon={
                  <XIcon
                    onClick={() => handleRemoveDate(index)}
                    className="w-4 h-4 flex cursor-pointer"
                  />
                }
                className="flex-row-reverse text-base-100 items-center w-fit gap-2 bg-primary rounded-full py-1.5 px-2.5"
              />
            );
          })}
        </div>
      </div>
      <SaveCancelGroup
        loading={isSubmitting}
        cancel={{
          onClick: () => setOpenForm(false),
          testId: 'cancelButtontrialLesson',
        }}
        save={{
          onClick: handleSubmit(onSubmit),
          testId: 'saveButtontrialLesson',
          disabled: dateArray.length <= 0,
        }}
      />
    </div>
  );
}
