import { EnrollmentStatusEnum } from '@/models/Enrollment';
import {
  ActiveEnum,
  EnrollmentAndReportStatus,
  TranferredEnum,
} from '@/models/ScheduledLessonReport';
import { ScheduledLessonReportEnum } from '@/models/ScheduledLessonReport';

export type StudentReportStatusProps = {
  enrollmentStatus: EnrollmentStatusEnum;
  reportStatus?: ScheduledLessonReportEnum;
};

export const studentReportStatus = ({
  enrollmentStatus,
  reportStatus,
}: StudentReportStatusProps): EnrollmentAndReportStatus => {
  const activated =
    enrollmentStatus === EnrollmentStatusEnum.ACTIVE &&
    (reportStatus === ScheduledLessonReportEnum.ACTIVE || !reportStatus);

  const courseTransferred =
    enrollmentStatus === EnrollmentStatusEnum.COURSE_TRANSFERRED;

  const classTransferred =
    enrollmentStatus === EnrollmentStatusEnum.CLASS_TRANSFERRED;

  const transferedToClass =
    enrollmentStatus === EnrollmentStatusEnum.ACTIVE &&
    reportStatus === ScheduledLessonReportEnum.NOT_ENROLLED;

  const activeInThisLesson =
    [
      EnrollmentStatusEnum.CANCELED,
      EnrollmentStatusEnum.COURSE_TRANSFERRED,
    ].includes(enrollmentStatus) &&
    reportStatus &&
    [
      ScheduledLessonReportEnum.ACTIVE,
      ScheduledLessonReportEnum.TRANSFERED,
    ].includes(reportStatus);

  const canceledThisLesson =
    enrollmentStatus === EnrollmentStatusEnum.CANCELED &&
    (reportStatus === ScheduledLessonReportEnum.CANCELED || !reportStatus);

  const awaitingPayment =
    enrollmentStatus === EnrollmentStatusEnum.AWAITING_PAYMENT && !reportStatus;

  const formed = enrollmentStatus === EnrollmentStatusEnum.CONCLUDED;

  if (activated) return EnrollmentStatusEnum.ACTIVE;
  else if (activeInThisLesson) return ActiveEnum.ACTIVE_IN_THIS_LESSON;
  else if (courseTransferred) return EnrollmentStatusEnum.COURSE_TRANSFERRED;
  else if (classTransferred) return TranferredEnum.TRANSFERRED_FROM_CLASS;
  else if (transferedToClass) return TranferredEnum.TRANSFERRED_TO_CLASS;
  else if (canceledThisLesson) return EnrollmentStatusEnum.CANCELED;
  else if (awaitingPayment) return EnrollmentStatusEnum.AWAITING_PAYMENT;
  else if (formed) return EnrollmentStatusEnum.CONCLUDED;
  else return EnrollmentStatusEnum.CANCELED;
};
