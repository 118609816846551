import User from './User';

export default interface Klass {
  classRoomUrl: string;
  coursePathSlug: string;
  dayTimes: DayTimes[];
  duration: number;
  enrollsCount: number;
  id: number;
  klassEndDate: Date;
  klassStartDate: Date;
  name: string;
  status: KlassStatusEnum;
  teacherId: number;
  unitId: number;
  maxCapacity: number;
  conectaSeats: number;
  klassType: KlassTypeEnum;
  coursePathId: number;
}

export type KlassPayload = Partial<
  Omit<Klass, 'id' | 'enrollments' | 'klassStartDate'>
> & {
  klassStartDate: string;
  courseId: number;
};

export interface DayTimes {
  day: DaysOfWeekTypeEnum;
  time: string;
}

export interface Teacher extends User {
  fullName: string;
  studentsChurn: number;
  studentsCount: number;
  studentsPresence: number;
}

export interface Student {
  id: number;
  username: string;
  profile: {
    fullName: string;
    avatarUrl?: string;
    bannerUrl?: string;
  };
  progress?: number;
}

export enum DaysOfWeekTypeEnum {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
}

export enum KlassStatusEnum {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  CONCLUDED = 'CONCLUDED',
  CANCELED = 'CANCELED',
}

export enum KlassTypeEnum {
  REGULAR = 'REGULAR',
  CONECTA = 'CONECTA',
}

export interface KlassProgress {
  progress: number;
}
