import ComponentGuard from '@/components/common/ComponentGuard';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import MainButton from '@/components/common/buttons/MainButton';
import SaveCancelGroup from '@/components/common/buttons/SaveCancelGroup';
import RadioButton from '@/components/common/buttons/radioButton';
import AccordionContainer from '@/components/common/cards/AccordionContainer';
import Text from '@/components/common/dataDisplay/Text';
import { Tooltip } from '@/components/common/dataDisplay/Tooltip';
import ImageInput from '@/components/common/dataInput/ImageInput';
import ToolInput, {
  SelectMainTag,
  TagType,
} from '@/components/common/dataInput/TagInput/ToolInput';
import TextInput from '@/components/common/dataInput/TextInput';
import Modal from '@/components/common/modals/Modal';
import useAuth from '@/data/hook/useAuth';
import {
  LessonBody,
  LessonToolParams,
  updateLesson,
  updateLessonInBank,
  updateLessonTool,
} from '@/data/services/lessonServices';
import { toolsQueryKeys } from '@/data/services/querykeys';
import { updateScheduledLesson } from '@/data/services/scheduledLessonsServices';
import { VersioningStatusEnum } from '@/enums/VersioningStatus';
import { isSuperAdmin } from '@/functions/auth';
import { isPulished } from '@/functions/handleCourseStatusIcon';
import { formatLessonNamePrefix } from '@/functions/lessonsName';
import { ApiError } from '@/models/Errors';
import { Lesson } from '@/models/Lesson';
import ScheduledLesson, {
  ScheduledLessonTypeEnum,
} from '@/models/ScheduledLesson';
import Tag, { SimpleTag } from '@/models/Tag';
import Tool from '@/models/Tool';
import { UserTypeEnum } from '@/models/User';
import alert from '@/utils/UseAlert';
import { validateUpload } from '@/utils/VerifyImageFile';
import { buildChangedObject } from '@/utils/buildChangedObject';
import isNullable from '@/utils/isNullable';
import { urlValidate } from '@/utils/urlValidate';
import { validateLink } from '@/validators/regex';
import { InformationCircleIcon, PlusIcon } from '@heroicons/react/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { every, isEmpty, isEqual, some } from 'lodash';
import { useEffect, useState } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Checkbox from '../common/dataInput/Checkbox';
import TopicsInput from '../common/dataInput/TagInput/TopicsInput/TopicsInput';
import TagForm from './TagForm';

export interface TagsForm extends Tag {
  name: string;
}

export type LessonFormType = Omit<Lesson, 'tags'> & {
  type?: ScheduledLessonTypeEnum;
  tags?: TagsForm[];
};

export interface LessonFormProps {
  lesson: Lesson;
  klassId?: number;
  userType: UserTypeEnum;
  updateLessons: () => Promise<void>;
  slugCourseName: string;
  scheduledLesson?: ScheduledLesson;
  readOnly?: boolean;
  canEditScheduledLesson?: boolean;
}

export default function LessonForm({
  lesson,
  klassId,
  readOnly,
  userType,
  updateLessons,
  scheduledLesson,
  canEditScheduledLesson,
}: LessonFormProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'lessonForm',
  });

  const { user } = useAuth();

  const { slugCourseName } = useParams();

  const queryClient = useQueryClient();

  const [bannerImg, setBannerImg] = useState<File | string | null>(
    lesson.bannerImg ?? null,
  );

  const [hasChanges, setHasChanges] = useState(false);
  const [isLoadingScheduledChanges, setIsLoadingScheduledChanges] =
    useState(false);

  const [tagFormType, setTagFormType] = useState<TagType>('none');
  const {
    watch,
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LessonFormType>({
    defaultValues: {
      ...lesson,
      type: scheduledLesson?.type,
      motivation: {
        message: lesson.motivation?.message ?? '',
      },
    },
  });

  const inBank = watch('inBank');

  const {
    fields: tools,
    append: appendTool,
    remove: removeTool,
    update: updateTool,
  } = useFieldArray({
    control,
    name: 'tools',
    keyName: 'key',
  });

  const resetFields = () => {
    reset(lesson);
    setBannerImg(lesson.bannerImg ?? null);
    setHasChanges(false);
  };

  interface UpdateLessonParams {
    lessonId: number;
    changes: Partial<LessonBody>;
  }

  const onUpdateLesson = async ({ changes, lessonId }: UpdateLessonParams) => {
    const bank = {
      inBank: changes.inBank,
      isPublicInBank: changes.isPublicInBank,
    };
    if (!isEmpty(bank)) {
      await updateLessonInBank({ lessonId, bank });
      delete changes.inBank;
      delete changes.isPublicInBank;
    }

    return await updateLesson({ lessonId, slugCourseName }, changes);
  };

  const { isLoading: isLoadingLessonChanges, mutate: update } = useMutation(
    onUpdateLesson,
    {
      async onSuccess(data) {
        alert.success(t('saved'));
        setOpenLinkInputs(false);

        setBannerImg(data.bannerImg ?? null);
        await defineMainTool({
          formTools: tools,
          lessonTools: data.tools,
          lessonId: data.id,
        });

        await updateLessons();
      },
      onError(error) {
        const apiError = new ApiError(error as any);
        if (apiError.fields.includes('abbreviation')) {
          const fieldMessage = apiError.getMessageByField('abbreviation');
          fieldMessage && alert.warning(fieldMessage);
        } else {
          alert.error(apiError.getErrorMessage());
        }
      },
    },
  );

  const { mutate: updateMainTool } = useMutation(updateLessonTool, {
    onSuccess(data) {
      const findDataMainToolIndex = tools.findIndex(
        ({ id }) => id === data.toolId,
      );
      const currentMainToolIndex = tools.findIndex(({ isMain }) => isMain);

      if (currentMainToolIndex === -1) {
        updateTool(findDataMainToolIndex, {
          ...tools[findDataMainToolIndex],
          isMain: true,
        });
      }
    },
    onError(apiError: ApiError) {
      if (apiError.fields.includes('abbreviation')) {
        const fieldMessage = apiError.getMessageByField('abbreviation');
        fieldMessage && alert.warning(fieldMessage);
      } else {
        alert.error(apiError.getErrorMessage());
      }
    },
  });

  type DefineMainTool = {
    lessonId: number;
    formTools: Tool[];
    lessonTools: Tool[];
  };

  async function defineMainTool({
    lessonId,
    formTools,
    lessonTools,
  }: DefineMainTool) {
    const formMainTool = formTools.find(({ isMain }) => isMain);
    const lessonMainTool = lessonTools.find(({ isMain }) => isMain);
    const params: LessonToolParams = {
      lessonId,
      toolId: 0,
    };

    if (!formMainTool && !lessonMainTool) {
      if (lessonTools.length) params.toolId = lessonTools[0].id;
    }

    if (formMainTool) {
      if (formMainTool.id !== lessonMainTool?.id) {
        params.toolId = formMainTool.id;
      }
    }

    if (params.toolId) updateMainTool(params);
  }

  const onSubmitLesson = async (
    lessonId: number,
    changes: Partial<LessonBody>,
  ) => {
    if (!Object.keys(changes).length && bannerImg === lesson.bannerImg) {
      throw new Error('No body or banner');
    }

    update({ lessonId, changes });
  };

  const onSubmit: SubmitHandler<LessonFormType> = async ({ tags, ...data }) => {
    const versioning = !isPulished(data.status);
    const tagsIds = tags?.map(({ id }) => id) ?? undefined;

    const changedFields = buildChangedObject<LessonFormType>(
      {
        ...lesson,
        type: scheduledLesson?.type,
      },
      {
        ...data,
        instructionsUrl: urlValidate(data.instructionsUrl),
        toolUrl: urlValidate(data.toolUrl),
        tagsIds,
      },
    );

    const tools = changedFields.tools?.map(({ id }) => id) ?? undefined;

    const { id: lessonId } = data;

    if (changedFields.type) {
      setIsLoadingScheduledChanges(true);
      if (!scheduledLesson) throw new Error('Scheduled lesson not found');
      await updateScheduledLesson(scheduledLesson.id, {
        type: changedFields.type,
      });
      delete changedFields.type;
      setIsLoadingScheduledChanges(false);
    }

    const status = klassId ? VersioningStatusEnum.VERSIONING : undefined;

    const bannerImgData =
      bannerImg !== lesson.bannerImg ? bannerImg : undefined;

    const body = {
      ...changedFields,
      bannerImg: bannerImgData,
      tools,
    };

    const params = {
      lessonId,
      klassId,
      slugCourseName,
    };
    if (!every(body, isNullable)) {
      if (!versioning) {
        const versioningLesson = await updateLesson(
          { lessonId, slugCourseName },
          { status },
        );

        params.lessonId = versioningLesson.id;
      }

      await onSubmitLesson(params.lessonId, body);
    } else {
      alert.success(t('saved'));
      await updateLessons();
    }
  };

  const onImageChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.files && e.target.files.length) {
      const value = e.target.files.item(0);
      if (value && !validateUpload(value)) {
        setBannerImg(value);
        setHasChanges(true);
      }
    }
  };

  const onImageRemove = () => {
    setBannerImg(null);
    setHasChanges(true);
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      const changes = watch(({ tags, ...lessonChanged }) => {
        const tagsDiff = !isEqual(
          tags?.map(tag => tag?.id),
          lesson.tagsIds,
        );
        delete lessonChanged.type;
        lessonChanged.tagsIds = lesson.tagsIds;
        setHasChanges(!isEqual(lessonChanged, lesson) || tagsDiff);
      });

      return () => {
        changes.unsubscribe();
        setHasChanges(false);
      };
    }
  }, [watch, lesson]);

  const blockChanges = some(errors, error => !!error?.message);

  const prefix = lesson.order
    ? formatLessonNamePrefix({
        lessonOrder: lesson.order,
        scheduledLessonType: scheduledLesson?.type,
      }) + ' - '
    : undefined;

  const [openLinkInputs, setOpenLinkInputs] = useState(false);

  function handleTool(tagTool: Tool) {
    const findToolTagIndex = tools.findIndex(({ id }) => id === tagTool.id);
    if (findToolTagIndex === -1) appendTool(tagTool);
    else if (findToolTagIndex || findToolTagIndex === 0)
      removeTool(findToolTagIndex);
  }

  function onSelectTag<Tag>(tagType: TagType, tag: Tag) {
    if (tagType === 'tool') {
      handleTool(tag as Tool);
    }
  }

  function setMainTool({ tool }: { tool: Tool }) {
    const findMain = tools.find(({ isMain }) => isMain);

    const findIndex = tools.findIndex(({ id }) => id === tool.id);

    if (findMain && findMain.id !== tool.id) {
      const findMainToolIndex = tools.findIndex(
        ({ id }) => id === findMain?.id,
      );
      updateTool(findMainToolIndex, {
        ...findMain,
        isMain: false,
      });
    }

    if (findIndex !== -1)
      updateTool(findIndex, {
        ...tool,
        isMain: tool.isMain ? false : true,
      });
  }

  function onSelectMainTag({ tag, tagType }: SelectMainTag) {
    if (tagType === 'tool') {
      setMainTool({ tool: tag as Tool });
    }
  }

  const [selectedTag, setSelectedTag] = useState<SimpleTag>();

  function openForm(tagType: TagType, tag?: SimpleTag) {
    setTagFormType(tagType);
    setSelectedTag(tag);
  }

  const updateTags = (tagType: TagType) => {
    if (tagType === 'tool')
      queryClient.invalidateQueries(toolsQueryKeys.list._def);
    openForm('none');
  };

  const isSuper = isSuperAdmin(user?.userType);

  const showBankInputs = () => {
    const isAuthor = user?.id === lesson.author;
    return isAuthor || isSuper;
  };

  useEffect(() => {
    if (errors.instructionsUrl || errors.toolUrl) {
      setOpenLinkInputs(true);
    }
  }, [errors.instructionsUrl, errors.toolUrl, setOpenLinkInputs]);

  return (
    <div>
      <Modal visible={tagFormType !== 'none'} onClose={() => openForm('none')}>
        <Text
          className="text-primary text-18 font-500"
          text={t('addToolTitle')}
        />
        <TagForm
          tagType={tagFormType ?? 'none'}
          tag={selectedTag}
          updateTags={updateTags}
        />
      </Modal>
      <form
        data-testid="lessonForm"
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-3.5"
      >
        <ConditionalRenderer condition={canEditScheduledLesson}>
          <div className="flex gap-6">
            <RadioInputContainer
              label={t('normal')}
              explain={t('normalExplain')}
            >
              <RadioButton
                testId="extraLessonRadio"
                className="radio-sm"
                value={ScheduledLessonTypeEnum.EXTRA}
                register={register('type')}
              />
            </RadioInputContainer>

            <RadioInputContainer label={t('free')} explain={t('freeExplain')}>
              <RadioButton
                testId="freeLessonRadio"
                value={ScheduledLessonTypeEnum.FREE}
                className="radio-sm"
                register={register('type')}
              />
            </RadioInputContainer>
          </div>
        </ConditionalRenderer>

        <TextInput
          testId="lessonNameInput"
          color={errors.name?.message ? 'warning' : 'primary'}
          prefix={prefix}
          prefixColor="text-secondary"
          label={t('name')}
          register={register('name', {
            required: t('warnings.required'),
          })}
          errorLabelText={errors.name?.message}
          disabled={readOnly}
        />

        <ConditionalRenderer condition={isSuperAdmin(userType)}>
          <div className="flex flex-col gap-3.5">
            <TextInput
              testId="descriptionInput"
              label={t('description')}
              register={register('description')}
              disabled={readOnly}
            />

            <TextInput
              testId="messageInput"
              label={t('message')}
              register={register('motivation.message')}
              disabled={readOnly}
              className={{ label: 'w-40' }}
            />
          </div>
        </ConditionalRenderer>
        <TopicsInput tagsIds={lesson.tagsIds} control={control} />

        <div className="flex flex-col w-full gap-3.5">
          <ToolInput
            tagType="tool"
            onSelectTag={onSelectTag}
            selectedTags={tools}
            openForm={openForm}
            onSelectMainTag={onSelectMainTag}
            disabled={isLoadingLessonChanges || readOnly}
            className={{ base: 'text-14' }}
          />

          <ComponentGuard roles={[UserTypeEnum.SUPER_ADMIN]}>
            <ConditionalRenderer condition={!readOnly}>
              <div className="flex gap-1 items-center">
                <Text className=" text-14" text={t('toolPhrase')} />
                <Text
                  className="text-14"
                  text={t('additHere')}
                  isLink
                  onClick={() => openForm('tool')}
                />
              </div>
            </ConditionalRenderer>
          </ComponentGuard>
        </div>

        <MainButton
          dataTestId="addAuxiliaryLinksButton"
          onClick={() => setOpenLinkInputs(prev => !prev)}
          type="button"
          text={t('addAuxiliaryLinks')}
          icon={<PlusIcon className="h-4 w-4" />}
          disabled={readOnly}
        />

        <AccordionContainer animate={openLinkInputs ? 'open' : 'closed'}>
          <div className="flex flex-col gap-3.5">
            <div className="flex flex-col gap-2">
              <TextInput
                testId="toolUrlInput"
                label={t('toolUrl')}
                register={register('toolUrl', {
                  pattern: {
                    value: validateLink,
                    message: t('warnings.validateLink'),
                  },
                })}
                errorLabelText={errors.toolUrl?.message}
                fontWeight="font-400"
              />
              <Text
                className="text-primary text-14"
                text={t('toolLinkMessage')}
              />
            </div>

            <div className="flex flex-col gap-2">
              <TextInput
                testId="instructionsUrlInput"
                label={t('instructionsUrl')}
                register={register('instructionsUrl', {
                  pattern: {
                    value: validateLink,
                    message: t('warnings.validateLink'),
                  },
                })}
                errorLabelText={errors.instructionsUrl?.message}
                fontWeight="font-400"
              />
              <Text
                className="text-primary text-14"
                text={t('videoInstallMessage')}
              />
            </div>
          </div>
        </AccordionContainer>
        <ConditionalRenderer condition={showBankInputs()}>
          <Checkbox
            text={t('inBank')}
            className="border-2 border-primary rounded-md w-5 h-5"
            {...register('inBank')}
          />
        </ConditionalRenderer>
        <ConditionalRenderer condition={isSuper && inBank}>
          <div className="flex gap-2 items-center justify-start">
            <input
              type="checkbox"
              {...register('isPublicInBank')}
              className="toggle toggle-sm toggle-primary"
            />
            <Text text={t('isPublicInBank')} className="flex" />
          </div>
        </ConditionalRenderer>

        <ConditionalRenderer condition={!lesson.tools.length && !readOnly}>
          <ImageInput
            isDisabled={!!tools.length}
            testId="bannerImgInput"
            className="w-fit"
            image={bannerImg}
            onChange={onImageChange}
            onRemove={onImageRemove}
          />
        </ConditionalRenderer>
        <ConditionalRenderer condition={!readOnly || canEditScheduledLesson}>
          <SaveCancelGroup
            loading={isLoadingLessonChanges || isLoadingScheduledChanges}
            className="self-end"
            save={{
              disabled: !hasChanges || blockChanges,
              testId: 'saveButton',
            }}
            cancel={{
              disabled: !hasChanges,
              onClick: resetFields,
              testId: 'cancelButton',
            }}
          />
        </ConditionalRenderer>
      </form>
    </div>
  );
}

interface RadioInputContainerProps {
  label: string;
  explain: string;
  children: React.ReactNode;
}

function RadioInputContainer({
  label,
  explain,
  children,
}: RadioInputContainerProps) {
  return (
    <div className="flex items-center gap-3.5">
      <div className="flex items-center gap-0.5">
        <Text text={label} />
        <Tooltip
          className="max-w-[224px] text-secondary"
          text={explain}
          color="lightPurple"
        >
          <InformationCircleIcon className="w-5 h-5 text-neutral/50" />
        </Tooltip>
      </div>
      {children}
    </div>
  );
}
