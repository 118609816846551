import Layout from '@/components/template/Layout';
import MenuItem from '@/components/admin/main/MenuItem';
import Heading3 from '@/components/common/Heading3';
import { useTranslation } from 'react-i18next';
import NoAdminScreenForMobile from '../admin/NoAdminScreenForMobile';
import { useState } from 'react';
import { UserGroupIcon, UserIcon } from '@heroicons/react/outline';
import useAuth from '@/data/hook/useAuth';
import HeadTitle from '@/components/common/HeadTitle';
import { KlassStatusEnum } from '@/models/Klass';
import { createSearchParams } from 'react-router-dom';
import TeacherCollapseItem from '@/components/admin/teacherCalendar/TeacherCollapseItem';
import ConditionalRenderer from '@/components/common/ConditionalRenderer';
import InfiniteSearchInput from '@/components/common/dataInput/InfiniteSearchInput';
import { getAuthorizedUnits } from '@/utils/getAuthorizedUnits';
import { EnrollmentStatusEnum } from '@/models/Enrollment';
import { unitsQueryKeys } from '@/data/services/querykeys';

export default function TeacherPage() {
  const { t } = useTranslation('translation', { keyPrefix: 'teacherPage' });
  const { t: tBase } = useTranslation();

  const { user } = useAuth();

  const pageNumber = '1';

  const klassParams = {
    pageNumber,
    status: [KlassStatusEnum.IN_PROGRESS, KlassStatusEnum.OPEN],
  };

  const studentParams = {
    pageNumber,
    enrollmentStatus: String([EnrollmentStatusEnum.ACTIVE]),
  };
  const data = [
    {
      icon: <UserIcon />,
      text: t('students'),
      href: '/admin/students',
      testId: 'studentsCard',
      search: '?' + createSearchParams(studentParams),
    },
    {
      icon: <UserGroupIcon />,
      text: t('classes'),
      href: '/admin/classes',
      testId: 'classesCard',
      search: '?' + createSearchParams(klassParams),
    },
  ];

  const isMultipleUnits = user && user.unitsIds?.length > 1;

  const [selectedUnit, setSelectedUnit] = useState<number | undefined>(
    isMultipleUnits ? undefined : user?.unitsIds[0],
  );

  return (
    <Layout>
      <HeadTitle routeInfo={t('title')} />
      <div className="md:hidden">
        <NoAdminScreenForMobile />
      </div>
      <div className="hidden md:block w-full">
        <div className="flex justify-between md:flex-row flex-col">
          <div className="flex-col">
            <Heading3 text={t('title')} className="text-primary pb-4" />
            <div className="flex w-full gap-2 flex-wrap pb-10">
              {data.map((listItem, index) => {
                return (
                  <div key={listItem.text}>
                    <MenuItem
                      testId={`item${index}`}
                      icon={listItem.icon}
                      pathname={listItem.href}
                      text={listItem.text}
                      search={listItem.search}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <ConditionalRenderer condition={isMultipleUnits}>
          <div className="flex w-full justify-end mb-2">
            <InfiniteSearchInput
              service={unitsQueryKeys.list}
              onSelect={({ id }) => setSelectedUnit(id)}
              displayName={unit => unit.name}
              onDeselect={() => setSelectedUnit(undefined)}
              filters={{ idIn: getAuthorizedUnits(user)?.toString() }}
              input={{
                placeholder: tBase('calendar.admin.allUnits'),
                className: { base: 'w-80 flex self-end' },
              }}
            />
          </div>
        </ConditionalRenderer>
        {user && (
          <TeacherCollapseItem
            teacher={user}
            key={selectedUnit}
            selectedUnit={selectedUnit ? [selectedUnit] : undefined}
          />
        )}
      </div>
    </Layout>
  );
}
