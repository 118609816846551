import React, { InputHTMLAttributes } from 'react';
import ConditionalRenderer from '../ConditionalRenderer';
import Skeleton from '../Skeleton';
import { BaseInputProps } from './BaseInput';
import { scrollBarClassName } from '@/utils/scrollBarClassName';
import { twMerge } from 'tailwind-merge';

export type weights =
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900';

export type TextAreaProps = Omit<
  InputHTMLAttributes<HTMLTextAreaElement>,
  'className'
> &
  BaseInputProps & {
    color?: 'primary' | 'warning';
    typeInput?: string;
    centerText?: boolean;
    maxLenght?: number;
    onClickIcon?(): void;
  };

interface IPreClasses {
  default: string;
  login: string;
}

export default function TextAreaInput(props: TextAreaProps) {
  const {
    label,
    className,
    icon,
    testId,
    typeInput,
    color,
    errorLabelText,
    dataTestId,
    fontWeight,
    register,
    bgColor,
    fontSize,
    centerText,
    isLoading,
    labelPosition = 'top',
    errorLabelPosition = 'top',
    maxLenght,
    onClickIcon,
    ...rest
  } = props;
  const name = register?.name;
  const preClasses: IPreClasses = {
    default: `${
      color === 'warning'
        ? 'input-warning focus-visible:ring-error-content'
        : 'input-primary focus-visible:ring-primary-content'
    } input-sm pt-0.5`,
    login: 'h-10 focus:input-primary focus-visible:ring-primary-content',
  };

  return (
    <div
      className={`form-control relative flex ${twMerge('', className?.base)} ${
        labelPosition === 'left' ? 'flex-row w-full gap-3' : 'flex-col'
      }`}
    >
      <ConditionalRenderer condition={label}>
        <label
          htmlFor={name}
          className={twMerge('label p-0', className?.label)}
        >
          {label}
        </label>
      </ConditionalRenderer>
      <div className="flex-col w-full justify-center flex">
        <ConditionalRenderer condition={errorLabelText}>
          <label
            data-testid="errorLabel"
            className={`label text-error p-0 ${
              errorLabelPosition === 'bottom' ? 'absolute top-full w-full' : ''
            } ${fontSize || 'text-14'}`}
          >
            {errorLabelText}
          </label>
        </ConditionalRenderer>
        <div className="flex items-center relative w-full">
          <ConditionalRenderer
            condition={!isLoading}
            fallback={
              <Skeleton className="h-[70px] rounded-lg w-full bg-primary" />
            }
          >
            <textarea
              {...register}
              {...rest}
              data-testid={testId || dataTestId}
              className={twMerge(
                `${scrollBarClassName} leading-5 w-full focus-visible:ring focus:outline-none min-h-[70px] ${
                  centerText ? 'text-center' : ''
                } ${fontWeight || 'font-500'} ${
                  fontSize || ''
                } placeholder:font-400 input input-bordered disabled:cursor-default ${
                  bgColor || ''
                } disabled:bg-base-100 disabled:border-none ${
                  typeInput
                    ? preClasses[typeInput as keyof IPreClasses]
                    : preClasses.default
                } input-sm ${
                  color === 'warning'
                    ? 'input-warning focus-visible:ring-error-content'
                    : 'input-primary focus-visible:ring-primary-content'
                } pt-2`,
                className?.input,
              )}
            />
          </ConditionalRenderer>
          <ConditionalRenderer condition={icon}>
            <label
              onClick={onClickIcon}
              className={`${onClickIcon ? 'cursor-pointer' : ''} ${
                errorLabelText ? 'text-error' : 'text-primary'
              } w-4 h-4 absolute right-2.5`}
            >
              {icon}
            </label>
          </ConditionalRenderer>
        </div>
      </div>
    </div>
  );
}
